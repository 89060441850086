import React from 'react';
import { Slider } from 'antd'
import Translator from '../../services/translate-factory';

interface RangeInputFieldProps {
    id: string;
    minValue: number | undefined;
    maxValue: number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    unit?: string;
    setSlider: (values: number[]) => void;
    T: Translator
}

const RangeInputField: React.FC<RangeInputFieldProps> = ({
    id,
    minValue,
    maxValue,
    onChange,
    label,
    unit = "",
    setSlider,
    T,
}) => {
    return (
        <div className='react-select-container tw-p-6 tw-bg-gray-100 tw-rounded-lg'>
            <label>{label} {unit.length > 0 && `(${unit})`}</label>
            <div className='tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2'>
                <div className="form-input form-group date-picker">
                    <input
                        id={`min${id}`}
                        name={`min${id}`}
                        value={minValue || ""}
                        onChange={onChange}
                        required
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor={`min${id}`}>{T.t('gen_minimum')}</label>
                </div>
                {" - "}
                <div className="form-input form-group date-picker">
                    <input
                        id={`max${id}`}
                        name={`max${id}`}
                        value={maxValue || ""}
                        onChange={onChange}
                        required
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor={`max${id}`}>{T.t('gen_maximum')}</label>
                </div>
            </div>
            <Slider
                marks={{
                    0: `0${unit}`,
                    200: `200${unit}`
                }}
                range
                min={0}
                max={200}
                value={[minValue || -1, maxValue || -1]}
                tooltip={{
                    formatter(value) {
                        return `${value}${unit}`
                    },
                }}
                onChange={setSlider}
            />
        </div>
    );
};

export default RangeInputField;
