import { ConfigProvider, Image } from 'antd';
import React, { useState } from 'react';
import { Image as ImageEnum } from '../../../../pages/sketches/tools/interfaces';
import Translator from '../../../../services/translate-factory';
import cn, { flexCenter, gap2 } from '../../../ui/Tailwind';
import ImageCard from './ImageCard';
import fallbackImage from '../../../../assets/images/fallback.jpg';
interface ImageGridProps {
    gridStr: string
    images: ImageEnum[] | ImageEnum;
    allImages: ImageEnum[] | ImageEnum;
    maxFiles: number;
    onDelete: (index: number) => void;
    padding: number;
}

const T = Translator.create();

const ImageGrid: React.FC<ImageGridProps> = ({ padding, gridStr, images, maxFiles, allImages, onDelete }) => {
    const [visible, setVisible] = useState(false)
    const [current, setCurrent] = useState(0)
    if (Array.isArray(images) ? images.filter(_ => !_.deleted).length < 1 : images.deleted) {
        return <div className={cn(flexCenter, "tw-p-4 tw-opacity-40")}>
            {T.t('gen_no_image')}
        </div>
    }

    return (
        <div className={cn(gridStr, gap2)} >
            {Array.isArray(images)
                && images.filter(_ => !_.deleted).map((image, index) => (
                    <ImageCard
                        key={image.id}
                        onPreview={() => {
                            setVisible(true);
                            setCurrent(padding === 0 ? index : padding + index);
                        }}
                        image={image}
                        onDelete={() => onDelete(image.id)}
                    />
                ))
            }
            <ConfigProvider
                theme={{
                    components: {
                        Image: {
                            zIndexPopup: 1500,
                            zIndexPopupBase: 1500,
                            zIndexBase: 1500
                        }
                    }
                }}
            >
                <Image.PreviewGroup preview={{
                    visible: visible,
                    current: current,
                    onVisibleChange: (visible) => { setVisible(visible) },
                    onChange: (curr) => { setCurrent(curr) }
                }}>
                    {!Array.isArray(images) && !images.deleted && <ImageCard
                        key={images.id}
                        onPreview={() => {
                            setVisible(true);
                            setCurrent(0)
                        }}
                        image={images}
                        onDelete={() => onDelete(images.id)}
                    />}
                    <div style={{
                        display: "none",
                        pointerEvents: "none",
                    }}>
                        {Array.isArray(allImages)
                            ? allImages.filter(_ => !_.deleted).map((image, index) => (
                                <Image
                                    key={image.id}
                                    onClick={(e) => e.preventDefault()}
                                    fallback={fallbackImage}
                                    src={image.src}
                                    alt={image.alt} />
                            ))
                            : <Image
                                key={allImages.id}
                                onClick={(e) => e.preventDefault()}
                                fallback={fallbackImage}
                                src={allImages.src}
                                alt={allImages.alt} />
                        }
                    </div>
                </Image.PreviewGroup>
            </ConfigProvider>
        </div>
    );
};

export default ImageGrid;
