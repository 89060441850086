import React, { useState, useEffect } from 'react'
import cn, { flexCenter, flexCol, flexIC, flexRow, gap2, gridCol1, gridCol2, gridCol3 } from '../../ui/Tailwind'
// UPPY
import './disableAddMore.css'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import English from '@uppy/locales/lib/en_US'
import Turkish from '../../../util/UppyLocalization'
import XHR from '@uppy/xhr-upload';
// General
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions/general';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';

import Paginate from '../../table/paginate'
import EditableTitle from './ui/EditableTitle'
import UploadButton from './ui/UploadButton'
import ImageGrid from './ui/ImageGrid'
import MockAPIClient from '../../../services/mock-api-client';
import { BuildingFloor, Image } from '../../../pages/sketches/tools/interfaces'
import Button from '../../button'
import { Modal } from 'antd'


const T = Translator.create();
declare const appSettings: Types.IAppSettings;

interface ImageDropzoneProps {
    uploadAreaText: string;
    title: string;
    imageList?: Image[] | Image;
    type: "default" | "building";
    maxFiles?: number;
    maxLengthFileNames?: number;
    gridCol?: 1 | 2 | 3;
    subTitle?: string;
    setImages: (newImages: Image[] | Image) => void
    setBuildingName?: (newImages: string) => void
    setFloor?: (newFloor: BuildingFloor) => void
    floor?: BuildingFloor
    dispatch?: any;
}

const ImageDropzone: React.FC<Readonly<ImageDropzoneProps>> = ({
    setImages,
    dispatch,
    subTitle,
    gridCol = 2,
    maxFiles = 10,
    maxLengthFileNames = 20,
    uploadAreaText,
    title,
    type = "default",
    imageList,
    setBuildingName = (newVal) => { },
    setFloor,
    floor
}) => {
    // Uppy
    const [uppyLocale, setUppyLocale] = useState<Uppy.Locale>(English);
    const [uppy, setUppy] = useState<Uppy.Uppy>();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 4; // Sayfa başına görüntü sayısı
    const [padding, setPadding] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const getPaginatedImages = () => {
        if (maxFiles === 1) {
            return imageList
        }
        const filteredImages = Array.isArray(imageList)
            ? imageList.filter((image) => !image.deleted)
            : [];
        const startIndex = (currentPage - 1) * pageSize;
        if (padding !== startIndex) {
            setPadding(startIndex)
        }
        return filteredImages.slice(startIndex, startIndex + pageSize);
    };


    const _papi: MockAPIClient = new MockAPIClient()

    const getCurrentTimestamp = (): number => {
        return Math.floor(Date.now() / 1000);
    };

    useEffect(() => {
        const appLocale = GT.getLocaleCode();
        if (appLocale === 'tr-TR') {
            setUppyLocale(Turkish);
        }
    }, []);

    // Uppy config
    useEffect(() => {
        const uppyInstance = Uppy({
            autoProceed: false,
            restrictions: {
                minNumberOfFiles: null,
                maxNumberOfFiles: maxFiles,
                maxFileSize: 5041230, // 5MB
                allowedFileTypes: ['image/jpeg', 'image/png', 'image/bmp'],
            },
            locale: uppyLocale,
        }).use(XHR, {
            endpoint: `${appSettings.API_URL}/integration/validate-image`,
            fieldName: 'file',
            headers: {
                Authorization: `Bearer ${_papi._accessToken}`,
            },
            formData: true,
        })
            .on("complete", (result: any) => {
                const successfulFiles = result.successful;

                if (successfulFiles.length > 0) {
                    let updatedImages: Image[] = []
                    if (imageList)
                        updatedImages = Array.isArray(imageList) ? imageList : [imageList]

                    const uploadedImage: any[] = getUploadFile(successfulFiles, updatedImages)

                    const newImages = [
                        ...updatedImages,
                        ...uploadedImage
                    ]
                    setImages(newImages);

                    setModalVisible(false)
                }
            })

        setUppy(uppyInstance);

        return () => uppyInstance.close();
    }, [uppyLocale, maxFiles, imageList]);

    const getUploadFile = (successfulFiles: any[], updatedImages: Image[] | undefined) => {

        return successfulFiles.map((file, index) => {
            const base64SRC = `data:${file.type};base64,${file.response.body.container}`
            return ({
                id: getCurrentTimestamp() + "_" + (updatedImages && updatedImages.length + index),
                src: file.uploadURL || base64SRC || "", // file.uploadURL yüklenen dosyanın URL'si olacak
                name: file.name,
                timestamp: getCurrentTimestamp(),
                index: updatedImages && updatedImages.length + index,
                base64string: file && file.response && file.response.body && file.response.body.container,
                deleted: false,
            })
        });
    };

    const showRemoveModal = (callback: () => void) => {
        dispatch(
            Actions.ShowModal({
                title: T.t('gen_delete_action'),
                body: T.t("gen_continue"),
                name: 'building_delete',
                icon: 'warning',
                iconColor: 'red',
                confirm: T.t('gen_yes'),
                cancel: T.t('gen_cancel'),
                onConfirm: callback
            }))
    }

    const handleDelete = (index: number) => {
        showRemoveModal(() => removeImage(index))
    };

    const handleDeleteFloor = () => {
        showRemoveModal(() => removeFloor())
    }

    const removeFloor = () => {
        if (setFloor && floor) {

            setFloor({
                ...floor,
                deleted: true
            })
        }
    }

    useEffect(() => {
        fixPaginatedImages()
    }, [imageList])

    const removeImage = (index: number) => {
        if (Array.isArray(imageList)) {
            const updateImages = imageList.map(p => {
                if (p.id === index) {
                    return {
                        ...p,
                        deleted: true
                    }
                }
                return p
            })

            setImages(updateImages);
        } else if (imageList) {
            setImages({
                ...imageList,
                deleted: true
            })
        }
    }

    const fixPaginatedImages = () => {
        if (!checkPageHasImages()) {
            if (currentPage > 1)
                handlePageChange(currentPage - 1)
        }
    }

    const checkPageHasImages = () => {
        const paginatedImages = getPaginatedImages()
        if (Array.isArray(paginatedImages)) {
            return paginatedImages.length > 0
        } else {
            return paginatedImages && !paginatedImages.deleted
        }
    }

    const getGrid = () => {
        switch (gridCol) {
            case 1:
                return gridCol1
            case 2:
                return gridCol2
            case 3:
                return gridCol3
            default:
                return gridCol1
        }
    }
    const shouldShowUploadButton = () => {
        if (Array.isArray(imageList)) {
            const nonDeletedImagesCount = imageList.filter(image => !image.deleted).length;
            return nonDeletedImagesCount < maxFiles;
        } else {
            if (imageList)
                return imageList.deleted;
            return true
        }
    };

    return (
        <div className={cn(flexCol, gap2)}>
            <div className={getGrid()}>
                <h6 className={cn(flexRow, gap2, flexIC)}>
                    {type === "building" ? (
                        <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full'>
                            <EditableTitle
                                onSave={setBuildingName}
                                title={title}
                                subTitle={subTitle}
                            />
                            {
                                floor &&
                                <Button key={floor.id} color='red' icon='delete' onClick={() => handleDeleteFloor()}>
                                    {T.t("gen_delete")}
                                </Button>
                            }
                        </div>
                    ) : (
                        title
                    )}
                </h6>
            </div>
            {
                shouldShowUploadButton() &&
                <UploadButton
                    onClick={() => setModalVisible(true)}
                    uploadAreaText={uploadAreaText}
                />
            }
            <ImageGrid
                gridStr={getGrid()}
                allImages={imageList || []}
                images={getPaginatedImages() || []}
                maxFiles={maxFiles}
                padding={padding}
                onDelete={handleDelete}
            />
            {maxFiles !== 1 && Array.isArray(imageList) && (
                <div className="row-options justify-content-end">
                    <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                        <Paginate
                            filters={{
                                page: currentPage,
                                size: pageSize,
                                total: imageList.filter((image) => !image.deleted).length,
                            }}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            )}
            <Modal
                open={modalVisible}
                zIndex={1500}
                width='fit-content'
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setModalVisible(false)}
                afterClose={() => {
                    if (uppy) uppy.reset()
                }}
            >
                <div className={cn(flexCenter, "tw-p-6")}>
                    {
                        uppy && (
                            <Dashboard
                                note={T.t('gen_max_5_mb_file')}
                                uppy={uppy}
                                showProgressDetails
                                proudlyDisplayPoweredByUppy={false}
                            />
                        )
                    }
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: ImageDropzoneProps
): ImageDropzoneProps => {
    if (!store) {
        return ownProps;
    }

    const newProps: ImageDropzoneProps = Object.assign({}, ownProps, {
        results: store.state.exam_period_page && store.state.exam_period_page.results,
        user: store.state.user,

    });
    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(next, prev);
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
    areStatesEqual
})(ImageDropzone);

export default container;
