import * as Yup from 'yup';
import { instructorFormFields } from '../../../pages/instructor/validations/instructor-form-val';
import { examClassroomFormFields } from '../../../pages/classroom/validations/classroom-form-val';
import Translator from '../../../services/translate-factory';

interface Validate {
    dataRow: any,
    errorFn: (errors?: any[]) => void,
    validateType: ValidateType
    translator: Translator
}
export enum ValidateType {
    CLASSROOM,
    INSTRUCTOR
}

const getPartialSchema = (type: ValidateType, translator: Translator) => {

    switch (type) {
        case ValidateType.INSTRUCTOR: {

            const fullSchema = instructorFormFields(translator);

            return Yup.object().shape({
                instructor_code: fullSchema.instructor_code,
                name: fullSchema.name,
            });
        }
        case ValidateType.CLASSROOM: {
            const fullSchema = examClassroomFormFields(translator);

            return Yup.object().shape({
                ClassroomCode: fullSchema.classroom_code,
                ClassroomName: fullSchema.name,
            });
        }
        default:
            return Yup.object().shape({});
    }
}


export async function validate(
    {
        dataRow,
        translator,
        errorFn,
        validateType
    }: Validate) {

    const partialSchema = getPartialSchema(validateType, translator)

    const schema = Yup.array().of(partialSchema);

    const res = await validateRows({ rows: dataRow, schema: schema })

    if (res instanceof Yup.ValidationError) {
        errorFn(mergeErrors(res))
        return false
    } else if (!res)
        return false
    return true
}

const validateRows = async ({ rows, schema }: { rows: any, schema: Yup.ArraySchema<object> }) => {
    try {
        await schema.validate(rows, { abortEarly: false });
        return true
    } catch (error) {
        if (error instanceof Yup.ValidationError) {
            return error
        } else {
            return false
        }
    }
};

const mergeErrors = (errors: Yup.ValidationError) => {
    if (!errors.inner || !Array.isArray(errors.inner))
        return

    const groupedErrors = Object.values(errors.inner.reduce((acc, curr) => {
        const match = curr.path.match(/\[(\d+)\]/);
        const index = match ? match[1] : null;

        if (index !== null) {
            if (!acc[index]) {
                acc[index] = { path: `[${index}]`, errors: [] };
            }
            acc[index].errors.push({ Message: curr.message, Field: curr.value });
        }
        return acc;
    }, {} as Record<string, { path: string; errors: Array<{ Message: string, Field: any }> }>));

    return groupedErrors.map((item, i) => {

        return {
            RowIndex: i,
            ValidationErrors:
                item.errors.map(
                    error => ({
                        Message: error.Message,
                        Field: error.Field
                    })
                )

        }
    })
}