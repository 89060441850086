import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  PROGRAM_LIST_SEARCH: 'PROGRAM:LIST_SEARCH',
  PROGRAM_CREATE: 'PROGRAM:CREATE',
  PROGRAM_MULTI_CREATE: 'PROGRAM:MULTI_CREATE',
  PROGRAM_UPDATE: 'PROGRAM:UPDATE',
  PROGRAM_DELETE: 'PROGRAM:DELETE',
  PROGRAM_GET_BY_ID: 'PROGRAM:GET_BY_ID',
  PROGRAM_GET_SELECT_OPTIONS: 'PROGRAM:GET_SELECT_OPTIONS',
  PROGRAM_CREATE_EXCEL_BULK: 'PROGRAM:CREATE_EXCEL_BULK',
  PROGRAM_IMPORT_LIST_SEARCH: 'PROGRAM:IMPORT_LIST_SEARCH',
  PROGRAM_IMPORT_GET_SELECT_OPTIONS: 'PROGRAM:IMPORT_GET_SELECT_OPTIONS',
  PROGRAM_IMPORT_CREATE: 'PROGRAM:IMPORT_CREATE',
  PROGRAM_CHANGE_ALL_DATA: 'PROGRAM:CHANGE_ALL_DATA'
};

export const ProgramSearchInitialValues: Types.IFilterProgram = {
  program_code: '',
  name: '',
  status: [],
  faculties: [],
  faculty_ids: undefined,
  select_all: false,
  education_types_select: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ProgramFormInitialValues: Types.IProgramItem = {
  program_id: undefined,
  program_code: '',
  status: 1,
  name: '',
  faculty_name: '',
  daily_max_class: undefined,
  min_class_interval_bw_campuses: undefined,
  description: '',
  max_gap_between_courses: 7,
  grade: 0,
  grades: [],
  grade_ids: [],
  education_type: '',
  education_types: []
};

export const ProgramGradeSelectOptions: Array<Types.ISelectOption> = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

export const EducationTypeSelectOptions = (T: Translator) => [
  { label: T.t('gen_formal_education'), value: 'NO' },
  { label: T.t('gen_evening_education'), value: 'IO' },
  { label: T.t('gen_distance_education'), value: 'Uzaktan Eğitim' }
];

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_program_code'), value: 'PROGRAM_CODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];

export const ChangeAllDataModalProgramInitialValues: Types.IProgramFilterChangeAllDataModal = {
  selected_types: undefined,
  selected_type_ids: undefined,
  max_gap_between_courses: 0,
  min_class_interval_bw_campuses: 0,
  daily_max_class: 0
};