import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log, Logger } from 'ng2-logger';
import { Modal } from 'reactstrap';
import Paginate from '../../../components/table/paginate';
import SortedColumn from '../../../components/table/sorted-column';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import {
  ActivitySearchInitialValues,
  ActivityHoursSearchInitialValues,
  ActivityTermLectureLocations,
  LectureLocationsLabel,
  EducationTypeSelectOptions,
  ActivityTypesLabel,
  CourseTypesLabel
} from '../../../store/constants/activity-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';

const T = Translator.create();
const L = Log.create('ActivityAddModal');

function getInitialState(): Types.IActivityPageState {
  const initialValues: Types.IActivityPageState = {
    filters: Object.assign({}, ActivityHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    activityFormIsOpen: false,
    activityNo: undefined,
    package_name: '',
    description: ''
  };
  initialValues.filters.onlySelectWithoutCourseHours = false;
  return Object.assign({}, initialValues);
}

class ActivityAddModal extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  state = getInitialState();

  handleChange(e: React.ChangeEvent<HTMLInputElement>, isDescription: boolean) {
    if (isDescription) this.setState({ description: e.target.value });
    else this.setState({ package_name: e.target.value });
  }

  componentDidMount() {
    this.searchActivities();
    this.getActivitySelectOptions();
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidUpdate() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }


  searchActivities() {
    let newModel = {
      add_list: this.state.filters.add_list,
      assistant_staff: this.state.filters.assistant_staff,
      assistant_staff_ids: this.state.filters.assistant_staff_ids,
      campus_ids: this.state.filters.campus_ids,
      campuses: this.state.filters.campuses,
      activity_noes_ids: this.state.filters.activity_noes_ids,
      course_codes_ids: this.state.filters.course_codes_ids,
      course_name: this.state.filters.course_name,
      course_types: this.state.filters.course_types,
      instructor_ids: this.state.filters.instructor_ids,
      instructors: this.state.filters.instructors,
      lecture_location_ids: this.state.filters.lecture_location_ids,
      lecture_locations: this.state.filters.lecture_locations,
      lecture_staff: this.state.filters.lecture_staff,
      lecture_staff_ids: this.state.filters.lecture_staff_ids,
      order_by: this.state.filters.order_by,
      page: this.state.filters.page,
      practical_location_ids: this.state.filters.practical_location_ids,
      practical_locations: this.state.filters.practical_locations,
      practical_staff: this.state.filters.practical_staff,
      practical_staff_ids: this.state.filters.practical_staff_ids,
      faculty_ids: this.state.filters.faculty_ids,
      faculties: this.state.filters.faculties,
      program_ids: this.state.filters.program_ids,
      programs: this.state.filters.programs,
      query: this.state.filters.query,
      select_all: this.state.filters.select_all,
      size: this.state.filters.size,
      status: this.state.filters.status,
      term_id: this.state.filters.term_id,
      total: this.state.filters.total
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, newModel, 'activity-list-spin')
    );
  }

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  getActivitySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'activity-list-spin'));
  }

  getProgramsByFaculties = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
    this.setClose();
  };

  onSelectActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchActivities();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addActivities = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      if (this.checkRelationAvaliability()) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_activity_relations'),
            body:
              <React.Fragment>
                <Formik
                  initialValues={ActivitySearchInitialValues}
                  enableReinitialize={true}
                  onSubmit={(values, actions) => {
                  }}
                  onReset={this.onFormReset}
                >
                  {(props: FormikProps<Types.IActivityPageProps>) => {
                    const { values, handleChange, handleBlur, handleSubmit } = props;
                    if (!(this.state.package_name && this.state.package_name.length)) this.setState({ package_name: this.props.selectedActivityRelation.package_name });
                    if (!(this.state.description && this.state.description.length)) this.setState({ description: this.props.selectedActivityRelation.description });
                    return (
                      <div className="row">
                        {T.t('gen_activity_relation_question')}
                        <div className="col-md-12 pt-3">
                          <div className="form-input form-group date-picker">
                            <input
                              id="package_name"
                              name="package_name"
                              value={this.state.package_name}
                              onChange={(e) => this.handleChange(e, false)}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="package_name">{T.t('gen_package_name')}</label>
                          </div>
                          <div className="form-input form-group date-picker">
                            <input
                              id="description"
                              name="description"
                              value={this.state.description}
                              onChange={(e) => this.handleChange(e, true)}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description">{T.t('gen_description')}</label>
                            <i className="material-icons">title</i>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </Formik>
              </React.Fragment>,
            name: 'activity_relations',
            icon: 'info',
            iconColor: 'green',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.onUpdateListFromModal();
                }
                if (status == 409) {
                  this.setCloseModal();
                }
              };

              const model = {
                activity_relation_id: this.props.selectedActivityNo,
                term_id: this.props.term_id,
                related_activity_ids: this.state.selected_ids,
                activity_relation_type: this.props.activityRelationType,
                package_name: this.state.package_name,
                description: this.state.description
              };

              if (model.activity_relation_id != -1) {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.course_period.UPDATE_ACTIVITY_RELATION,
                    model,
                    'ep-instructor-list',
                    resultCallback
                  )
                );
              } else {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.course_period.POST_ACTIVITY_RELATION,
                    model,
                    'ep-instructor-list',
                    resultCallback
                  )
                );
              }
            }
          })
        );
      } else {
        if (this.state.selected_ids && this.state.selected_ids.length > 1) {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_activiy_max_select'),
              name: 'activity_relations_notallowed',
              icon: 'warning',
              iconColor: 'red',
              cancel: T.t('gen_close')
            })
          );
        } else {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_activiy_min_select'),
              name: 'activity_relations_notallowed',
              icon: 'warning',
              iconColor: 'red',
              cancel: T.t('gen_close')
            })
          );
        }
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchActivities();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchActivities();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivitySearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchActivities();
  };

  onFilterActivity(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchActivities();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.modalIsOpen && !state.activityFormIsOpen) {
      hasNewState = true;
      props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, state.filters, 'activity-list-spin'));
      state.activityFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  checkRelationAvaliability = (): boolean => {
    if (this.state.selected_ids && this.state.selected_ids.length > 1) {
      if (
        this.props.activityRelationType == 2 ||
        this.props.activityRelationType == 3 ||
        this.props.activityRelationType == 5 ||
        this.props.activityRelationType == 6
      ) {
        return this.state.selected_ids.length > 2 ? false : true;
      } else return true;
    } else if (this.props.selectedActivityNo != -1) return true;
    else return false;
  };

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes ? this.props.selectOptions.course_codes : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    let activityList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_activity_relations')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      id='button_filter'
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cancel_selection'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='button_add_selection'
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addActivities}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          id='button_cancel_selected'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                          </span>
                        </button>
                        <button
                          id='button_add_selected'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ActivitySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterActivity(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterActivity>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_no')}</label>
                                    <Select
                                      id='select_activty_no'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.activity_noes
                                          ? this.props.selectOptions.activity_noes
                                          : []
                                      }
                                      placeholder={T.t('gen_select_activity_no')}
                                      value={props.values.activity_noes}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('activity_noes', list);
                                        props.setFieldValue(
                                          'activity_noes_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_code')}</label>
                                    <Select
                                      id='select_course_code'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={courseCodeSelectOptions}
                                      placeholder={T.t('gen_select_course_code')}
                                      value={props.values.course_codes}
                                      filterOption={this.filterOption}
                                      onInputChange={this.onInputChange}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_codes', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                        props.setFieldValue(
                                          'course_codes_ids',
                                          list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_name"
                                    name="course_name"
                                    value={props.values.course_name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.instructors
                                          ? this.props.selectOptions.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.instructors}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('instructors', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-xs-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_faculty')}</label>
                                    <Select
                                      id='select_faculty'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_faculty')}
                                      value={props.values.faculties ? props.values.faculties : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('faculties', list);
                                        props.setFieldValue(
                                          'faculty_ids',
                                          list.map((item) => item.value)
                                        );
                                        props.setFieldValue('programs', []);
                                        props.setFieldValue('program_ids', []);
                                        this.getProgramsByFaculties(list.map(item => item.value))
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_program')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={this.props.programs_related_faculty && this.props.programs_related_faculty.length > 0 ? this.props.programs_related_faculty :
                                        this.props.selectOptions && this.props.selectOptions.programs
                                          ? this.props.selectOptions.programs
                                          : []}
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs ? props.values.programs : null} d
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_location')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_location ? props.values.lecture_location : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_location', list);
                                        props.setFieldValue(
                                          'lecture_location_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upword'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              id='select_all'
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_no"
                          title={T.t('gen_activity_no')}
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          sortkey="activity_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_activity_type')}
                          sortkey="activity_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lesson_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_count')}
                          sortkey="lesson_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_location"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_location')}
                          sortkey="lecture_location"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="education_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_education_type')}
                          sortkey="education_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="faculty"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_faculty')}
                          sortkey="faculty"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="program"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_connected_programs')}
                          sortkey="program"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="class"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_grade')}
                          sortkey="class"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="section"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_section')}
                          sortkey="section"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="instructor"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_instructors')}
                          sortkey="instructor"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_count')}
                          sortkey="student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <SortedColumn
                          datacell="course_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_type')}
                          sortkey="course_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityList && activityList.length
                        ? activityList.map((item: any) => {
                          const courseLocation = allSelectOptions.find(
                            (option) => option.value == item.lecture_location_id
                          );
                          return (
                            <tr key={'activity-' + item.activity_no} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    id='select_activity'
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                    }
                                    data-id={item.activity_no}
                                    onChange={this.onSelectActivity}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">{item.activity_no}</td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{item.course_code ? item.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{item.course_name ? item.course_name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td data-label={T.t('gen_activity_type')} className="text-center">{ActivityTypesLabel(item.activity_type, T)}</td>
                              <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{item.lesson_count}</td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">{LectureLocationsLabel(item.lecture_location.label, T)}</td>
                              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                  EducationTypeSelectOptions(T)[0].label :
                                  item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                    EducationTypeSelectOptions(T)[1].label :
                                    item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                      EducationTypeSelectOptions(T)[2].label : item.education_type
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.faculties && item.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{item.faculties && item.faculties.map((i: any, index: any) => (index == item!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.programs && item.programs.length > 0 ?
                                    <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.classes && item.classes.length > 0 ?
                                    <div className="table-scrollable-td">{item.classes && item.classes.map((i: any, index: any) => (index == item!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.sections && item.sections.length > 0 ?
                                    <div className="table-scrollable-td">{item.sections && item.sections.map((i: any, index: any) => (index == item!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.instructors && item.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{item.instructors && item.instructors.map((i: any, index: any) => (index == item!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {item.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                <div className="table-scrollable-td">{item.week ? item.week.split(',').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                {CourseTypesLabel(item.course_type, T)}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                            </tr>
                          )
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    all_ids: store.state.activity_page && store.state.activity_page.all_ids,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.all_ids,
        next.state.activity_page && next.state.activity_page.all_ids
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ActivityAddModal);

export default container;
