import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';
import { specialCharacterRegex } from '../../../util/regex';

export const ExamClassroomFormValidation = (T: Translator) => Yup.object().shape(examClassroomFormFields(T));

export const examClassroomFormFields = (T: Translator) => ({
  classroom_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(50, T.t('gen_can_be_up_to_n').replace('{0}', '50'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  classroom_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  building_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  door_order: Yup.number().required(T.t('gen_cannot_leave_empty')),
  building_floor: Yup.string().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000'))
})


