import React, { Component } from 'react';
import Select from 'react-select';
import { Formik, ErrorMessage, FormikProps, FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Types from '../../store/types';
import * as Constants from '../../store/constants/all';
import * as Actions from '../../store/actions/general';
import { InstructorFormValidation } from './validations/instructor-form-val';
import { CourseInstructorFormValidation } from './validations/course-instructor-form-val';
import {
  InstructorFormInitialValues,
  InvigilatorLevels,
  Titles,
  StaffTypes
} from '../../store/constants/instructor-const';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('ClassroomForm');

function getInitialState(): Types.IInstructorFormState {
  const initialValues: Types.IInstructorFormState = {
    model: Object.assign({}, InstructorFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class InstructorForm extends Component<Types.IInstructorFormProps, Types.IInstructorFormState> {
  state: Types.IInstructorFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getProgramsByFacultiesAtInstructors([]);
    this.getInvigilatorProgramsByFacultiesAtInvigilators([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getProgramsByFacultiesAtInstructors = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'users-list-spin'));
  }

  getInvigilatorProgramsByFacultiesAtInvigilators = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_INVIGILATOR_PROGRAMS_BY_FACULTIES, facultyIds, 'invigilator-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IInstructorItem, FormActions: FormikActions<Types.IInstructorItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201 || status === 202) {
        this.setClose(true);
      }
    };

    if (this.props.instructorId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.instructor.INSTRUCTOR_UPDATE, model, 'instructor-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.instructor.INSTRUCTOR_CREATE, model, 'instructor-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.IInstructorFormProps, state: Types.IInstructorFormState) {
    let hasNewState: boolean = false;
    if (props.instructorId && props.instructorId != state.model.instructor_id) {
      state.model.instructor_id = props.instructorId;
      props.dispatch(
        Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_BY_ID, props.instructorId, 'instructor-form-spin')
      );
      hasNewState = true;
    }
    if (props.form && props.instructorId && props.instructorId == state.model.instructor_id) {
      state.model = props.form;
      if (props.form.invigilation_campuses) {
        state.model.invigilation_campus_ids = props.form.invigilation_campuses.map((f) => f.value);
      }
      if (props.form.invigilation_faculties) {
        state.model.invigilation_faculty_ids = props.form.invigilation_faculties.map((f) => f.value);
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.instructorId && state.model.instructor_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    const validation = this.props.term_type === 0 ? CourseInstructorFormValidation(T) : InstructorFormValidation(T);

    let invigilationProgramSelector = !this.props.invigilation_program_ids! ? true : false;

    let titles = Titles(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_titles && this.props.selectOptions.additional_titles.length > 0) {
      titles = [...Titles(T), ...this.props.selectOptions.additional_titles].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={validation}
        >
          {(props: FormikProps<Types.IInstructorItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            let title = null;
            if (props.values.title) {
              let value = props.values.title.toLowerCase().replace(/[. ]+/g, '');
              title = titles.find((title) => title.label.toLowerCase().replace(/[. ]+/g, '') == value);
            }

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {this.props.instructorId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_instructor')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_instructor')}</>}
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="instructor_code"
                              name="instructor_code"
                              value={values.instructor_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="instructor_code">{T.t('gen_code')}</label>
                            {errors && errors.instructor_code && props.submitCount > 0 && (
                              <div className="error">{errors && errors.instructor_code}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name_surname')}</label>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="error">{errors && errors.name}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_staff_type')}</label>
                                <Select
                                  id='select_staff'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={StaffTypes(T)}
                                  placeholder={T.t('gen_select_staff_type')}
                                  value={
                                    props.values.staff_type != null
                                      ? StaffTypes(T).find(
                                        (degree: Types.ISelectOption) => degree.value === props.values.staff_type
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('staff_type', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.staff_type && props.submitCount > 0 && (
                                <div className="error">{errors && errors.staff_type}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_title')}</label>
                                <Select
                                  id='select_title'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={titles}
                                  placeholder={T.t('gen_select_title')}
                                  value={
                                    props.values.title != null
                                      ? titles.find((degree: Types.ISelectOption) => degree.value === props.values.title)
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('title', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_title')}
                                />
                              </div>
                              {errors && errors.title && props.submitCount > 0 && (
                                <div className="error">{errors && errors.title}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="email">{T.t('gen_email')}</label>
                            {errors && errors.email && props.submitCount > 0 && (
                              <div className="error">{errors && errors.email}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="mobile"
                              name="mobile"
                              value={values.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="mobile">{T.t('gen_cellphone')}</label>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_faculty_college_institute_of_duty')}</label>
                                <Select
                                  id='select_faculty'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.faculties
                                      ? this.props.selectOptions.faculties
                                      : []
                                  }
                                  placeholder={T.t('gen_select_faculty_college_institute')}
                                  value={props.values.faculty_of_duty}
                                  onChange={(option: any) => {
                                    props.setFieldValue('faculty_of_duty', option);
                                    props.setFieldValue('faculty_of_duty_id', option && option.value);
                                    this.getProgramsByFacultiesAtInstructors([Number(option && option.value)]);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                />
                              </div>
                              {errors && errors.faculty_of_duty_id && props.submitCount > 0 && (
                                <div className="error">{errors && errors.faculty_of_duty_id}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_program_department_of_duty')}</label>
                                <Select
                                  id='select_department'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.programs_related_faculty}
                                  value={props.values.program_of_duty}
                                  placeholder={T.t('gen_select_program_department')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('program_of_duty', option);
                                    props.setFieldValue('program_of_duty_id', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                />
                              </div>
                              {errors && errors.program_of_duty_id && props.submitCount > 0 && (
                                <div className="error">{errors && errors.program_of_duty_id}</div>
                              )}
                            </div>
                          </div>
                          {this.props.term_type === 0 ? (
                            <React.Fragment>
                              <div className="col-md-6 form-input form-group with-icon">
                                <input
                                  id="daily_max_class"
                                  name="daily_max_class"
                                  value={values.daily_max_class}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="daily_max_class">{T.t('gen_daily_max_course')}</label>
                                {errors && errors.daily_max_class && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.daily_max_class}</div>
                                )}
                              </div>
                              <div className="col-md-6 form-input form-group with-icon">
                                <input
                                  id="weekly_max_day"
                                  name="weekly_max_day"
                                  value={values.weekly_max_day}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="weekly_max_day">{T.t('gen_weekly_max_day')}</label>
                                {errors && errors.weekly_max_day && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.weekly_max_day}</div>
                                )}
                              </div>
                            </React.Fragment>
                          ) : null}
                          {this.props.term_type === 1 ? (
                            <React.Fragment>
                              <div className="col-md-5 form-input form-group with-icon">
                                <input
                                  id="max_time_bw_consecutive_invigilations"
                                  name="max_time_bw_consecutive_invigilations"
                                  value={values.max_time_bw_consecutive_invigilations}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="max_time_bw_consecutive_invigilations">{T.t('gen_max_time_bw_consecutive_invigilations')}</label>
                                {errors && errors.max_time_bw_consecutive_invigilations && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.max_time_bw_consecutive_invigilations}</div>
                                )}
                              </div>
                              <div className="col-md-4 form-input form-group with-icon">
                                <input
                                  id="daily_max_duty"
                                  name="daily_max_duty"
                                  value={values.daily_max_duty}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="daily_max_duty">{T.t('gen_daily_max_duty')}</label>
                                {errors && errors.daily_max_duty && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.daily_max_duty}</div>
                                )}
                              </div>
                              <div className="col-md-3 form-input form-group with-icon">
                                <input
                                  id="max_invigilation_duty"
                                  name="max_invigilation_duty"
                                  value={values.max_invigilation_duty}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="max_invigilation_duty">{T.t('gen_max_invigilation_count')}</label>
                                {errors && errors.max_invigilation_duty && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.max_invigilation_duty}</div>
                                )}
                              </div>
                              <div className="col-md-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_invigilation_level')}</label>
                                    <Select
                                      id='select_level'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={InvigilatorLevels(T)}
                                      placeholder={T.t('gen_select_level')}
                                      value={
                                        props.values.invigilator_level != null
                                          ? InvigilatorLevels(T).find(
                                            (degree: Types.ISelectOption) =>
                                              degree.value === props.values.invigilator_level
                                          )
                                          : null
                                      }
                                      onChange={(option: any) => props.setFieldValue('invigilator_level', option.value)}
                                      noOptionsMessage={(): string => T.t('gen_select_no_level')}
                                    />
                                  </div>
                                  {errors && errors.invigilator_level && props.submitCount > 0 && (
                                    <div className="error">{errors && errors.invigilator_level}</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_invigilation_campuses')}</label>
                                    <Select
                                      id='select_location_campus'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.campuses
                                          ? this.props.selectOptions.campuses
                                          : []
                                      }
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.invigilation_campuses}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('invigilation_campuses', list);
                                        props.setFieldValue(
                                          'invigilation_campus_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                  {errors && errors.invigilation_campus_ids && props.submitCount > 0 && (
                                    <div className="error">{errors && errors.invigilation_campus_ids}</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_invigilation_faculties_colleges_institutes')}</label>
                                    <Select
                                      id='select_location_faculty'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.invigilation_faculties}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('invigilation_faculties', list);
                                        props.setFieldValue('invigilation_faculty_ids', list.map((item) => item.value));
                                        this.getInvigilatorProgramsByFacultiesAtInvigilators(list.map((item) => item.value));
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                  {errors && errors.invigilation_faculty_ids && props.submitCount > 0 && (
                                    <div className="error">{errors && errors.invigilation_faculty_ids}</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_invigilation_programs')}</label>
                                    <Select
                                      id='select_program'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={[{ label: T.t('gen_select_all'), value: -1 }, ...this.props.invigilator_programs_related_faculty!]}
                                      placeholder={T.t('gen_select_location')}
                                      value={invigilationProgramSelector ? props.values.invigilation_programs : this.props.invigilator_programs_related_faculty}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('invigilation_programs',
                                          list.map(item => item.value).includes(-1) ? this.props.invigilator_programs_related_faculty : list
                                        );
                                        props.setFieldValue(
                                          'invigilation_program_ids',
                                          list.map(item => item.value).includes(-1) ? this.props.invigilator_programs_related_faculty!.map((item: any) => item.value) : list.map(item => item.value)
                                        );
                                        invigilationProgramSelector = true;
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                  {errors && errors.invigilation_program_ids && props.submitCount > 0 && (
                                    <div className="error">{errors && errors.invigilation_program_ids}</div>
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          ) : null}
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IInstructorFormProps
): Types.IInstructorFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IInstructorFormProps = Object.assign({}, ownProps, {
    form:
      store.state.instructor_page && store.state.instructor_page.form
        ? store.state.instructor_page.form
        : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    term_type: store.state.term_type,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    invigilator_programs_related_faculty: store.state.select_options && store.state.select_options.invigilator_programs_related_faculty
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.instructor_page) {
    return (
      !!equal(
        prev.state.instructor_page && prev.state.instructor_page.form,
        next.state.instructor_page && next.state.instructor_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.instructorPage,
        next.state.select_options && next.state.select_options.instructorPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.invigilator_programs_related_faculty,
        next.state.select_options && next.state.select_options.invigilator_programs_related_faculty
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InstructorForm);

export default container;
