import * as Types from '../../store/types';
import { RBCEventStatus, RBCEventType } from './constants';

export const events: Types.RBCEvents[] = [
    {
        title: "Sınav Etkinliği",
        start: new Date('2024-08-06T06:00:00.000Z'),
        end: new Date('2024-08-06T07:00:00.000Z'),
        desc: "Bu bir aciklama metnidir",
        allDay: false,
        type: RBCEventType.EXAM
    },
    {
        title: "Ders Etkinliği",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        desc: "gtdk1 - genel test ders adı 2 Sınıf 5 - Şube B - genel test kampüs 1",
        room: {
            label: "genel test derslik 10 (gtdk10)",
            value: "101",
        },
        allDay: true,
        type: RBCEventType.COURSE,
        responsibles: [
            { id: '1', name: 'Prof. Dr. gtegitmen2' },
        ],
    },
    {
        title: "Ders ve sınav dışındaki etkinlikler",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 2),
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 2),
        desc: "Bu bir aciklama metnidir",
        allDay: true,
        type: RBCEventType.OTHER,
        otherType: {
            label: "Toplantı",
            value: '12',
        },
        building: {
            label: "A Blok",
            value: '1',
        },
        room: {
            label: "101 Derslik",
            value: '10',
        },
        status: RBCEventStatus.DENIED
    },
    {
        title: "Kulüp toplantısı",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 3),
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 4),
        desc: "Öğretim üyesinin oluşturduğu herhangi bir etkinlik",
        allDay: true,
        type: RBCEventType.OTHER,
        status: RBCEventStatus.PENDING
    },
    {
        title: "SAU Rock Festivali",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 13),
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 18),
        desc: "Rock festivali",
        allDay: true,
        type: RBCEventType.OTHER,
        status: RBCEventStatus.APPROVED
    },
    {
        title: "Toplantı ve Seminerler",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 5), // 5 gün sonrası
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 5), // 5 gün sonrası
        desc: "Bu, önemli bir toplantının açıklama metnidir.",
        allDay: false,
        type: RBCEventType.OTHER,
        otherType: {
            label: "Seminer",
            value: '14',
        },
        building: {
            label: "B Blok",
            value: '2',
        },
        room: {
            label: "202 Konferans Salonu",
            value: '20',
        },
        responsibles: [
            { id: '1', name: 'Sevgi Işık' },
        ],
        status: RBCEventStatus.APPROVED
    },
    {
        title: "Yıllık Planlama Toplantısı",
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10), // 10 gün sonrası
        end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10), // 10 gün sonrası
        desc: "Bu, yıllık planlama toplantısının açıklama metnidir.",
        allDay: true,
        type: RBCEventType.OTHER,
        otherType: {
            label: "Planlama Toplantısı",
            value: '22',
        },
        campus: {
            label: "Merkez Kampüs",
            value: '3',
        },
        faculity: {
            label: "Mühendislik Fakültesi",
            value: '4',
        },
        building: {
            label: "C Blok",
            value: '5',
        },
        room: {
            label: "302 Konferans Odası",
            value: '30',
        },
        responsibles: [
            { id: '1', name: 'Prof. Dr. Ahmet Yılmaz' },
            { id: '2', name: 'Doç. Dr. Ayşe Kaya' },
        ],
        status: RBCEventStatus.PENDING,
    }
]