import * as React from 'react';
import { FormikProps } from "formik"
import Button from "../../components/button"
import TableBase from "../../components/table/Table"
import { ICreateFilterEvent } from "../../store/types"
import { TableType } from "../sketches/tools/interfaces"
import { IAvailablePlace } from "./constants"
import Translator from "../../services/translate-factory"
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';


const AvailablePlaceTable: React.FC<{ formikProps: FormikProps<ICreateFilterEvent> }> = ({ formikProps: props }) => {
    const T = new Translator()
    const { values, setFieldValue } = props
    return <TableBase
        type={TableType.AVALIABLE_EVENT}
        //TODO please remove results and disableFetch after the api connection done
        // please remove this line after the fetch is done
        results={[
            {
                id: 1,
                "name": "B-E-208",
                "campus_name": "Merkez Kampüs",
                "building_name": "E Blok",
                "floor": "2",
                "lecture_capacity": 100,
                "exam_capacity": 100,
                "sitting_type": "Sabit Sira",
                "seating_arrangement": "Kolcakli Sandalye Yerlesim",
                "feature": ["AT", "Bil", "DVD", "Klima", "SbtPer", "Kullanicinin ozelligi"]
            },
            {
                id: 2,
                "name": "C-E-209",
                "campus_name": "Merkez Kampüs",
                "building_name": "F Blok",
                "floor": "2",
                "lecture_capacity": 200,
                "exam_capacity": 150,
                "sitting_type": "Sandalye",
                "seating_arrangement": "Sirali Yerlesim",
                "feature": ["SesSist"]
            },
        ]}
        // please remove this line after the fetch is done
        disableFetch={true}
        // 
        spinner="available-place-table"
        notFoundMessage={props.values.isSearched ? T.t("gen_no_data_found") : T.t("gen_select_campus_before_search")}
        columnComponent={[
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_name"),
                parseName: "name",
            },
            {
                __componentType: 'oneLine',
                parseName: '',
                __headerType: 'default',
                title: T.t("gen_campus") + "/" + T.t("gen_building"),
                customRender: (record: IAvailablePlace) => {
                    return (
                        <td className="text-center">
                            <span>{record.campus_name}</span>
                            <span>/</span>
                            <span>{record.building_name}</span>
                        </td>
                    )
                }
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_floor"),
                parseName: "floor",
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_lecture_capacity"),
                parseName: "lecture_capacity",
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_exam_capacity"),
                parseName: "exam_capacity",
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_sitting_type"),
                parseName: "sitting_type",
                translateMap: ClassroomSeatingTypeOptions(T)
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_seating_arrangement"),
                parseName: "seating_arrangement",
                translateMap: ClassroomSeatingArrangementOptions(T)
            },
            {
                __componentType: 'multiLine',
                __headerType: 'default',
                title: T.t("gen_feature"),
                parseName: "feature",
                translateMap: ClassroomFeatureOptions(T)
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                parseName: '',
                customRender: (record: IAvailablePlace) => {
                    const isSelected = values.selectedPlace && values.selectedPlace.name === record.name

                    return (
                        <td className="text-center tw-w-[128px]">
                            <Button color={
                                isSelected ? "green" : "blue"
                            } onClick={() => !isSelected ? setFieldValue('selectedPlace', record) : setFieldValue('selectedPlace', undefined)}>
                                {isSelected ? T.t('gen_selected') : T.t('gen_select')}
                            </Button>
                        </td>
                    )
                }
            }
        ]}
    />
}

export default AvailablePlaceTable