import React, { Component } from 'react';
import { Formik, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Log } from 'ng2-logger';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ProgramSearchInitialValues, EducationTypeSelectOptions } from '../../store/constants/program-const';

import Spinner from '../../components/templates/spinner';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';

import Translator from '../../services/translate-factory';

const T = Translator.create();

const equal = require('deep-equal');
const L = Log.create('ProgramImportForm');

class ProgramImportForm extends Component<Types.IProgramImportFormProps, Types.IProgramImportFormState> {
  state: Types.IProgramImportFormState = {
    filters: ProgramSearchInitialValues,
    filterIsOpen: false,
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.init();
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.formIsOpen && this.props.formIsOpen) {
      this.init();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.init();
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  init() {
    this.state = {
      filters: Object.assign({}, ProgramSearchInitialValues),
      filterIsOpen: false,
      all_ids: [],
      selected_ids: []
    };
    this.state.filters.page = 1;
    this.setState(this.state);
    this.searchPrograms();
    this.getProgramSelectOptions();
  }

  searchPrograms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.program.PROGRAM_IMPORT_LIST_SEARCH, this.state.filters, 'program-import-form-spin')
    );
  }

  getProgramSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.program.PROGRAM_IMPORT_GET_SELECT_OPTIONS, 'program-import-form-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchPrograms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchPrograms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ProgramSearchInitialValues);
    this.setState(this.state);
    this.searchPrograms();
  };

  onFilterProgram(model: Types.IFilterProgram, FormActions: FormikActions<Types.IFilterProgram>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchPrograms();
    FormActions.setSubmitting(false);
  }

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.program.PROGRAM_IMPORT_LIST_SEARCH,
            this.state.filters,
            'program-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectProgram = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onFormSave(props: Types.IProgramImportFormState, FormActions: FormikActions<Types.IProgramImportFormState>) {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
    };
    const program_ids = props.selected_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.program.PROGRAM_IMPORT_CREATE, program_ids, 'program-import-form-spin', resultCallback)
    );
    FormActions.setSubmitting(false);
  };

  translateEducationTypeCode = (programCode: string) => {
    let educationTypeCodes = ["NO", "IO", "Uzaktan Eğitim"];

    if (educationTypeCodes.some(s => programCode.endsWith(s))) {
      if (programCode.endsWith("Uzaktan Eğitim")) {
        let code = "Uzaktan Eğitim"
        let parts = programCode.split(code);
        return parts.join("") + " " + T.t(code);
      } else {
        let parts = programCode.split(" ");
        let code = parts.pop()
        return parts.join(" ") + " " + T.t(code);
      }
    }
    else {
      return programCode;
    }
  }

  static getDerivedStateFromProps(props: Types.IProgramImportFormProps, state: Types.IProgramImportFormState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    }
    else return null;
  }

  render() {
    let programList = this.props.results;
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
        >
          {(props: FormikProps<Types.IProgramImportFormState>) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <Spinner name="program-import-form-spin" />
                        <h5 className="modal-title">
                          <span>
                            <i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_program')}
                          </span>
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="white-container mt-4">
                          <div className="row align-items-center mb-1">
                            <div className="col-md-12">
                              <div className="col-md-12 text-right">
                                <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                  <button
                                    id='button_filter'
                                    type='button'
                                    className="category-tag-square tag-glass float-right ml-3 mr-3"
                                    style={{ margin: '5px' }}
                                    onClick={() => {
                                      this.state.filterIsOpen = !this.state.filterIsOpen;
                                      this.setState(this.state);
                                    }}
                                  >
                                    <i className="material-icons mr-2">filter_list</i>
                                    <span>{T.t('gen_filter')}</span>
                                  </button>
                                </div>
                              </div>
                              {/* FILTER STARTS HERE  */}
                              <div
                                className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                                id="advance-search"
                              >
                                <div className="advance-search d-block mt-3" style={{}}>
                                  <Formik
                                    initialValues={ProgramSearchInitialValues}
                                    enableReinitialize={true}
                                    onSubmit={(values, actions) => {
                                      this.onFilterProgram(values, actions);
                                    }}
                                    onReset={this.onFormReset}
                                  >
                                    {(props: FormikProps<Types.IFilterProgram>) => {
                                      return (
                                        <form onSubmit={props.handleSubmit}>
                                          <div className="row">
                                            <div className="col-12">
                                              <h6>{T.t('gen_filter_parameters')}</h6>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                              <div className="form-input form-group date-picker">
                                                <input
                                                  id="program_code"
                                                  name="program_code"
                                                  value={props.values.program_code}
                                                  onChange={props.handleChange}
                                                  type="text"
                                                  required
                                                />
                                                <span className="highlight" />
                                                <span className="bar" />
                                                <label htmlFor="program_code">{T.t('gen_code')}</label>
                                                <i className="material-icons">filter_1</i>
                                              </div>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                              <div className="form-input form-group date-picker">
                                                <input
                                                  id="name"
                                                  name="name"
                                                  value={props.values.name}
                                                  onChange={props.handleChange}
                                                  type="text"
                                                  required
                                                />
                                                <span className="highlight" />
                                                <span className="bar" />
                                                <label htmlFor="name">{T.t('gen_name')}</label>
                                                <i className="material-icons">title</i>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="add-custom-tag mb-3">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_faculty_college')}</label>
                                                  <Select
                                                    id='select_faculty'
                                                    className="react-select"
                                                    isMulti={true}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={false}
                                                    options={
                                                      this.props.selectOptions && this.props.selectOptions.faculties
                                                        ? this.props.selectOptions.faculties
                                                        : []
                                                    }
                                                    placeholder={T.t('gen_select_faculty')}
                                                    value={props.values.faculties ? props.values.faculties : null}
                                                    onChange={(
                                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                    ) => {
                                                      const list: Array<Types.ISelectOption> = options
                                                        ? (options as Array<Types.ISelectOption>)
                                                        : [];
                                                      props.setFieldValue('faculties', list);
                                                      props.setFieldValue(
                                                        'faculty_ids',
                                                        list.map((item) => item.value)
                                                      );
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="add-custom-tag mb-3">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_education_type')}</label>
                                                  <Select
                                                    id='select_education_type'
                                                    className="react-select"
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                    options={EducationTypeSelectOptions(T)}
                                                    placeholder={T.t('gen_select_education_type')}
                                                    value={props.values.education_types_select}
                                                    onChange={(
                                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                    ) => {
                                                      const list: Array<Types.ISelectOption> = options
                                                        ? (options as Array<Types.ISelectOption>)
                                                        : [];
                                                      props.setFieldValue('education_types_select', list);
                                                      props.setFieldValue(
                                                        'education_types',
                                                        list.map((item) => item.value)
                                                      );
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {this.props.term_type !== 1 ? (
                                              <div className="col-md-4">
                                                <div className="add-custom-tag mb-3">
                                                  <div className="react-select-container">
                                                    <label>{T.t('gen_grade')}</label>
                                                    <Select
                                                      id='select_grade'
                                                      className="react-select"
                                                      isMulti={true}
                                                      closeMenuOnSelect={false}
                                                      options={
                                                        this.props.selectOptions && this.props.selectOptions.all_classes
                                                          ? this.props.selectOptions.all_classes
                                                          : []
                                                      }
                                                      placeholder={T.t('gen_select_grade')}
                                                      value={props.values.class ? props.values.class : null}
                                                      onChange={(
                                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                      ) => {
                                                        const list: Array<Types.ISelectOption> = options
                                                          ? (options as Array<Types.ISelectOption>)
                                                          : [];
                                                        props.setFieldValue('class', list);
                                                        props.setFieldValue(
                                                          'class_ids',
                                                          list.map((item) => item.value)
                                                        );
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-md-6">
                                              <div className="text-left">
                                                <h6>{T.t('gen_status')}</h6>
                                                <div className="tick-radio position-relative mb-3 d-inline-block">
                                                  <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                                </div>
                                                <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                                  <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="row mt-3">
                                            <div className="col-6">
                                              <button
                                                id='button_arrow_upward'
                                                type="button"
                                                onClick={() => {
                                                  this.state.filterIsOpen = false;
                                                  this.setState(this.state);
                                                }}
                                                className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                              >
                                                <i className="material-icons">arrow_upward</i>
                                              </button>
                                              <button
                                                id='button_delete_sweep'
                                                type="reset"
                                                onClick={props.handleReset}
                                                className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                              >
                                                <i className="material-icons">delete_sweep</i>
                                              </button>
                                            </div>
                                            <div className="col-6 text-right">
                                              <button
                                                id='button_search'
                                                type="button"
                                                className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                                onClick={() => props.handleSubmit()}
                                                disabled={props.isSubmitting}
                                              >
                                                <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                    <thead>
                                      <tr>
                                        <th data-cell="select">
                                          <div className="tick-radio position-relative">
                                            <input
                                              id='select_all'
                                              type="checkbox"
                                              className="form-radio"
                                              checked={this.checkAllIdsSelected()}
                                              onChange={this.onSelectAll}
                                            />
                                          </div>
                                        </th>
                                        <SortedColumn
                                          datacell="program_code"
                                          title={T.t('gen_code')}
                                          sortkey="program_code"
                                          sortedcolumn={this.state.filters.order_by}
                                          sort={this.sort}
                                        />
                                        <SortedColumn
                                          datacell="name"
                                          className="d-none d-lg-table-cell d-xl-table-cell"
                                          title={T.t('gen_name')}
                                          sortkey="name"
                                          sortedcolumn={this.state.filters.order_by}
                                          sort={this.sort}
                                        />
                                        <SortedColumn
                                          datacell="faculty_name"
                                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                          title={T.t('gen_faculty_college')}
                                          sortkey="faculty_name"
                                          sortedcolumn={this.state.filters.order_by}
                                          sort={this.sort}
                                        />
                                        <th scope="col" className="text-center">
                                          {T.t('gen_education_type')}
                                        </th>
                                        {this.props.term_type !== 1 ? (
                                          <th scope="col" className="text-center">
                                            {T.t('gen_grade')}
                                          </th>
                                        ) : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {programList && programList.length
                                        ? programList.map((item: any) => (
                                          <tr key={'program-' + item.program_id} data-title={item.name}>
                                            <td data-cell="select">
                                              <div className="tick-radio position-relative">
                                                <input
                                                  id='select_program'
                                                  type="checkbox"
                                                  className="form-radio"
                                                  checked={
                                                    this.state.selected_ids &&
                                                    this.state.selected_ids.indexOf(item.program_id ? item.program_id : -1) > -1
                                                  }
                                                  data-id={item.program_id}
                                                  onChange={this.onSelectProgram}
                                                />
                                              </div>
                                            </td>
                                            <td scope="row" data-label={T.t('gen_code')}>
                                              {this.props.term_type !== 1 ? this.translateEducationTypeCode(item.program_code) : item.program_code}
                                            </td>
                                            <td data-label={T.t('gen_name')}>{item.name}</td>
                                            <td data-label={T.t('gen_faculty_college')} className="text-center">
                                              {item.faculty_name}
                                            </td>
                                            <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                              {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                                EducationTypeSelectOptions(T)[0].label :
                                                item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                                  EducationTypeSelectOptions(T)[1].label :
                                                  item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                                    EducationTypeSelectOptions(T)[2].label : item.education_type
                                              }
                                            </td>
                                            {this.props.term_type === 0 ? (
                                              <td data-label={T.t('gen_grade')} className="text-center">
                                                {item.class}
                                              </td>
                                            ) : null}
                                          </tr>
                                        ))
                                        : null}
                                    </tbody>
                                  </table>
                                  <div className="row-options justify-content-end">
                                    <div
                                      className="page-sorting d-flex align-items-center justify-content-center"
                                      style={{ marginTop: '5px' }}
                                    >
                                      {this.props.results && this.props.results.length > 0 && (
                                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              disabled={this.state.selected_ids && this.state.selected_ids.length < 1}
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramImportFormProps): Types.IProgramImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IProgramImportFormProps = Object.assign({}, ownProps, {
    user: store.state.user,
    results: store.state.program_import_form && store.state.program_import_form.results,
    filters: store.state.program_import_form && store.state.program_import_form.filters,
    selectOptions: store.state.select_options && store.state.select_options.programImportForm,
    term_type: store.state.term_type
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.program_import_form) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.program_import_form && prev.state.program_import_form.results,
        next.state.program_import_form && next.state.program_import_form.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programImportForm,
        next.state.select_options && next.state.select_options.programImportForm
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ProgramImportForm);

export default container;
