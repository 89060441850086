import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const BuildingFormValidation = (T: Translator) => Yup.object().shape({
  building_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  campus_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000')),
  floor_count: Yup.number().required(T.t('gen_cannot_leave_empty')).min(0, T.t('gen_must_be_positive')),
});
