import React from 'react';

interface InputFieldProps extends React.ComponentProps<'input'> {
    id: string;
    label?: string;
    icon?: string; // İkon ismi
}

const InputField: React.FC<InputFieldProps> = ({ id, label, icon, ...otherProps }) => {
    return (
        <div className="form-input form-group date-picker mt-2">
            <input
                className='mt-2'
                id={id}
                name={id}
                type="text"
                {...otherProps}
                value={otherProps.value || ""}
            />
            <span className="highlight" />
            <span className="bar" />
            <label htmlFor={id} className="tw-text-sm">{label}</label>
            {icon && <i className="material-icons">{icon}</i>}
        </div>
    );
};

export default InputField;
