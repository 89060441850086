import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import Flatpickr from 'react-flatpickr';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { FileInitialValues } from '../../store/constants/file-const';
import { EmailServiceSelectOptions, ClassroomInvigilatorCountSettings, InvigilatorAssignTypes, SettingsEventModuleStatus } from '../../store/constants/setting-const';
import { TermExamTypesLabel, InstructorTitlesLabel, ActivityTypesLabel, ClassroomFeaturesLabel } from '../../store/constants/setting-const';
import { Formik, FormikActions, FormikProps } from 'formik';
import moment from 'moment';
import 'moment/locale/tr';
import Switch from 'react-switch';
import { UncontrolledTooltip } from 'reactstrap';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import CalendarSettings from './calendar-setting';
import ImageHolder from '../../components/image/image-holder';
import imgLogo from '../../assets_aplan/images/plan-365-logo.png';
import Translator from '../../services/translate-factory';
import * as GT from '../../tools/general-tools';
import DefinitionsSettings from './defitinions-settings';

const T = Translator.create();
const L = Log.create('GroupListPage');

let invigilatorCountsDueToStudentCount: Array<any> = new Array<any>();
let prevInvigilatorCountsDueToStudentCount: Array<any> = new Array<any>();
let validationInvigilatorByStudent: boolean = false;
let validationOnlineInvigilatorByStudent: boolean = false;

let termExamTypes: Array<any> = new Array<any>();
let termExamTypesTemp: Array<any> = new Array<any>();
let termExamTypesAddButtonIsActive: boolean = false;
let termExamTypesAddButtonIsClicked: boolean = false;

let instructorTitles: Array<any> = new Array<any>();
let instructorTitlesTemp: Array<any> = new Array<any>();
let instructorTitlesAddButtonIsActive: boolean = false;
let instructorTitlesAddButtonIsClicked: boolean = false;

let activityTypes: Array<any> = new Array<any>();
let activityTypesTemp: Array<any> = new Array<any>();
let activityTypesAddButtonIsActive: boolean = false;
let activityTypesAddButtonIsClicked: boolean = false;

let classroomFeatures: Array<any> = new Array<any>();
let classroomFeaturesTemp: Array<any> = new Array<any>();
let classroomFeaturesAddButtonIsActive: boolean = false;
let classroomFeaturesAddButtonIsClicked: boolean = false;

let courseLanguages: Array<any> = new Array<any>();
let courseLanguagesTemp: Array<any> = new Array<any>();
let courseLanguagesAddButtonIsActive: boolean = false;
let courseLanguagesAddButtonIsClicked: boolean = false;

let InvigilatorSettingsByFaculty: Array<any> = new Array<any>();
let InvigilatorSettingsByFacultyAddButtonIsActive: boolean = false;
let InvigilatorSettingsByFacultyFielsIsEmpty: boolean = false;

let isMinDayRestrictionsCountError: boolean = false;
let isMinConsecutiveSlotsCountError: boolean = false;

class APlanSettings extends Component<any, Types.IAplanSettingsPageState> {
  state: Types.IAplanSettingsPageState = Object.assign({
    locale: '',
    settings_online_student_invigilator: []
  });

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  constructor(props: any) {
    super(props)
    this.state.locale = GT.getLocaleFromLangCode();
    this.setModelSettings = this.setModelSettings.bind(this)
  }

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getSettings();
    this.getCourseSelectOptions();
    this.setState(this.state);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    termExamTypesTemp = [];
    instructorTitlesTemp = [];
    activityTypesTemp = [];
    classroomFeaturesTemp = [];
    courseLanguagesTemp = [];
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'course-list-spin'));
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  searchTermExamType(index: any, item: any) {
    const resultCallback = (result: any, status: number) => {
      setTimeout(() => {
        if (result && result.data && result.data.length) {
          this.onDeleteTermExamType(index, item)
        } else {
          this.onDeleteTermExamTypeRow(index, item);
        }
      }, 1000);
    };

    let TermSearchFilterModel = {
      exam_type_ids: [item.id],
      status: [1],
      select_all: false,
      order_by: '',
      page: 1,
      query: '',
      total: -1,
      size: 10
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_LIST_SEARCH, TermSearchFilterModel, '', resultCallback)
    );
  }

  searchInstructorTitle(index: any, item: any) {
    const resultCallback = (result: any, status: number) => {
      setTimeout(() => {
        if (result && result.data && result.data.length) {
          this.onDeleteInstructorTitle(index, item)
        } else {
          this.onDeleteInstructorTitleRow(index, item);
        }
      }, 1000);
    };

    let InstructorSearchFilterModel = {
      title_ids: [item.name],
      status: [1],
      select_all: false,
      order_by: '',
      page: 1,
      query: '',
      total: -1,
      size: 10
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.instructor.INSTRUCTOR_LIST_SEARCH, InstructorSearchFilterModel, '', resultCallback)
    );
  }

  searchActivityType(index: any, item: any) {
    const resultCallback = (result: any, status: number) => {
      setTimeout(() => {
        if (result && result.data && result.data.length) {
          this.onDeleteActivityType(index, item)
        } else {
          this.onDeleteActivityTypeRow(index, item);
        }
      }, 1000);
    };

    let InstructorSearchFilterModel = {
      activity_type: item.name,
      status: [1],
      select_all: false,
      order_by: '',
      page: 1,
      query: '',
      total: -1,
      size: 10
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, InstructorSearchFilterModel, '', resultCallback)
    );
  }

  searchClassroomFeature(index: any, item: any) {
    const resultCallback = (result: any, status: number) => {
      setTimeout(() => {
        if (result && result.data && result.data.length) {
          this.onDeleteClassroomFeature(index, item)
        } else {
          this.onDeleteClassroomFeatureRow(index, item);
        }
      }, 1000);
    };

    let ClassroomSearchFilterModel = {
      classroom_feature_codes: [item.name],
      status: [1],
      select_all: false,
      order_by: '',
      page: 1,
      query: '',
      total: -1,
      size: 10
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, ClassroomSearchFilterModel, 'classroom-list-spin', resultCallback)
    );
  }


  // This function helps set states form from child components
  setModelSettings(newStates: any) {
    this.setState(prev => ({ ...prev, ...newStates }))
  }

  saveSettings = (model: Types.IAplanSettingsPageState, FormActions: FormikActions<Types.IAplanSettingsPageState>) => {
    model.classroom_types = this.state.classroom_types;
    model.building_functionalities = this.state.building_functionalities;
    model.roomClassroomFunctions = this.state.roomClassroomFunctions;
    model.classroom_usage_states = this.state.classroom_usage_states;
    model.course_languages = this.state.course_languages;
    model.term_exam_types = this.state.term_exam_types;
    model.instructor_titles = this.state.instructor_titles;
    model.activity_types = this.state.activity_types;
    model.classroom_features = this.state.classroom_features;
    model.invigilator_settings_by_faculty = this.state.invigilator_settings_by_faculty;
    model.invigilator_counts_in_online_exams = this.state.invigilator_counts_in_online_exams;
    model.classroom_categories = this.state.classroom_categories;
    model.classroom_attributes = this.state.classroom_attributes;
    model.classroom_seating_types = this.state.classroom_seating_types;
    model.classroom_seating_arrangements = this.state.classroom_seating_arrangements;

    let instructorMinimumAvailability = {
      active: model.show_instructor_minimum_availability,
      min_day_restrictions_count: model.min_day_restrictions_count,
      min_consecutive_slots_count: model.min_consecutive_slots_count
    }

    model.instructor_minimum_availability = JSON.stringify(
      instructorMinimumAvailability
    );

    if (model.show_instructor_minimum_availability && (isMinConsecutiveSlotsCountError || isMinDayRestrictionsCountError)) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_error'),
          body: T.t('settings_instructor_minimum_availability_count_error'),
          name: 'user_import_error',
          icon: 'error_outline',
          iconColor: 'red'
        })
      );
    } else {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('settings_save_title'),
          body: T.t('settings_save_question'),
          name: 'courseHours_save',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_save'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.getSettings();
                this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, '', 'general-settings-spin', resultCallback));
              }
            };
            this.props.dispatch(
              Actions.ApiRequest(Constants.setting.UPDATE_SETTINGS, model, 'exam-period-modal-tab-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  onDeleteTermExamType = (index: any, item: any) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_delete_action'),
        body: T.t('gen_delete_term_exam_type_question'),
        name: 'term_exam_type_delete',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_cancel'),
      })
    );
  };

  onDeleteInstructorTitle = (index: any, item: any) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_delete_action'),
        body: T.t('gen_delete_instructor_title_question'),
        name: 'instructor_title_delete',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_cancel'),
      })
    );
  };

  onDeleteActivityType = (index: any, item: any) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_delete_action'),
        body: T.t('gen_delete_activity_type_question'),
        name: 'activity_type_delete',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_cancel'),
      })
    );
  };

  onDeleteClassroomFeature = (index: any, item: any) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_delete_action'),
        body: T.t('gen_delete_classroom_feature_question'),
        name: 'classroom_feature_delete',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_cancel'),
      })
    );
  };


  static getDerivedStateFromProps(props: any, state: Types.IAplanSettingsPageState) {
    let hasNewState: boolean = false;
    if (props.settings && state.term_id == null) {
      state = props.settings;

      if (props.settings.instructor_minimum_availability) {
        let instructorMinimumAvailability = JSON.parse(props.settings.instructor_minimum_availability);
        state.show_instructor_minimum_availability = instructorMinimumAvailability.active;
        state.min_day_restrictions_count = instructorMinimumAvailability.min_day_restrictions_count;
        state.min_consecutive_slots_count = instructorMinimumAvailability.min_consecutive_slots_count;
      }

      if (props.settings.term_exam_types && !props.settings.term_exam_types.includes('isDeleted')) {
        termExamTypesTemp = JSON.parse(props.settings.term_exam_types);

        termExamTypesTemp.forEach((item: any) => {
          let model = {
            id: item.id,
            name: item.name,
            isDeleted: false
          }
          termExamTypesTemp = termExamTypesTemp.filter((item: any) => item.id != model.id);
          termExamTypesTemp.push(model);
        });

        state.term_exam_types = JSON.stringify(
          termExamTypesTemp
        );
      }

      if (props.settings.instructor_titles && !props.settings.instructor_titles.includes('isDeleted')) {
        instructorTitlesTemp = JSON.parse(props.settings.instructor_titles);

        instructorTitlesTemp.forEach((item: any) => {
          let model = {
            id: item.id,
            name: item.name,
            isDeleted: false
          }
          instructorTitlesTemp = instructorTitlesTemp.filter((item: any) => item.id != model.id);
          instructorTitlesTemp.push(model);
        });

        state.instructor_titles = JSON.stringify(
          instructorTitlesTemp
        );
      }

      if (props.settings.activity_types && !props.settings.activity_types.includes('isDeleted')) {
        activityTypesTemp = JSON.parse(props.settings.activity_types);

        activityTypesTemp.forEach((item: any) => {
          let model = {
            id: item.id,
            name: item.name,
            isDeleted: false
          }
          activityTypesTemp = activityTypesTemp.filter((item: any) => item.id != model.id);
          activityTypesTemp.push(model);
        });

        state.activity_types = JSON.stringify(
          activityTypesTemp
        );
      }

      if (props.settings.classroom_features && !props.settings.classroom_features.includes('isDeleted')) {
        classroomFeaturesTemp = JSON.parse(props.settings.classroom_features);

        classroomFeaturesTemp.forEach((item: any) => {
          let model = {
            id: item.id,
            name: item.name,
            isDeleted: false
          }
          classroomFeaturesTemp = classroomFeaturesTemp.filter((item: any) => item.id != model.id);
          classroomFeaturesTemp.push(model);
        });

        state.classroom_features = JSON.stringify(
          classroomFeaturesTemp
        );
      }


      if (props.settings.course_languages && !props.settings.course_languages.includes('isDeleted')) {
        courseLanguagesTemp = JSON.parse(props.settings.course_languages);

        courseLanguagesTemp.forEach((item: any) => {
          let model = {
            id: item.id,
            name: item.name,
            isDeleted: false
          }
          courseLanguagesTemp = courseLanguagesTemp.filter((item: any) => item.id != model.id);
          courseLanguagesTemp.push(model);
        });

        state.course_languages = JSON.stringify(
          courseLanguagesTemp
        );
      }

      hasNewState = true;
    }

    if (InvigilatorSettingsByFaculty.length != 0) {
      state.invigilator_settings_collapse = "1";
    } else {
      state.invigilator_settings_collapse = "0";
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onUploadLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.state.institution_logo = URL.createObjectURL(img);
      this.setState(this.state);
    }
  };

  onBrandLogoUploadRequest = (imageHolder: ImageHolder) => {
    let options: Types.IUploadRequestOptions = {
      accept: 'image/*',
      maxSize: 100001
    };

    if (this.props.uploadOptions) {
      options.fileMaxSizes = this.props.uploadOptions.fileMaxSizes;
    }

    let file: Types.IFileView = Object.assign({}, FileInitialValues);
    file.shared = false;
    file.container = '_logo[brand]';

    const resultCallback = (file: Types.IFileView, status: string) => {
      L.data('resultCallback :', file, status);
      if (status === 'uploaded') {
        if (file) {
          if (imageHolder) {
            this.state.institution_logo = file.container;
            imageHolder.updateImage(file.container);
            this.setState(this.state);
            this.props.dispatch(Actions.Notification('notification_logo_saved', 'gen_success'))
          }
        }
      }
      else {
        this.props.dispatch(Actions.Notification('notification_logo_wrong_size', 'gen_error', 'danger'))
      }
    };

    this.props.dispatch(Actions.UploadRequest(options, file, resultCallback));
  };

  onChangeRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      this.state.user_entry_type = Number(e.currentTarget.value);
      this.setState(this.state);
    }
  }

  onAddRow = (invigilatorCountsDueToStudentCount: Array<any>) => {
    if (this.validateMinMax(this.state).length > 0) {
      return;
    }
    prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;

    let model;
    if (invigilatorCountsDueToStudentCount.length == 0) {
      model = {
        option: 1,
        min: 1,
        max: 40,
        num_of_invigilators: 1
      }
    } else {
      let interval = Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].max) - Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].min);
      let min = Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].max) + 1;
      model = {
        option: invigilatorCountsDueToStudentCount.length,
        min: min.toString(),
        max: (min + interval).toString(),
        num_of_invigilators: (invigilatorCountsDueToStudentCount.length + 1).toString()
      }
    }

    invigilatorCountsDueToStudentCount.push(model)
    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
      invigilatorCountsDueToStudentCount
    );
    this.setState(this.state);
  };

  onDeleteRow = (index: any) => {
    prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;
    invigilatorCountsDueToStudentCount.splice(index, 1);
    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
      invigilatorCountsDueToStudentCount
    );
    this.setState(this.state);
  };

  onAddTermExamTypeRow = (exam_type: string) => {
    termExamTypes = JSON.parse(this.state.term_exam_types);
    let exam_type_names = termExamTypes.filter((item: any) => item.isDeleted == false).map((item: any) => item.name.toLowerCase());
    let exam_type_names_localized = termExamTypes.filter((item: any) => item.isDeleted == false).map((item: any) => TermExamTypesLabel(item.id, T) === "-" ? item.name.toLowerCase() : TermExamTypesLabel(item.id, T).toLowerCase());
    if (!exam_type_names.includes(exam_type.toLowerCase()) && !exam_type_names_localized.includes(exam_type.toLowerCase())) {
      let model = {
        id: termExamTypesTemp.length + 1,
        name: exam_type,
        isDeleted: false
      }

      termExamTypes.push(model);
      termExamTypesTemp.push(model);
      this.state.term_exam_types = JSON.stringify(
        termExamTypes
      );

      termExamTypesAddButtonIsActive = false;
      termExamTypesAddButtonIsClicked = true;
    } else {
      termExamTypesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  onAddInstructorTitleRow = (instructor_title: string) => {
    instructorTitles = JSON.parse(this.state.instructor_titles);
    let instructor_title_names = instructorTitles.filter((item: any) => item.isDeleted == false).map((item: any) => item.name.toLowerCase());
    let instructor_title_names_localized = instructorTitles.filter((item: any) => item.isDeleted == false).map((item: any) => InstructorTitlesLabel(item.id, T) === "-" ? item.name.toLowerCase() : InstructorTitlesLabel(item.id, T).toLowerCase());
    if (!instructor_title_names.includes(instructor_title.toLowerCase()) && !instructor_title_names_localized.includes(instructor_title.toLowerCase())) {
      let model = {
        id: instructorTitlesTemp.length + 1,
        name: instructor_title,
        isDeleted: false
      }

      instructorTitles.push(model);
      instructorTitlesTemp.push(model);
      this.state.instructor_titles = JSON.stringify(
        instructorTitles
      );

      instructorTitlesAddButtonIsActive = false;
      instructorTitlesAddButtonIsClicked = true;
    } else {
      instructorTitlesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  onAddActivityTypeRow = (activity_type: string) => {
    activityTypes = JSON.parse(this.state.activity_types);
    let activity_type_names = activityTypes.filter((item: any) => item.isDeleted == false).map((item: any) => item.name.toLowerCase());
    let activity_type_names_localized = activityTypes.filter((item: any) => item.isDeleted == false).map((item: any) => ActivityTypesLabel(item.id, T) === "-" ? item.name.toLowerCase() : ActivityTypesLabel(item.id, T).toLowerCase());
    if (!activity_type_names.includes(activity_type.toLowerCase()) && !activity_type_names_localized.includes(activity_type.toLowerCase())) {
      let model = {
        id: activityTypesTemp.length + 1,
        name: activity_type,
        isDeleted: false
      }

      activityTypes.push(model);
      activityTypesTemp.push(model);
      this.state.activity_types = JSON.stringify(
        activityTypes
      );

      activityTypesAddButtonIsActive = false;
      activityTypesAddButtonIsClicked = true;
    } else {
      activityTypesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  onAddClassroomFeatureRow = (classroom_feature: string) => {
    classroomFeatures = JSON.parse(this.state.classroom_features);
    let classroom_feature_names = classroomFeatures.filter((item: any) => item.isDeleted == false).map((item: any) => item.name.toLowerCase());
    let classroom_feature_names_localized = classroomFeatures.filter((item: any) => item.isDeleted == false).map((item: any) => ClassroomFeaturesLabel(item.id, T) === "-" ? item.name.toLowerCase() : ClassroomFeaturesLabel(item.id, T).toLowerCase());
    if (!classroom_feature_names.includes(classroom_feature.toLowerCase()) && !classroom_feature_names_localized.includes(classroom_feature.toLowerCase())) {
      let model = {
        id: classroomFeaturesTemp.length + 1,
        name: classroom_feature,
        isDeleted: false
      }

      classroomFeatures.push(model);
      classroomFeaturesTemp.push(model);
      this.state.classroom_features = JSON.stringify(
        classroomFeatures
      );

      classroomFeaturesAddButtonIsActive = false;
      classroomFeaturesAddButtonIsClicked = true;
    } else {
      classroomFeaturesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  onDeleteTermExamTypeRow = (index: any, item: any) => {
    termExamTypes = termExamTypes.filter((exam_type: any) => exam_type.id != item.id);
    let model = {
      id: item.id,
      name: item.name,
      isDeleted: true
    }
    termExamTypes.push(model);

    this.state.term_exam_types = JSON.stringify(
      termExamTypes
    );
    this.setState(this.state);
  };

  onDeleteInstructorTitleRow = (index: any, item: any) => {
    instructorTitles = instructorTitles.filter((instructor_title: any) => instructor_title.id != item.id);
    let model = {
      id: item.id,
      name: item.name,
      isDeleted: true
    }
    instructorTitles.push(model);

    this.state.instructor_titles = JSON.stringify(
      instructorTitles
    );
    this.setState(this.state);
  };

  onDeleteActivityTypeRow = (index: any, item: any) => {
    activityTypes = activityTypes.filter((activity_type: any) => activity_type.id != item.id);
    let model = {
      id: item.id,
      name: item.name,
      isDeleted: true
    }
    activityTypes.push(model);

    this.state.activity_types = JSON.stringify(
      activityTypes
    );
    this.setState(this.state);
  };

  onDeleteClassroomFeatureRow = (index: any, item: any) => {
    classroomFeatures = classroomFeatures.filter((classroom_feature: any) => classroom_feature.id != item.id);
    let model = {
      id: item.id,
      name: item.name,
      isDeleted: true
    }
    classroomFeatures.push(model);

    this.state.classroom_features = JSON.stringify(
      classroomFeatures
    );
    this.setState(this.state);
  };

  onAddInvigilatorSettingsByFacultyRow = (faculty: Types.ISelectOption, classroom_invigilator_count: number, invigilator_assign_type: number) => {
    if (!faculty) {
      InvigilatorSettingsByFacultyFielsIsEmpty = true;
    } else {
      let faculty_code = InvigilatorSettingsByFaculty.map((item: any) => item.faculty_code.toLowerCase());

      if (!faculty_code.includes(faculty.value.toLowerCase())) {
        let model = {
          faculty_code: faculty.value,
          faculty_name: faculty.label,
          classroom_invigilator_count: classroom_invigilator_count,
          invigilator_assign_type: invigilator_assign_type,
        }

        InvigilatorSettingsByFaculty.push(model);
        this.state.invigilator_settings_by_faculty = JSON.stringify(
          InvigilatorSettingsByFaculty
        );

        InvigilatorSettingsByFacultyFielsIsEmpty = false;
        InvigilatorSettingsByFacultyAddButtonIsActive = false;
      } else {
        InvigilatorSettingsByFacultyAddButtonIsActive = true;
      }
    }

    this.setState(this.state);
  };

  onDeleteInvigilatorSettingsByFacultyRow = (index: any) => {
    InvigilatorSettingsByFaculty.splice(index, 1);
    this.state.invigilator_settings_by_faculty = JSON.stringify(
      InvigilatorSettingsByFaculty
    );
    this.setState(this.state);
  };

  findDuplicates = (arr: any) => {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  //#region CourseLanguage

  onDeleteCourseLanguageRow = (index: any, item: any) => {
    courseLanguages = courseLanguages.filter((course_languages: any) => course_languages.id != item.id);
    let model = {
      id: item.id,
      name: item.name,
      isDeleted: true
    }
    courseLanguages.push(model);

    this.state.course_languages = JSON.stringify(
      courseLanguages
    );
    this.setState(this.state);
  };

  onDeleteCourseLanguage = (index: any, item: any) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_delete_action'),
        body: T.t('gen_delete_course_language_question'),
        name: 'course_language_delete',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_cancel'),
      })
    );
  };

  searchCourseLanguage(index: any, item: any) {
    const resultCallback = (result: any, status: number) => {
      setTimeout(() => {
        if (result && result.data && result.data.length) {
          this.onDeleteCourseLanguage(index, item)
        } else {
          this.onDeleteCourseLanguageRow(index, item);
        }
      }, 1000);
    };

    let CourseLanguageSearchFilterModel: Types.IFilterActivity = {
      course_language: item.id.toString(),
      term_id: this.state.term_id,
      select_all: false,
      order_by: '',
      page: 1,
      query: '',
      total: -1,
      size: 10
    };

    if (this.props.term_type === 0) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, CourseLanguageSearchFilterModel, 'activity-list-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.course.COURSE_LIST_SEARCH, CourseLanguageSearchFilterModel, 'course-list-spin', resultCallback)
      );
    }
  }

  onAddCourseLanguageRow = (course_language: string) => {
    courseLanguages = JSON.parse(this.state.course_languages);
    let course_language_names = courseLanguages.filter((item: any) => item.isDeleted == false).map((item: any) => item.name.toLowerCase());
    if (!course_language_names.includes(course_language.toLowerCase())) {
      let model = {
        id: courseLanguagesTemp.length + 1,
        name: course_language,
        isDeleted: false
      }

      courseLanguages.push(model);
      courseLanguagesTemp.push(model);
      this.state.course_languages = JSON.stringify(
        courseLanguages
      );

      courseLanguagesAddButtonIsActive = false;
      courseLanguagesAddButtonIsClicked = true;
    } else {
      courseLanguagesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  controlMinMaxData(data: Array<{ min: number, max: number, num_of_invigilators: number, option?: number, id?: number }>): string[] {
    const errors: string[] = [];
    const invigilatorCountSet = new Set<number>();

    for (let i = 0; i < data.length; i++) {
      const current = data[i];

      if (current.min >= current.max) {
        errors.push(`Error in min (${current.min}) should be less than max (${current.max}).`);
      }

      if (i > 0) {
        const previous = data[i - 1];
        if (current.min <= previous.max) {
          errors.push(`Error in min (${current.min}) should be greater than the previous max (${previous.max}).`);
        }
      }

      if (invigilatorCountSet.has(current.num_of_invigilators)) {

        errors.push(`Error: Duplicate invigilator count (${current.num_of_invigilators}) found in option ${current.option}.`);
      } else {
        invigilatorCountSet.add(current.num_of_invigilators);
      }
      if (current.num_of_invigilators <= 0) {
        errors.push(`Error: Invigilator count should be greater than 0 in option ${current.option}.`);
      }
    }
    return errors
  }

  validateMinMax(values: Types.IAplanSettingsPageState): string[] {
    if (this.props.term_type != 1) {
      return [];
    }

    const onlineStudent = this.controlMinMaxData(values.invigilator_counts_in_online_exams.map(_ => ({
      min: _.min || 0,
      max: _.max || 0,
      num_of_invigilators: _.invigilatorCount
    })))

    const invigilatorStudent = this.controlMinMaxData(JSON.parse(values.invigilator_counts_due_to_student_count, (key, value) => {
      if (key === 'min' || key === 'max' || key === 'num_of_invigilators') {
        return Number(value);
      }
      return value;
    }).map((_: any) => ({
      min: _.min || 0,
      max: _.max || 0,
      num_of_invigilators: _.num_of_invigilators
    })))

    return [...onlineStudent, ...invigilatorStudent];
  }

  //#endregion

  render() {
    const lang = localStorage.getItem("langid");
    L.data('aplan_settings_xx_lang', lang);

    if (this.state && this.state.invigilator_counts_due_to_student_count) {
      invigilatorCountsDueToStudentCount = JSON.parse(this.state.invigilator_counts_due_to_student_count);
    }

    let numOfInvigilators: Array<number> = invigilatorCountsDueToStudentCount.map((item: any) => Number(item.num_of_invigilators));
    if (this.findDuplicates(numOfInvigilators).length != 0) {
      validationInvigilatorByStudent = true;
    } else {
      validationInvigilatorByStudent = false;
    }

    if (this.state && this.state.invigilator_counts_in_online_exams) {
      let numOfOnlineInvigilators: Array<number> = this.state.invigilator_counts_in_online_exams.map((item) => Number(item.invigilatorCount));
      if (this.findDuplicates(numOfOnlineInvigilators).length != 0) {
        validationOnlineInvigilatorByStudent = true;
      } else {
        validationOnlineInvigilatorByStudent = false;
      }
    }

    if (prevInvigilatorCountsDueToStudentCount.length == 0) {
      prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;
    }

    if (this.state && this.state.invigilator_settings_by_faculty) {
      InvigilatorSettingsByFaculty = JSON.parse(this.state.invigilator_settings_by_faculty);
    }

    if (this.state && this.state.term_exam_types && termExamTypesTemp.length == 0) {
      termExamTypesTemp = JSON.parse(this.state.term_exam_types);
    }

    if (this.state && this.state.instructor_titles && instructorTitlesTemp.length == 0) {
      instructorTitlesTemp = JSON.parse(this.state.instructor_titles);
    }

    if (this.state && this.state.activity_types && activityTypesTemp.length == 0) {
      activityTypesTemp = JSON.parse(this.state.activity_types);
    }

    if (this.state && this.state.classroom_features && classroomFeaturesTemp.length == 0) {
      classroomFeaturesTemp = JSON.parse(this.state.classroom_features);
    }

    if (this.state && this.state.course_languages && courseLanguagesTemp.length == 0) {
      courseLanguagesTemp = JSON.parse(this.state.course_languages);
    }

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="building-page">
          <div className="main">
            <div className="container">
              <div className="row align-items-center mb-sm-4 mb-2">
                <div className="col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_settings')}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        id="general-tab"
                        data-toggle="tab"
                        href="#general"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('settings_institution')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="definitions-tab"
                        data-toggle="tab"
                        href="#definitions"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t("gen_definitions")}
                      </a>
                      {this.props.term_id !== -1 ? (
                        <a
                          className="nav-item nav-link"
                          id="planning-tab"
                          data-toggle="tab"
                          href="#term"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_term')}
                        </a>
                      ) : null}
                      <a
                        className="nav-item nav-link"
                        id="calendar-tab"
                        data-toggle="tab"
                        href="#calendar"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_calendar')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="email-tab"
                        data-toggle="tab"
                        href="#emailservice"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_email')}
                      </a>
                    </div>
                  </nav>
                  <Formik
                    enableReinitialize={true}
                    initialValues={this.state}
                    onSubmit={(values, actions) => {
                      this.saveSettings(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.IAplanSettingsPageState>) => {
                      const { values, handleChange, handleBlur, setFieldValue } = props;
                      values.invigilator_counts_due_to_student_count = this.state.invigilator_counts_due_to_student_count;

                      if (!!values.term_exam_type) {
                        termExamTypes = JSON.parse(this.state.term_exam_types);
                        termExamTypes = termExamTypes.filter(termExamType => (TermExamTypesLabel(termExamType.id, T) === "-" ? termExamType.name.toLowerCase() : TermExamTypesLabel(termExamType.id, T).toLowerCase()).includes(values.term_exam_type.toLowerCase()));
                      } else {
                        if (this.state && this.state.term_exam_types) {
                          termExamTypes = JSON.parse(this.state.term_exam_types);
                        }
                      }

                      if (termExamTypesAddButtonIsClicked) {
                        values.term_exam_type = '';
                        termExamTypes = JSON.parse(this.state.term_exam_types);
                        termExamTypesAddButtonIsClicked = false;
                      }

                      if (!!values.instructor_title) {
                        instructorTitles = JSON.parse(this.state.instructor_titles);
                        instructorTitles = instructorTitles.filter(instructorTitle => (InstructorTitlesLabel(instructorTitle.id, T) === "-" ? instructorTitle.name.toLowerCase() : InstructorTitlesLabel(instructorTitle.id, T).toLowerCase()).includes(values.instructor_title.toLowerCase()));
                      } else {
                        if (this.state && this.state.instructor_titles) {
                          instructorTitles = JSON.parse(this.state.instructor_titles);
                        }
                      }

                      if (instructorTitlesAddButtonIsClicked) {
                        values.instructor_title = '';
                        instructorTitles = JSON.parse(this.state.instructor_titles);
                        instructorTitlesAddButtonIsClicked = false;
                      }

                      if (!!values.activity_type) {
                        activityTypes = JSON.parse(this.state.activity_types);
                        activityTypes = activityTypes.filter(activityType => (ActivityTypesLabel(activityType.id, T) === "-" ? activityType.name.toLowerCase() : ActivityTypesLabel(activityType.id, T).toLowerCase()).includes(values.activity_type.toLowerCase()));
                      } else {
                        if (this.state && this.state.activity_types) {
                          activityTypes = JSON.parse(this.state.activity_types);
                        }
                      }

                      if (activityTypesAddButtonIsClicked) {
                        values.activity_type = '';
                        activityTypes = JSON.parse(this.state.activity_types);
                        activityTypesAddButtonIsClicked = false;
                      }

                      if (!!values.classroom_feature) {
                        classroomFeatures = JSON.parse(this.state.classroom_features);
                        classroomFeatures = classroomFeatures.filter(classroomFeature => (ClassroomFeaturesLabel(classroomFeature.id, T) === "-" ? classroomFeature.name.toLowerCase() : ClassroomFeaturesLabel(classroomFeature.id, T).toLowerCase()).includes(values.classroom_feature.toLowerCase()));
                      } else {
                        if (this.state && this.state.classroom_features) {
                          classroomFeatures = JSON.parse(this.state.classroom_features);
                        }
                      }

                      if (classroomFeaturesAddButtonIsClicked) {
                        values.classroom_feature = '';
                        classroomFeatures = JSON.parse(this.state.classroom_features);
                        classroomFeaturesAddButtonIsClicked = false;
                      }

                      if (!!values.course_language) {
                        courseLanguages = JSON.parse(this.state.course_languages);
                        courseLanguages = courseLanguages.filter(courseLanguage => courseLanguage.name.toLowerCase().includes(values.course_language.toLowerCase()));
                      } else {
                        if (this.state && this.state.course_languages) {
                          courseLanguages = JSON.parse(this.state.course_languages);
                        }
                      }

                      if (courseLanguagesAddButtonIsClicked) {
                        values.course_language = '';
                        courseLanguages = JSON.parse(this.state.course_languages);
                        courseLanguagesAddButtonIsClicked = false;
                      }

                      values.event_module_status_boolean = values.event_module_status === SettingsEventModuleStatus.Active;

                      return (
                        <form onSubmit={props.handleSubmit}>
                          <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general">
                              <div className="row mb-4">
                                <div className="col-md-6 form-input form-group with-icon">
                                  <h5>{T.t('settings_institution_info')}</h5>
                                  <input
                                    name="institution_name"
                                    id="institution_name"
                                    type="text"
                                    value={values.institution_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="institution_name" className="settings-label">{T.t('settings_institution_name')}</label>
                                </div>
                                <div className="col-md-6 form-input form-group with-icon">
                                  <h5 className="settings-radio-button-h5">{T.t('settings_instructor_entry_type')}</h5>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='email'
                                      type="radio"
                                      name="user_entry_type"
                                      value={0}
                                      checked={values.user_entry_type == 0}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label0">{T.t('gen_email')}</label>
                                  </div>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='instructor_code'
                                      type="radio"
                                      name="user_entry_type"
                                      value={1}
                                      checked={values.user_entry_type == 1}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label1">{T.t('gen_instructor_code')}</label>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-3 form-input form-group with-icon text-left">
                                  <h5 className="settings-radio-button-h5 ml-2">{T.t('gen_default_language')}</h5>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='language_default_opt1'
                                      type="radio"
                                      name="language_default"
                                      value={'tr-TR'}
                                      checked={values.language_default == 'tr-TR'}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label0">{'Türkçe'}</label>
                                  </div>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='language_default_opt2'
                                      type="radio"
                                      name="language_default"
                                      value={'en-US'}
                                      checked={values.language_default == 'en-US'}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label1">{'English'}</label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-6">
                                  <h5 className="settings-radio-button-h5">
                                    <label className="logo-title-center">{T.t('settings_institution_logo')}</label></h5>
                                  <div className="text-gray form-group">
                                    <div className="form-input">
                                      <ImageHolder
                                        src={this.state.institution_logo == null ? '' : this.state.institution_logo}
                                        default={imgLogo}
                                        title="200 X 200 px"
                                        onClick={this.onBrandLogoUploadRequest}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-12">
                                  <h5>{T.t('gen_terms_page_settings')}</h5>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <h6>{T.t('gen_term_exam_types')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="term_exam_type"
                                          name="term_exam_type"
                                          value={values.term_exam_type}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="term_exam_type">{T.t('gen_enter_exam_type')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          termExamTypesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.term_exam_type ? true : false}
                                        onClick={() => this.onAddTermExamTypeRow(values.term_exam_type)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {termExamTypes.filter((item: any) => item.isDeleted == false).map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='term_exam_type'
                                                    name={'term_exam_type-' + _index}
                                                    value={TermExamTypesLabel(item.id, T) === "-" ? item.name : TermExamTypesLabel(item.id, T)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.exam_type = e.currentTarget.value;
                                                      this.state.term_exam_types = JSON.stringify(
                                                        termExamTypes
                                                      );
                                                      values.term_exam_types = JSON.stringify(
                                                        termExamTypes
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.searchTermExamType(_index, item)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-12">
                                  <h5>{T.t('gen_definitions_page_settings')}</h5>
                                  <hr />
                                </div>
                                <div className="col-md-6">
                                  <h6>{T.t('gen_instructor_titles')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="instructor_title"
                                          name="instructor_title"
                                          value={values.instructor_title}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="instructor_title">{T.t('gen_enter_instructor_title')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          instructorTitlesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.instructor_title ? true : false}
                                        onClick={() => this.onAddInstructorTitleRow(values.instructor_title)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {instructorTitles.filter((item: any) => item.isDeleted == false).map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='instructor_title'
                                                    name={'instructor_title-' + _index}
                                                    value={InstructorTitlesLabel(item.id, T) === "-" ? item.name : InstructorTitlesLabel(item.id, T)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.instructor_title = e.currentTarget.value;
                                                      this.state.instructor_titles = JSON.stringify(
                                                        instructorTitles
                                                      );
                                                      values.instructor_titles = JSON.stringify(
                                                        instructorTitles
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.searchInstructorTitle(_index, item)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <h6>{T.t('gen_activity_types')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="activity_type"
                                          name="activity_type"
                                          value={values.activity_type}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="activity_type">{T.t('gen_enter_activity_type')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          activityTypesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.activity_type ? true : false}
                                        onClick={() => this.onAddActivityTypeRow(values.activity_type)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {activityTypes.filter((item: any) => item.isDeleted == false).map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='activity_type'
                                                    name={'activity_type-' + _index}
                                                    value={ActivityTypesLabel(item.id, T) === "-" ? item.name : ActivityTypesLabel(item.id, T)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.activity_type = e.currentTarget.value;
                                                      this.state.activity_types = JSON.stringify(
                                                        activityTypes
                                                      );
                                                      values.activity_types = JSON.stringify(
                                                        activityTypes
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.searchActivityType(_index, item)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-6">

                                  <h6>{T.t('gen_course_languages')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="course_language"
                                          name="course_language"
                                          value={values.course_language}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="course_language">{T.t('gen_enter_course_language')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          courseLanguagesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.course_language ? true : false}
                                        onClick={() => this.onAddCourseLanguageRow(values.course_language)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {courseLanguages.filter((item: any) => item.isDeleted == false).map((item: any, _index: number) => (
                                              <tr key={"course_languages_" + _index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='course_language'
                                                    name={'course_language-' + _index}
                                                    value={item.name}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.course_language = e.currentTarget.value;
                                                      this.state.course_languages = JSON.stringify(
                                                        courseLanguages
                                                      );
                                                      values.course_languages = JSON.stringify(
                                                        courseLanguages
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_course_language'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.searchCourseLanguage(_index, item)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">

                                  <h6>{T.t('gen_classroom_features')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="classroom_feature"
                                          name="classroom_feature"
                                          value={values.classroom_feature}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="classroom_feature">{T.t('gen_enter_classroom_feature')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          classroomFeaturesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.classroom_feature ? true : false}
                                        onClick={() => this.onAddClassroomFeatureRow(values.classroom_feature)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {classroomFeatures.filter((item: any) => item.isDeleted == false).map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='classroom_feature'
                                                    name={'classroom_feature-' + _index}
                                                    value={ClassroomFeaturesLabel(item.id, T) === "-" ? item.name : ClassroomFeaturesLabel(item.id, T)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.classroom_feature = e.currentTarget.value;
                                                      this.state.classroom_features = JSON.stringify(
                                                        classroomFeatures
                                                      );
                                                      values.classroom_features = JSON.stringify(
                                                        classroomFeatures
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.searchClassroomFeature(_index, item)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-12">
                                  <h5>{T.t('gen_planning_page_settings')}</h5>
                                  <hr />
                                </div>
                                <div className="col-md-6">
                                  <div className="row align-items-center">
                                    <div className="col-md-9">
                                      <h6>{T.t('gen_instructors')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_instructors_opt1'
                                          type="radio"
                                          name="restrict_hours_type_instructors"
                                          value={1}
                                          checked={values.restrict_hours_type_instructors == 1}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_only_restrict_instructor_hours')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_instructors_opt2'
                                          type="radio"
                                          name="restrict_hours_type_instructors"
                                          value={2}
                                          checked={values.restrict_hours_type_instructors == 2}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_only_restrict_course_hours')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_instructors_opt3'
                                          type="radio"
                                          name="restrict_hours_type_instructors"
                                          value={0}
                                          checked={values.restrict_hours_type_instructors == 0}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_restrict_all_hours')}
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                                <div className="col-md-6">
                                  <div className="row align-items-center">
                                    <div className="col-md-9">
                                      <h6>{T.t('gen_program_managers')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_program_managers_opt1'
                                          type="radio"
                                          name="restrict_hours_type_program_managers"
                                          value={1}
                                          checked={values.restrict_hours_type_program_managers == 1}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_only_restrict_instructor_hours')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_program_managers_opt2'
                                          type="radio"
                                          name="restrict_hours_type_program_managers"
                                          value={2}
                                          checked={values.restrict_hours_type_program_managers == 2}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_only_restrict_course_hours')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='restrict_hours_type_program_managers_opt3'
                                          type="radio"
                                          name="restrict_hours_type_program_managers"
                                          value={0}
                                          checked={values.restrict_hours_type_program_managers == 0}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_can_restrict_all_hours')}
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                <div className="col-md-12">
                                  <h5>{T.t('gen_solution_page_settings')}</h5>
                                  <hr />
                                </div>
                                <div className="col-md-6">
                                  <div className="mt-3">
                                    <label>{T.t('settings_public_course_solution_page_show')}</label>
                                    <Switch
                                      id="show_public_course_solution"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.show_public_course_solution = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.show_public_course_solution}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <label>{T.t('settings_public_exam_solution_page_show')}</label>
                                    <Switch
                                      id="show_public_exam_solution"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.show_public_exam_solution = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.show_public_exam_solution}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <label>{T.t('settings_restrict_solution_to_instructors')}</label>
                                    <Switch
                                      id="restrict_solution_to_instructors"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.restrict_solution_to_instructors = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.restrict_solution_to_instructors}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <><label id="settings_solution_show_course_opened_sections">{T.t('settings_solution_show_course_opened_sections')} <i className="material-icons mb-1">info_outlined</i></label>
                                      <UncontrolledTooltip placement="right" target="settings_solution_show_course_opened_sections">
                                        {T.t('settings_solution_show_course_opened_sections_info')}
                                      </UncontrolledTooltip></>
                                    <Switch
                                      id="settings_solution_show_course_opened_sections"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.solution_show_course_opened_sections = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.solution_show_course_opened_sections}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <h6>{T.t('gen_classroom_display_type')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='assign_classroom_display_type_opt1'
                                          type="radio"
                                          name="calendar_classroom_display_type"
                                          value={0}
                                          checked={values.calendar_classroom_display_type == 0}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_code_and_name')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='assign_classroom_display_type_opt2'
                                          type="radio"
                                          name="calendar_classroom_display_type"
                                          value={1}
                                          checked={values.calendar_classroom_display_type == 1}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_code2')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='assign_classroom_display_type_opt3'
                                          type="radio"
                                          name="calendar_classroom_display_type"
                                          value={2}
                                          checked={values.calendar_classroom_display_type == 2}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('gen_name2')}
                                      </label>
                                    </div>
                                  </div>
                                </div>


                                <div className="col-md-8">
                                  <br />
                                  <h6>{T.t('gen_instructor_minimum_availability')}</h6>
                                  <div className="mt-3">
                                    <label>{T.t('settings_instructor_minimum_availability_can_be_assigned')}</label>
                                    <Switch
                                      id="show_instructor_minimum_availability"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.show_instructor_minimum_availability = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.show_instructor_minimum_availability}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={`col-md-6 form-input form-group with-icon mt-3 collapse ` + (values.show_instructor_minimum_availability ? `show` : ``)}
                                  id="min_day_restrictions_count">
                                  <h6 >{T.t('settings_instructor_hour_minimum_days_restrictions_count')}</h6>
                                  <input
                                    name="min_day_restrictions_count"
                                    id="min_day_restrictions_count"
                                    type="number"
                                    value={values.min_day_restrictions_count}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {(values.min_day_restrictions_count < 1 || values.min_day_restrictions_count > 7) ? (
                                    isMinDayRestrictionsCountError = true,
                                    <div className="error">{T.t('gen_min_day_restrictions_count_error').replace('{0}', '0').replace('{1}', '8')}</div>
                                  ) : (isMinDayRestrictionsCountError = false)}
                                </div>
                                <div className={`col-md-6 form-input form-group with-icon mt-3 collapse ` + (values.show_instructor_minimum_availability ? `show` : ``)}
                                  id="min_consecutive_slots_count">
                                  <h6 >{T.t('settings_instructor_hour_minimum_consecutive_slots_in_day_count')}</h6>
                                  <input
                                    name="min_consecutive_slots_count"
                                    id="min_consecutive_slots_count"
                                    type="number"
                                    value={values.min_consecutive_slots_count}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {(values.min_consecutive_slots_count < 1 || values.min_consecutive_slots_count > 8) ? (
                                    isMinConsecutiveSlotsCountError = true,
                                    <div className="error">{T.t('gen_min_consecutive_slots_count_error').replace('{0}', '0').replace('{1}', '9')}</div>
                                  ) : (isMinConsecutiveSlotsCountError = false)}
                                </div>

                                <div className="col-md-12 mt-3">
                                  <hr />
                                </div>

                                {this.props.general_settings && this.props.general_settings.event_module_status !== SettingsEventModuleStatus.Blocked ? (
                                  <React.Fragment>
                                    <div className="col-md-12">
                                      <h5>{T.t('gen_event_module_settings')}</h5>
                                      <hr />
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mt-3">
                                        <><label id="settings_event_module_show">{T.t('settings_event_module_show')} <i className="material-icons mb-1">info_outlined</i></label>
                                          <UncontrolledTooltip placement="right" target="settings_event_module_show">
                                            {T.t('settings_event_module_show_info')}
                                          </UncontrolledTooltip></>
                                        <Switch
                                          id="event_module_status_boolean"
                                          className="react-switch"
                                          onChange={(checked: boolean) => {
                                            values.event_module_status_boolean = checked;
                                            values.event_module_status = checked ? 1 : 0;
                                            this.setState(this.state);
                                          }}
                                          checked={values.event_module_status_boolean}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                      <hr />
                                    </div>
                                  </React.Fragment>
                                ) : null}

                                <div className="col-md-6">
                                  <h6>{T.t('gen_restrict_login_roles')}</h6>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_instructor'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.unauthoritative_roles && this.state.unauthoritative_roles.find((item: number) => item == 0) == 0 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.unauthoritative_roles;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(0);
                                            } else {
                                              let index = selectedList.indexOf(0);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.unauthoritative_roles = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('gen_instructor')}
                                    </label>
                                  </div>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_head_of_program'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.unauthoritative_roles && this.state.unauthoritative_roles.find((item: number) => item == 1) == 1 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.unauthoritative_roles;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(1);
                                            } else {
                                              let index = selectedList.indexOf(1);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.unauthoritative_roles = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('gen_head_of_program')}
                                    </label>
                                  </div>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        id="settings_system_admin"
                                        className="form-radio"
                                        checked={this.state.unauthoritative_roles && this.state.unauthoritative_roles.find((item: number) => item == 2) == 2 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.unauthoritative_roles;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(2);
                                            } else {
                                              let index = selectedList.indexOf(2);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.unauthoritative_roles = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('gen_system_admin')}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <button id='button_save' type="button" className="btn btn-green" onClick={() => props.handleSubmit()}>
                                    <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="term" role="tabpanel" aria-labelledby="term">
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <h6>{T.t('settings_term_department_heads_date_range')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='select_dates'
                                      placeholder={T.t('gen_select_dates')}
                                      value={this.state.department_heads_date_range}
                                      options={{
                                        dateFormat: 'Y-m-d',
                                        mode: 'range',
                                        locale: this.state.locale,
                                        defaultDate: this.state.department_heads_date_range
                                      }}
                                      onClose={(value) => {
                                        values.department_heads_date_range = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <h6>{T.t('settings_term_instructors_date_range')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='select_date'
                                      placeholder={T.t('gen_select_dates')}
                                      value={this.state.instructors_date_range}
                                      options={{
                                        dateFormat: 'Y-m-d',
                                        mode: 'range',
                                        locale: this.state.locale,
                                        defaultDate: this.state.instructors_date_range
                                      }}
                                      onClose={(value) => {
                                        values.instructors_date_range = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <hr />
                                  <h6>{T.t('settings_system_will_be_opened_for')}</h6>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_term_full_time_instructors'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.allowed_staff && this.state.allowed_staff.find((item: number) => item == 2) == 2 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.allowed_staff;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(2);
                                            } else {
                                              let index = selectedList.indexOf(2);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.allowed_staff = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('settings_term_full_time_instructors')}
                                    </label>
                                  </div>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_term_part_time_instructors'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.allowed_staff && this.state.allowed_staff.find((item: number) => item == 1) == 1 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.allowed_staff;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(1);
                                            } else {
                                              let index = selectedList.indexOf(1);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.allowed_staff = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('settings_term_part_time_instructors')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <hr />
                                  <h6>{T.t('settings_secondary_education_start_hour')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='secondary_education_start_hour'
                                      value={this.state.secondary_education_start_hour}
                                      placeholder={T.t('gen_select_time')}
                                      options={{
                                        enableTime: true,
                                        dateFormat: 'H:i',
                                        noCalendar: true,
                                        time_24hr: true,
                                        defaultHour: 17
                                      }}
                                      onChange={(value) => {
                                        let hour = moment(value[0]).format('HH:mm');
                                        values.secondary_education_start_hour = hour;
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                {this.props.term_type === 0 ? (
                                  <React.Fragment>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_course_duration_without_break')}</h6>
                                      <div className="form-input form-group with-icon">
                                        <Flatpickr
                                          id='course_duration'
                                          value={this.state.course_duration}
                                          placeholder={T.t('gen_select_time')}
                                          options={{
                                            enableTime: true,
                                            dateFormat: 'H:i',
                                            noCalendar: true,
                                            time_24hr: true,
                                            minTime: '00:05',
                                            maxTime: '03:00',
                                            defaultHour: 1
                                          }}
                                          onChange={(value) => {
                                            let hour = moment(value[0]).format('HH:mm');
                                            values.course_duration = hour;
                                            this.setState(this.state);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_proximity_courses')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_proximity_courses_opt1'
                                          type="radio"
                                          name="proximity_courses"
                                          value={0}
                                          checked={values.proximity_courses == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_assign_courses_close_each_other')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_proximity_courses_opt2'
                                          type="radio"
                                          name="proximity_courses"
                                          value={1}
                                          checked={values.proximity_courses == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_assign_courses_away_each_other')}
                                      </label>
                                    </div>
                                    <div className="col-md-6 form-input form-group with-icon">
                                      <hr />
                                      <h6 >{T.t('settings_course_gap_duration')}</h6>
                                      <input
                                        name="course_gap_duration"
                                        id="course_gap_duration"
                                        type="number"
                                        value={values.course_gap_duration}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="col-md-6 form-input form-group with-icon">
                                      <hr />
                                      <h6 >{T.t('settings_max_solution_count')}</h6>
                                      <input
                                        name="max_solution_count"
                                        id="max_solution_count"
                                        type="number"
                                        value={values.max_solution_count}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('gen_solution_page_settings')}</h6>
                                      <hr />
                                      <div className="mt-3">
                                        <label>{T.t('settings_filter_classrooms_at_solution_update')}</label>
                                        <Switch
                                          id="filter_classrooms_at_solution_update_change"
                                          className="react-switch"
                                          onChange={(checked: boolean) => {
                                            values.filter_classrooms_at_solution_update = checked;
                                            this.setState(this.state);
                                          }}
                                          checked={values.filter_classrooms_at_solution_update}
                                        />
                                      </div>
                                      <div className="mt-3">
                                        <><label id="settings_elective_courses_may_overlap">{T.t('settings_elective_courses_may_overlap')} <i className="material-icons mb-1">info_outlined</i></label>
                                          <UncontrolledTooltip placement="right" target="settings_elective_courses_may_overlap">
                                            {T.t('settings_elective_courses_may_overlap_info')}
                                          </UncontrolledTooltip></>
                                        <Switch
                                          id="elective_courses_may_overlap"
                                          className="react-switch"
                                          onChange={(checked: boolean) => {
                                            values.elective_courses_may_overlap = checked;
                                            this.setState(this.state);
                                          }}
                                          checked={values.elective_courses_may_overlap}
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_gap_between_campuses')}</h6>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_check_program_campus_gap'
                                            type="checkbox"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 0) == 0 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(0);
                                                } else {
                                                  let index = selectedList.indexOf(0);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_program_campus_gap')}
                                        </label>
                                      </div>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_check_instructor_campus_gap'
                                            type="checkbox"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 1) == 1 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(1);
                                                } else {
                                                  let index = selectedList.indexOf(1);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_instructor_campus_gap')}
                                        </label>
                                      </div>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            type="checkbox"
                                            id="radio-active"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 2) == 2 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(2);
                                                } else {
                                                  let index = selectedList.indexOf(2);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_student_campus_gap')}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_place_duty_invigilator_assignments')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_place_duty_invigilator_assignments_opt1'
                                          type="radio"
                                          name="place_duty_invigilator_assignments"
                                          value={1}
                                          checked={values.place_duty_invigilator_assignments == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_place_duty_invigilator_assignments_opt1')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_place_duty_invigilator_assignments_opt2'
                                          type="radio"
                                          name="place_duty_invigilator_assignments"
                                          value={0}
                                          checked={values.place_duty_invigilator_assignments == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_place_duty_invigilator_assignments_opt2')}
                                      </label>
                                    </div>

                                    <div className="col-md-12">
                                      <hr />
                                      <h5>{T.t('settings_invigilator_settings')}</h5>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_settings_opt1'
                                          type="radio"
                                          name="invigilator_settings_collapse"
                                          value={0}
                                          checked={values.invigilator_settings_collapse == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_assignment_to_all_faculties')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_settings_opt2'
                                          type="radio"
                                          name="invigilator_settings_collapse"
                                          value={1}
                                          checked={values.invigilator_settings_collapse == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_appointment_according_to_certain_faculties')}
                                      </label>
                                    </div>
                                    <div
                                      className={`col-md-6 collapse ` + (values.invigilator_settings_collapse == "0" ? `show` : ``)}
                                      id="settings_classroom_invigilator_count_settings"
                                    >
                                      <div className="col-md-12">
                                        <hr />
                                        <h6>{T.t('settings_classroom_invigilator_count_settings')}</h6>
                                        <div className="tick-radio position-relative settings-radio-button">
                                          <label className="classroom_invigilation_count_0" style={{ cursor: 'pointer' }}>
                                            <input
                                              id='settings_classroom_invigilator_count_settings_opt1'
                                              type="radio"
                                              name="classroom_invigilation_count"
                                              value={0}
                                              checked={values.classroom_invigilation_count == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt1')}
                                          </label>
                                        </div>
                                        <label className="classroom_invigilation_count_1" style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_classroom_invigilator_count_settings_opt2'
                                            type="radio"
                                            name="classroom_invigilation_count"
                                            value={1}
                                            checked={values.classroom_invigilation_count == "1"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt2')}
                                        </label>
                                        <label className="classroom_invigilation_count_2" style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_classroom_invigilator_count_settings_opt3'
                                            type="radio"
                                            name="classroom_invigilation_count"
                                            value={2}
                                            checked={values.classroom_invigilation_count == "2"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt3')}
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-md-12 collapse ` + (values.invigilator_settings_collapse == "1" ? `show` : ``)}
                                      id="settings_number_invigilator_assignment_invigilator_faculties"
                                    >
                                      <hr />
                                      <h6>{T.t('settings_number_invigilator_assignment_invigilator_faculties')}</h6>
                                      <div className="col-md-12 list-error">
                                        {
                                          InvigilatorSettingsByFacultyAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : InvigilatorSettingsByFacultyFielsIsEmpty ?
                                              <span>{T.t('gen_info_cannot_leave_empty')}</span>
                                              : null
                                        }
                                      </div>
                                      <table className="aplan-table table">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_faculty')}</label>
                                                  <Select
                                                    id='select_faculty'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={
                                                      this.props.selectOptions && this.props.selectOptions.faculties_code
                                                        ? this.props.selectOptions.faculties_code
                                                        : []
                                                    }
                                                    placeholder={T.t('gen_select_faculty')}
                                                    value={values.faculty}
                                                    onChange={(option: any) => {
                                                      props.setFieldValue('faculty', option);
                                                      props.setFieldValue('faculty_code', option && option.value);
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                                  />
                                                </div>
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('settings_classroom_invigilator_count_settings')}</label>
                                                  <Select
                                                    id='select_classroom_invigilator_count_settings'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={ClassroomInvigilatorCountSettings(T)}
                                                    placeholder={T.t('settings_classroom_invigilator_count_setting_select')}
                                                    value={
                                                      values.classroom_invigilator_count != null
                                                        ? ClassroomInvigilatorCountSettings(T).find((item: Types.ISelectOption) => item.value === values.classroom_invigilator_count)
                                                        : null
                                                    }
                                                    onChange={(option: any) => props.setFieldValue('classroom_invigilator_count', option.value)}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_classroom_invigilator_count')}
                                                  />
                                                </div>
                                                {values.classroom_invigilator_count === undefined && values.faculty && (
                                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                                )}
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('settings_invigilator_assign_type')}</label>
                                                  <Select
                                                    id='select_settings_invigilator_assign_type'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={InvigilatorAssignTypes(T)}
                                                    placeholder={T.t('settings_invigilator_assign_type_select')}
                                                    value={
                                                      values.invigilator_assign_type != null
                                                        ? InvigilatorAssignTypes(T).find((item: Types.ISelectOption) => item.value === values.invigilator_assign_type)
                                                        : null
                                                    }
                                                    onChange={(option: any) => props.setFieldValue('invigilator_assign_type', option.value)}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_invigilator_assign_type')}
                                                  />
                                                </div>
                                                {values.invigilator_assign_type === undefined && values.faculty && (
                                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                                )}
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '10%' }}>
                                              <div className="pb-3">
                                                <button
                                                  id='button_add_invigilator_settings_by_faculty'
                                                  className="mss-table-add-button"
                                                  disabled={(values.classroom_invigilator_count === undefined || values.invigilator_assign_type === undefined) && values.faculty ? true : false}
                                                  onClick={() => this.onAddInvigilatorSettingsByFacultyRow(values.faculty, values.classroom_invigilator_count, values.invigilator_assign_type)}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                                </button>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {InvigilatorSettingsByFaculty.map((item: any, _index: number) => {
                                            let ClassroomInvigilatorCountObject = ClassroomInvigilatorCountSettings(T).find(
                                              (object) => object.value == item.classroom_invigilator_count
                                            );

                                            let InvigilatorAssignTypeObject = InvigilatorAssignTypes(T).find(
                                              (object) => object.value == item.invigilator_assign_type
                                            );
                                            return (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='faculty'
                                                    name={'faculty-' + _index}
                                                    value={item.faculty_name}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='classroom_invigilator_count'
                                                    name={'classroom_invigilator_count-' + _index}
                                                    value={ClassroomInvigilatorCountObject && ClassroomInvigilatorCountObject.label ? ClassroomInvigilatorCountObject && ClassroomInvigilatorCountObject.label : '-'}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='invigilator_assign_type'
                                                    name={'invigilator_assign_type-' + _index}
                                                    value={InvigilatorAssignTypeObject && InvigilatorAssignTypeObject.label ? InvigilatorAssignTypeObject && InvigilatorAssignTypeObject.label : '-'}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_invigilator_settings_by_faculty'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.onDeleteInvigilatorSettingsByFacultyRow(_index)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="col-md-12">
                                      <hr />
                                      <h5>{T.t('gen_acc_exam_type')}</h5>
                                      <div className="row align-items-center">
                                        <div className="col-md-6">
                                          <h6>{T.t('gen_assigment_project_report_submission')}</h6>
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='assign_invigilator_opt1'
                                              type="radio"
                                              name="assigment_project_report_submission"
                                              value={1}
                                              checked={values.assigment_project_report_submission == "1"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_assign_invigilator')}
                                          </label>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='do_not_assign_invigilator_opt1'
                                              type="radio"
                                              name="assigment_project_report_submission"
                                              value={0}
                                              checked={values.assigment_project_report_submission == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_do_not_assign_invigilator')}
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <h6>{T.t('gen_jury_defense')}</h6>
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='assign_invigilator_opt2'
                                              type="radio"
                                              name="jury_defense"
                                              value={1}
                                              checked={values.jury_defense == "1"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_assign_invigilator')}
                                          </label>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='do_not_assign_invigilator_opt2'
                                              type="radio"
                                              name="jury_defense"
                                              value={0}
                                              checked={values.jury_defense == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_do_not_assign_invigilator')}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_invigilator_assign_type')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_assign_type_opt1'
                                          type="radio"
                                          name="invigilator_assignment_type"
                                          value={0}
                                          checked={values.invigilator_assignment_type == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_invigilator_assign_type_opt1')}
                                      </label>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_assign_type_opt2'
                                          type="radio"
                                          name="invigilator_assignment_type"
                                          value={1}
                                          checked={values.invigilator_assignment_type == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_invigilator_assign_type_opt2')}
                                      </label>
                                    </div>
                                    <div
                                      className={`col-md-12 collapse ` + (values.invigilator_assignment_type == '1' ? `show` : ``)}
                                      id="advance-search"
                                    >
                                      <hr />
                                      <h6>{T.t('settings_student_invigilator_count')}</h6>
                                      <div style={{ color: 'red' }}>
                                        {validationInvigilatorByStudent ? T.t('validation_invigilator_count') : null}
                                      </div>
                                      <table className="aplan-table table">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_minimum')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_maximum')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_invigilator_count')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>
                                              <button
                                                id='button_add_circle_outline'
                                                className="mss-table-add-button"
                                                onClick={() => this.onAddRow(invigilatorCountsDueToStudentCount)}
                                              >
                                                <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                              </button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {invigilatorCountsDueToStudentCount.map((item: any, _index: number) => (
                                            <tr key={_index}>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='student_invigilator_number_min'
                                                  name={'student-invigilator-number-min-' + _index}
                                                  disabled={true}
                                                  min={_index > 0 ? Number(invigilatorCountsDueToStudentCount[_index - 1].max) + 1 : 1}
                                                  max={Number(item.max - 1)}
                                                  value={Number(item.min)}
                                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    item.min = e.currentTarget.value;
                                                    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    this.setState(this.state);
                                                  }}
                                                  type="number"
                                                  required
                                                />
                                              </td>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='student_invigilator_number_max'
                                                  name={'student-invigilator-number-max-' + _index}
                                                  max={_index < invigilatorCountsDueToStudentCount.length - 1 ? Number(invigilatorCountsDueToStudentCount[_index + 1].min) - 1 : undefined}
                                                  disabled={_index !== invigilatorCountsDueToStudentCount.length - 1}
                                                  min={Number(item.min) + 1}
                                                  value={Number(item.max)}
                                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    let parsedValue = parseInt(e.currentTarget.value, 10);
                                                    if (isNaN(parsedValue)) {
                                                      item.max = Number(item.min) + 1
                                                    } else {
                                                      item.max = parsedValue
                                                    }
                                                    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    this.setState(this.state);
                                                  }}
                                                  type="number"
                                                  required
                                                />
                                              </td>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='student_invigilator_number'
                                                  name={'student-invigilator-number-num_of_invigilators-' + _index}
                                                  min={1}
                                                  value={Number(item.num_of_invigilators)}
                                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    let parsedValue = parseInt(e.currentTarget.value, 10);
                                                    if (isNaN(parsedValue)) {
                                                      item.num_of_invigilators = 1
                                                    } else {
                                                      item.num_of_invigilators = parsedValue
                                                    }

                                                    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                      invigilatorCountsDueToStudentCount
                                                    );
                                                    this.setState(this.state);
                                                  }}
                                                  type="number"
                                                  required
                                                />
                                              </td>{
                                                (_index === invigilatorCountsDueToStudentCount.length - 1) &&
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.onDeleteRow(_index)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className={`col-md-12 collapse ${values.invigilator_assignment_type === '1' ? 'show' : ''}`}>
                                      <hr />
                                      <h6>
                                        {T.t('gen_settings_online_exam_on_classroom')}
                                      </h6>
                                      <div style={{ color: 'red' }}>
                                        {validationOnlineInvigilatorByStudent ? T.t('validation_invigilator_count') : null}
                                      </div>
                                      <table className='aplan-table table'>
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_minimum')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_maximum')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_invigilator_count')}</th>
                                            <th scope="col" style={{ 'width': '10%' }}>
                                              <button
                                                id='button_add_circle_outline'
                                                className="mss-table-add-button"
                                                onClick={() => {
                                                  if (this.validateMinMax(this.state).length > 0) {
                                                    return;
                                                  }
                                                  let newID = 0;
                                                  const popItem = [...this.state.invigilator_counts_in_online_exams].pop();
                                                  if (popItem) {
                                                    newID = popItem.id + 1;
                                                  }
                                                  const newMin = (popItem && popItem.max) || 0;
                                                  const newEntry = {
                                                    id: newID,
                                                    min: newMin + 1,
                                                    max: newMin + 40,
                                                    invigilatorCount: (popItem && popItem.invigilatorCount + 1) || 1
                                                  };
                                                  this.state.invigilator_counts_in_online_exams = [
                                                    ...this.state.invigilator_counts_in_online_exams,
                                                    newEntry
                                                  ]
                                                  this.setState(this.state);
                                                }}
                                              >
                                                <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                              </button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.invigilator_counts_in_online_exams && this.state.invigilator_counts_in_online_exams.map((item, _index) => (
                                            <tr key={item.id}>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='online_student_inviligator'
                                                  name={'online_student_inviligator-' + _index}
                                                  value={item.min}
                                                  disabled={true}
                                                  onChange={(e) => {
                                                    const parsedValue = parseInt(e.currentTarget.value, 10);
                                                    this.state.invigilator_counts_in_online_exams = this.state.invigilator_counts_in_online_exams.map(invigilator => {
                                                      if (invigilator.id === item.id) {
                                                        return { ...invigilator, min: parsedValue };
                                                      }
                                                      return invigilator;
                                                    })

                                                    this.setState(this.state);
                                                  }}
                                                  type="number"
                                                  min={_index > 0 ? this.state.invigilator_counts_in_online_exams[_index - 1].max + 1 : 1}
                                                  max={item.max - 1}
                                                  required
                                                />
                                              </td>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='online_student_inviligator'
                                                  name={'online_student_inviligator-' + _index}
                                                  disabled={_index !== this.state.invigilator_counts_in_online_exams.length - 1}
                                                  value={item.max}
                                                  onChange={(e) => {
                                                    let parsedValue = parseInt(e.currentTarget.value, 10);
                                                    if (isNaN(parsedValue)) {
                                                      parsedValue = item.min + 1
                                                    }
                                                    this.state.invigilator_counts_in_online_exams = this.state.invigilator_counts_in_online_exams.map(invigilator => {
                                                      if (invigilator.id === item.id) {
                                                        return { ...invigilator, max: parsedValue };
                                                      }
                                                      return invigilator;
                                                    })
                                                    this.setState(this.state);
                                                  }}
                                                  max={_index < this.state.invigilator_counts_in_online_exams.length - 1 ? this.state.invigilator_counts_in_online_exams[_index + 1].min - 1 : undefined}
                                                  min={Number(item.min) + 1}
                                                  type="number"
                                                  required
                                                />
                                              </td>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                <input
                                                  id='online_student_inviligator'
                                                  name={'online_student_inviligator-' + _index}
                                                  value={Number(item.invigilatorCount)}
                                                  onChange={(e) => {
                                                    let parsedValue = parseInt(e.currentTarget.value, 10);
                                                    if (isNaN(parsedValue)) {
                                                      parsedValue = 1
                                                    }
                                                    this.state.invigilator_counts_in_online_exams = this.state.invigilator_counts_in_online_exams.map(invigilator => {
                                                      if (invigilator.id === item.id) {
                                                        return { ...invigilator, invigilatorCount: parsedValue };
                                                      }
                                                      return invigilator;
                                                    })

                                                    this.setState(this.state);
                                                  }}
                                                  type="number"
                                                  min={1}
                                                  required
                                                />
                                              </td>
                                              <td className="form-input" style={{ 'width': '10%' }}>
                                                {(_index === this.state.invigilator_counts_in_online_exams.length - 1) && (
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => {

                                                      const newArray = [...this.state.invigilator_counts_in_online_exams];
                                                      newArray.splice(_index, 1);
                                                      this.state.invigilator_counts_in_online_exams = newArray
                                                      this.setState(this.state);
                                                    }}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  {this.props.term_type != -1 && (
                                    <button
                                      id='save'
                                      type="submit"
                                      className="btn btn-green float-right"
                                      onClick={(e) => {

                                        if (this.validateMinMax(this.state).length < 1) {
                                          props.handleSubmit();
                                        }
                                      }}
                                    >
                                      <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="definitions" role="tabpanel" aria-labelledby="definitions">
                              <DefinitionsSettings
                                setModelSettings={this.setModelSettings}
                                handleChange={handleChange}
                                values={values}
                                setFieldValue={setFieldValue}
                                onSubmit={props.handleSubmit}
                              />
                            </div>
                            <div className="tab-pane fade" id="calendar" role="tabpanel" aria-labelledby="calendar">
                              <CalendarSettings otherSettings={this.state} />
                            </div>
                            <div className="tab-pane fade" id="emailservice" role="tabpanel" aria-labelledby="emailservice">
                              <div className="row mb-4">
                                <div className="col-6 col-md-6 col-sm-6">
                                  <div className="react-select-container">
                                    <label>{T.t('settings_mail_service')}</label>
                                    <Select
                                      id='select_service'
                                      className="react-select"
                                      isSearchable={false}
                                      placeholder={T.t('gen_select_service')}
                                      options={EmailServiceSelectOptions(T)}
                                      value={EmailServiceSelectOptions(T).find((item) => item.value == this.state.service)}
                                      onChange={(item: any) => {
                                        values.service = item.value;
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 form-input form-group with-icon mt-3">
                                  <input
                                    name="email-sender"
                                    id="email-sender"
                                    type="mail"
                                    required
                                    value={values.sending_adress}
                                    onChange={(e: any) => {
                                      values.sending_adress = e.currentTarget.value;
                                      this.setState(this.state);
                                    }}
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="email-sender">{T.t('settings_mail_sending_mail_address')}</label>
                                </div>
                                <div className="col-6 col-md-6 col-sm-6">
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_validation')}</label>
                                    <Switch
                                      id="send_email"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.allow_validation = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.allow_validation}
                                    />
                                  </div>
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_send_mail_on_user_created')}</label>
                                    <Switch
                                      id="send_email_on_user_created"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.send_email_on_user_created = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.send_email_on_user_created}
                                    />
                                  </div>
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_send_solution_is_changed')}</label>
                                    <Switch
                                      id="send_email_solution_is_changed"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.send_email_solution_is_changed = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.send_email_solution_is_changed}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  {this.props.term_type != -1 && (
                                    <button id='save_button' type="button" className="btn btn-green" onClick={() => props.handleSubmit()}>
                                      <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
      </MainLayout >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.building_page && store.state.building_page.results,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    settings: store.state.settings,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(APlanSettings);

export default container;
