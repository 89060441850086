import React, { useCallback, useEffect, useReducer } from 'react';
import { Calendar, EventProps, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import './rbc.css'
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import Translator from '../../services/translate-factory';
import EventCalendarFilters from './event-calendar-filters';
import * as Types from '../../store/types';
import { calendarMessages, RBCEventStatus, RBCEventType } from './constants';
import { events } from './mock';
import * as Constants from '../../store/constants/all';
import cn, { flexIC, flexRow, gap1 } from '../../components/ui/Tailwind';
import { truncateString } from '../../util/truncate';
import { debounce } from 'lodash';

const T = Translator.create();
interface EventCalendarProps {
    event_page?: Types.IEventPageResults;
}
const EventCalendarIn: React.FC<EventCalendarProps> = ({ event_page }) => {

    const [, forceRender] = useReducer(x => x + 1, 0);
    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);


    const localizer = momentLocalizer(moment);
    return (
        <div className="main editor-screen-main" style={{ display: 'block', marginTop: '8px' }}>
            <div
                className="white-container mt-4 editor-screen collapse editor-screen"
                style={{ display: 'block', minHeight: '100vh' }}
            >
                <div>
                    <h5>{T.t("gen_event_calendar")}</h5>
                    <EventCalendarFilters />
                    <div className='white-container tw-w-full tw-p-4' style={{ height: '142vh', borderRadius: '8px' }}>
                        <Calendar
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            messages={calendarMessages(T)}
                            showMultiDayTimes
                            // remove mock events
                            events={events || (event_page && event_page.results)}
                            views={['month', 'week', 'day', 'agenda',]}
                            defaultView="month"
                            tooltipAccessor={(event) => {
                                return ""
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                /**
                                 * Represents the style object for the event calendar.
                                 */
                                let newStyle: React.CSSProperties = {
                                    borderRadius: '0px',
                                };
                                let className
                                switch (event.type) {
                                    case RBCEventType.EXAM:
                                        className = "tw-text-red-500 tw-bg-red-100 tw-border-solid tw-border tw-border-red-500 tw-rounded-lg hover:tw-z-50"
                                        break;
                                    case RBCEventType.COURSE:
                                        className = "tw-text-blue-500 tw-bg-blue-100 tw-border-solid tw-border tw-border-blue-500 tw-rounded-lg hover:tw-z-50"
                                        break;
                                    case RBCEventType.OTHER:
                                        className = "tw-text-gray-500 tw-bg-gray-100 tw-border-solid tw-border tw-border-gray-500 tw-rounded-lg hover:tw-z-[1000] tw-z-50"
                                        break;

                                    default:
                                        className = "tw-text-gray-500 tw-bg-gray-100 tw-border-solid tw-border tw-border-gray-500 tw-rounded-lg hover:tw-z-[1000] tw-z-50"
                                        break;
                                }

                                return {
                                    className: className,
                                    style: newStyle,
                                    title: undefined,
                                };
                            }}
                            components={{
                                event: (e) => {
                                    let tooltip = undefined
                                    switch (e.event.status) {
                                        case RBCEventStatus.PENDING:
                                            tooltip = T.t("gen_waiting_action")
                                            break;
                                        case RBCEventStatus.APPROVED:
                                            tooltip = T.t("gen_approved")
                                            break;
                                        case RBCEventStatus.DENIED:
                                            tooltip = T.t("gen_denied")
                                            break;

                                        default:
                                            break;
                                    }
                                    return (
                                        <Tooltip placement='rightTop' title={tooltip}>
                                            <div>
                                                <div className='tw-relative'>
                                                    <span>{getHour(e.event)}</span>
                                                    <h5 className='tw-text-base tw-leading-5'>
                                                        {e.title}
                                                    </h5>
                                                    {getToolTip(e)}
                                                </div>
                                                <div className='tw-text-xs'>{e.event.desc}</div>
                                                {
                                                    e.event.campus &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.campus.label}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.faculity &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.faculity.label}{" "}{e.event.building && `- ${e.event.building.label}`}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.room &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.room.label}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.responsibles &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        {
                                                            e.event.responsibles.map((r, i) => (
                                                                <div key={r.name} className='tw-text-xs'>{r.name}</div>
                                                            ))
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </Tooltip>
                                    );
                                }
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

const getHour = (event: Types.RBCEvents) => {
    return `${event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
}

const getToolTip = (event: React.PropsWithChildren<EventProps<Types.RBCEvents>>) => {
    let className = 'tw-text-xs tw-w-2 tw-h-2 tw-rounded-full '
    switch (event.event.status) {
        case RBCEventStatus.PENDING:
            className += "tw-bg-yellow-500"
            break;
        case RBCEventStatus.APPROVED:
            className += "tw-bg-green-500"
            break;
        case RBCEventStatus.DENIED:
            className += "tw-bg-red-500"
            break;

        default:
            break;
    }

    const type = event.event.otherType && event.event.otherType.label

    return <div className={cn('tw-absolute tw-right-0 tw-top-0', flexRow, flexIC, gap1)}>
        {type && <div className='tw-bg-gray-200 tw-rounded-lg tw-px-2' title={type}>{truncateString(type, 16)}</div>}
        <div className={className} />
    </div>

}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: EventCalendarProps
): EventCalendarProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: EventCalendarProps = {
        event_page: store.state.event_page,
        ...ownProps,
    };
    return newProps;
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendar = connect(mapStateToProps, dispatchProps)(EventCalendarIn);

export default EventCalendar