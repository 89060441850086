import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  Grades,
  CourseTypes,
  EducationTypeSelectOptions
} from '../../store/constants/course-const';
import {
  ActivitySearchInitialValues,
  ActivityHoursSearchInitialValues,
  ActivityTypesLabel,
  ActivityTypes,
  LectureLocationsLabel,
  CourseTypesLabel,
  CourseEnvironments,
  ActivityTermLectureLocations
} from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('CourseAddModal');

function getInitialState(): Types.IActivityPageState {
  const initialValues: Types.IActivityPageState = {
    filters: Object.assign({}, ActivityHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    activityFormIsOpen: false,
    activityNo: undefined
  };
  initialValues.filters.onlySelectWithoutCourseHours = true;
  return Object.assign({}, initialValues);
}

class CourseAddModal extends Component<any, Types.IActivityPageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.searchActivities();
    this.getActivitySelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  searchActivities() {
    this.state.filters.status = [1];
    this.state.filters.onlySelectWithoutCourseHours = true;
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, this.state.filters, 'activity-list-spin')
    );
  }

  getActivitySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
    this.searchActivities();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.state.filters.status = [1];
      this.state.filters.onlySelectWithoutCourseHours = true;
      this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, this.state.filters, 'activity-list-spin'));
      this.props.onUpdateList(refresh);
    }
  };

  onSelectActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchActivities();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addActivities = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_courses_add_planning'),
          body: T.t('gen_add_courses_to_course_schedule_question'),
          name: 'coursePeriod_courses_add',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setClose();
                this.onUpdateListFromModal();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.add_list = this.state.selected_ids;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_ADD_ACTIVITIES_TO_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchActivities();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchActivities();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivitySearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchActivities();
  };

  onFilterActivity(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchActivities();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.ICoursePageProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.modalIsOpen && !state.courseFormIsOpen) {
      hasNewState = true;
      props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, state.filters, 'activity-list-spin'));
      state.courseFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  checkRelationAvaliability = (): boolean => {
    if (this.state.selected_ids && this.state.selected_ids.length > 1) {
      if (
        this.props.activityRelationType == 2 ||
        this.props.activityRelationType == 3 ||
        this.props.activityRelationType == 5 ||
        this.props.activityRelationType == 6
      ) {
        return this.state.selected_ids.length > 2 ? false : true;
      } else return true;
    } else return false;
  };

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes ? this.props.selectOptions.course_codes : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let activityList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_add_courses_to_list')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      id='button_filter'
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cancel'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='add_definitions_button'
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addActivities}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          id='button_selecteds_add'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/* FILTER STARTS HERE   */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ActivitySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterActivity(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterActivity>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_no')}</label>
                                    <Select
                                      id='select_activity'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.activity_noes
                                          ? this.props.selectOptions.activity_noes
                                          : []
                                      }
                                      placeholder={T.t('gen_select_activity_no')}
                                      value={props.values.activity_noes}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('activity_noes', list);
                                        props.setFieldValue(
                                          'activity_noes_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_code')}</label>
                                    <Select
                                      id='select_course_code'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={courseCodeSelectOptions}
                                      placeholder={T.t('gen_select_course_code')}
                                      value={props.values.course_codes}
                                      filterOption={this.filterOption}
                                      onInputChange={this.onInputChange}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_codes', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                        props.setFieldValue(
                                          'course_codes_ids',
                                          list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_name"
                                    name="course_name"
                                    value={props.values.course_name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_location')}</label>
                                    <Select
                                      id='select_location'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_locations', list);
                                        props.setFieldValue(
                                          'lecture_location_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_type')}</label>
                                    <Select
                                      id='select_activity_type'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={activityTypes}
                                      placeholder={T.t('gen_select_activity_type')}
                                      value={
                                        props.values.activity_type != undefined && props.values.activity_type != null
                                          ? activityTypes.find((option) => option.value == props.values.activity_type)
                                          : null
                                      }
                                      onChange={(option: any) => {
                                        props.setFieldValue('activity_type', option && option.value);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_program')}</label>
                                    <Select
                                      id='select_program'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.programs
                                          ? this.props.selectOptions.programs
                                          : []
                                      }
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_type')}</label>
                                    <Select
                                      id='select_course_type'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={CourseTypes(T)}
                                      placeholder={T.t('gen_select_course_type')}
                                      value={props.values.course_types}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_types', list);
                                        props.setFieldValue(
                                          'course_type',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      id='select_instructor'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.instructors
                                          ? this.props.selectOptions.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.lecture_staff}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_staff', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_count')}</label>
                                    <Select
                                      id='select_lesson_count'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.lesson_counts
                                          ? this.props.selectOptions.lesson_counts
                                          : []
                                      }
                                      placeholder={T.t('gen_select_lesson_count')}
                                      value={props.values.lesson_counts}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lesson_counts', list);
                                        props.setFieldValue(
                                          'lesson_counts_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_lesson_count')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_education_type')}</label>
                                    <Select
                                      id='select_education_type'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={EducationTypeSelectOptions(T)}
                                      placeholder={T.t('gen_select_education_type')}
                                      value={props.values.education_types}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('education_types', list);
                                        props.setFieldValue(
                                          'education_type',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_grade')}</label>
                                    <Select
                                      id='select_grade'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={Grades}
                                      placeholder={T.t('gen_select_grade')}
                                      value={props.values.grades}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('grades', list);
                                        props.setFieldValue(
                                          'grades_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_section')}</label>
                                    <Select
                                      id='select_section'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.sections
                                          ? this.props.selectOptions.sections
                                          : []
                                      }
                                      placeholder={T.t('gen_select_section')}
                                      value={props.values.sections_object}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('sections_object', list);
                                        props.setFieldValue(
                                          'sections',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_section')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="week"
                                    name="week"
                                    value={props.values.week}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="week">{T.t('gen_week')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_language')}</label>
                                    <Select
                                      id='select_course_language'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.course_languages_active
                                          ? this.props.selectOptions.course_languages_active
                                          : []
                                      }
                                      placeholder={T.t('gen_select_course_language')}
                                      value={props.values.course_languages ? props.values.course_languages : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_languages', list);
                                        props.setFieldValue(
                                          'course_language_ids',
                                          list.map((item) => item.value.toString())
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_environment')}</label>
                                    <Select
                                      id='select_course_environment'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={CourseEnvironments(T)}
                                      placeholder={T.t('gen_select_course_environment')}
                                      value={props.values.course_environments ? props.values.course_environments : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_environments', list);
                                        props.setFieldValue(
                                          'course_environment_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      id='select_type'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classroom_types
                                          ? this.props.selectOptions.classroom_types
                                          : []
                                      }
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value.toString())
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              id='select_all_definitions'
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.size
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_no"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_activity_no')}
                          sortkey="activity_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_activity_type')}
                          sortkey="activity_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lesson_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_count')}
                          sortkey="lesson_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_location"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_location')}
                          sortkey="lecture_location"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="education_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_education_type')}
                          sortkey="education_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="faculty"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_faculty')}
                          sortkey="faculty"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="program"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_connected_programs')}
                          sortkey="program"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="class"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_grade')}
                          sortkey="class"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="section"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_section')}
                          sortkey="section"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="instructor"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_instructors')}
                          sortkey="instructor"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_count')}
                          sortkey="student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <SortedColumn
                          datacell="course_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_type')}
                          sortkey="course_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityList && activityList.length
                        ? activityList.map((item: any) => {
                          return (
                            <tr key={'activity-' + item.activity_no} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    id='select_activity_no'
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                    }
                                    data-id={item.activity_no}
                                    onChange={this.onSelectActivity}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_activity_no')}>{item.activity_no}</td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{item.course_code ? item.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{item.course_name ? item.course_name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td data-label={T.t('gen_activity_type')} className="text-center">{ActivityTypesLabel(item.activity_type, T)}</td>
                              <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{item.lesson_count}</td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">{LectureLocationsLabel(item.lecture_location.label, T)}</td>
                              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                  EducationTypeSelectOptions(T)[0].label :
                                  item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                    EducationTypeSelectOptions(T)[1].label :
                                    item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                      EducationTypeSelectOptions(T)[2].label : item.education_type
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.faculties && item.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{item.faculties && item.faculties.map((i: any, index: any) => (index == item!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.programs && item.programs.length > 0 ?
                                    <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.classes && item.classes.length > 0 ?
                                    <div className="table-scrollable-td">{item.classes && item.classes.map((i: any, index: any) => (index == item!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.sections && item.sections.length > 0 ?
                                    <div className="table-scrollable-td">{item.sections && item.sections.map((i: any, index: any) => (index == item!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.instructors && item.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{item.instructors && item.instructors.map((i: any, index: any) => (index == item!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {item.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                <div className="table-scrollable-td">{item.week ? item.week.split(',').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                {CourseTypesLabel(item.course_type, T)}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                            </tr>
                          )
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    all_ids: store.state.activity_page && store.state.activity_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.all_ids,
        next.state.activity_page && next.state.activity_page.all_ids
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseAddModal);

export default container;
