import React, { useEffect, useReducer } from 'react';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import * as Actions from '../../../store/actions/general';
import * as GT from '../../../tools/general-tools';
import { routes as Routes } from '../../../store/constants/routes';
import Translator from '../../../services/translate-factory';
import Table from '../../../components/table/Table'
import { FaArrowRight } from 'react-icons/fa';
import { connect } from 'react-redux';
import { BuildingSearchInitialValues } from "../../../store/constants/building-const";
import { TableColumn, TableType } from '../tools/interfaces';
import { get } from 'lodash';
import { getConstFromType } from '../tools/utils';
import { UserRole } from '../../../store/constants/enums';
const T = Translator.create();
interface BuildingSketchListProps {
    dispatch?: any,
    user?: Types.IAuthUser,
    selectOptions?: Types.ISelectOption[]
}
const BuildingSketchList = ({ dispatch, selectOptions, user }: BuildingSketchListProps) => {

    const langChanged = useReducer(() => ({}), {})[1] as () => void;

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
    }, [])

    const onRowSelected = (e: React.MouseEvent<HTMLButtonElement>, floor: number) => {
        if (e && e.currentTarget) {
            const id: string = e.currentTarget.dataset.id || '';
            dispatch(Actions.Navigation(GT.Route(Routes.SKETCHES, `?type=building&id=${id}&floor=${floor}`)));
        }
    };

    const getColumnComponents = (): TableColumn[] => {
        const adminComponents: TableColumn[] = [
            {
                __componentType: 'chexbox',
                widthPercentage: 5,
                __headerType: 'checkAll',
                parseName: 'building_id',
            },
            {
                __componentType: 'activeness',
                widthPercentage: 5,
                __headerType: 'default',
                parseName: 'status_images',
                title: T.t("gen_status"),
            },
        ]

        const mainComponents: TableColumn[] = [
            {
                widthPercentage: 15,
                __componentType: 'image',
                __headerType: 'default',
                customImageSrc: (item: Types.IBuildingItem) => {
                    return item.photos && item.photos[0] && item.photos[0].src || ""
                },
                title: T.t("gen_photo"),
                parseName: "",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                parseName: "building_code",
                title: T.t("gen_code"),
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'sort',
                title: T.t("gen_building"),
                parseName: "name",
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_campus"),
                parseName: "campus_name",
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_address"),
                parseName: "address",
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'default',
                customRender: (item: Types.IBuildingItem) => {
                    return <td>
                        <div className='table-scrollable-td'>
                            <table className="table aplan-table aplan-table-responsive tw-border-gray-200 tw-border-2 table-striped table-hover sortable filter-table">
                                <tbody>
                                    {
                                        item.floors && item.floors.map(floor => (<tr key={floor.id}>
                                            <td scope="row" className="text-center" style={{ width: '50%' }}>
                                                {floor.name || floor.floor}
                                            </td>
                                            <td data-label={T.t('gen_name')} className="text-center">
                                                <button
                                                    style={{ border: 'none', backgroundColor: 'transparent' }}
                                                    id='button_edit'
                                                    data-toggle="tooltip"
                                                    data-id={floor.id}
                                                    onClick={(e) => onRowSelected(e, floor.floor)}
                                                >
                                                    <FaArrowRight style={{ fontSize: '24px' }} />
                                                </button>
                                            </td>
                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </td>
                },
                title: T.t("gen_floor"),
                parseName: "",
            },
        ]

        const adminRoles: string[] = [UserRole.SYSTEM, UserRole.MANAGER, UserRole.PANEL]

        if (user && adminRoles.includes(user.role)) {
            mainComponents.splice(0, 0, ...adminComponents);
        }

        return mainComponents
    }

    return (
        <Table
            type={TableType.SKETCH_BUILDING}
            initialFilter={BuildingSearchInitialValues}
            filterComponent={[
                {
                    fieldName: "building_code",
                    type: "input",
                    title: T.t("gen_code")
                },
                {
                    fieldName: "name",
                    type: "input",
                    title: T.t("gen_building")
                },
                {
                    fieldName: "campus",
                    type: "dropdown-multi",
                    placeholder: T.t("gen_select_campus"),
                    title: T.t("gen_campuses"),
                    options: selectOptions,
                },
            ]}
            actions={[
                {
                    actionType: 'active/passive',
                    showOnSelect: true,
                }
            ]}
            columnComponent={getColumnComponents()}>
        </Table>
    );

}

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const mapStateToProps = (store: Types.IPersistedState, ownProps: BuildingSketchListProps): BuildingSketchListProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const config = getConstFromType.sketch_building
    if (!config) {
        return ownProps;
    }
    const statePaths = config.statePaths;

    const newProps: any = Object.assign({}, ownProps, {
        selectOptions: get(store.state, statePaths.selectOptions),
        user: store.state.user
    });

    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(BuildingSketchList);

export default container;