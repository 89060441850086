import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { CourseSearchInitialValues } from '../../store/constants/course-const';
import { SyncCampusSearchInitialValuesModal } from '../../store/constants/sync-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('CampusIntegrationModal');

function getInitialState(): any {
  const initialValues: any = {
    filters: Object.assign({}, SyncCampusSearchInitialValuesModal),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_objects: []
  };
  return Object.assign({}, initialValues);
}

class SyncBuildingModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getBuildings();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  getBuildings() {
    const model = {
      sync_type: 2,
      filters: this.state.filters
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_LIST_SYNC_JOBS, model, 'synchronization-spinner')
    );
  }

  onBuildingTransfer = () => {
    const model = {
      sync_job_id: this.props.synchronization.sync_job_id
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_BUILDING_LIST_SEARCH, this.state.filters, 'synchronization-spinner')
    );
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_BUILDING)));
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.getBuildings();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getBuildings();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getBuildings();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.getBuildings();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getBuildings();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let buildingList = this.props.synchronization && this.props.synchronization.listSyncJobs;
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_sync_jobs')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">{T.t('gen_actions')}</th>
                        <th scope="col" className="text-center">{T.t('gen_sync_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_job_parameters')}</th>
                        <th scope="col" className="text-center">{T.t('gen_user_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_term_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_year')}</th>
                        <th scope="col" className="text-center">{T.t('gen_term')}</th>
                        <th scope="col" className="text-center">{T.t('gen_status')}</th>
                        <th scope="col" className="text-center">{T.t('gen_attempted_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_completion_date')}</th>
                        <th scope="col" className="text-center">{T.t('gen_queue_date')}</th>
                        <th scope="col" className="text-center">{T.t('gen_sync_job_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_create_date')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {buildingList && buildingList.length ? (
                        buildingList.map((item: any) => {
                          return (
                            <tr key={item.sync_job_id}>
                              <td data-label={T.t('gen_actions')} className="table-buttons-visible text-center">
                                <div className="table-buttons-wrapper">
                                  <button
                                    data-toggle="tooltip"
                                    data-id={item.building_id}
                                    onClick={this.onBuildingTransfer}
                                    title={T.t('gen_edit')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_sync_type')} className="text-center">
                                {item.sync_type}
                              </td>
                              <td data-label={T.t('gen_job_parameters')} className="text-center">
                                {item.job_parameters}
                              </td>
                              <td data-label={T.t('gen_user_id')} className="text-center">
                                {item.user_id}
                              </td>
                              <td data-label={T.t('gen_term_id')} className="text-center">
                                {item.term_id}
                              </td>
                              <td data-label={T.t('gen_year')} className="text-center">
                                {item.year}
                              </td>
                              <td data-label={T.t('gen_term')} className="text-center">
                                {item.term}
                              </td>
                              <td data-label={T.t('gen_status')} className="text-center">
                                {
                                  item.status == '1' ? 'Çekmeye başladı' :
                                    item.status == '2' ? 'Başarılı' :
                                      item.status == '3' ? 'Başarısız' :
                                        item.status == '4' ? 'Senkronize etmeye başladı' :
                                          item.status == '5' ? 'Başarılı' :
                                            item.status == '6' ? 'Başarısız' :
                                              item.status
                                }
                              </td>
                              <td data-label={T.t('gen_attempt_count')} className="text-center">
                                {item.attempt_count}
                              </td>
                              <td data-label={T.t('gen_completion_date')} className="text-center">
                                {item.completion_date}
                              </td>
                              <td data-label={T.t('gen_queue_date')} className="text-center">
                                {item.queue_date}
                              </td>
                              <td data-label={T.t('gen_sync_job_id')} className="text-center">
                                {item.sync_job_id}
                              </td>
                              <td data-label={T.t('gen_create_date')} className="text-center">
                                {item.created_date}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={16}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.integration && store.state.integration.results,
    filters: store.state.integration && store.state.integration.filters,
    all_ids: store.state.integration && store.state.integration.all_ids,
    term_id: store.state.term_id,
    term_list: store.state.term_list,
    synchronization: store.state.synchronization
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.synchronization) {
    return (
      !!equal(
        prev.state.integration && prev.state.integration.results,
        next.state.integration && next.state.integration.results
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.filters,
        next.state.integration && next.state.integration.filters
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.all_ids,
        next.state.integration && next.state.integration.all_ids
      ) &&
      !!equal(prev.state.term_id, next.state.term_id) &&
      !!equal(prev.state.term_list, next.state.term_list) &&
      !!equal(prev.state.synchronization, next.state.synchronization)
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SyncBuildingModal);

export default container;
