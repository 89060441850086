import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { CampusSearchInitialValues } from '../../store/constants/campus-const';
import { BuildingSearchInitialValues } from '../../store/constants/building-const';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import Translator from '../../services/translate-factory';
import SketchesDetail from './sketches-detail';
import { TableProps } from 'antd';
import { Campus } from './tools/interfaces';
import CampusSketchList from './campus'
import BuildingSketchList from './building'
import cn, { flexCenter } from '../../components/ui/Tailwind';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { debounce } from 'lodash';

type OnChange = NonNullable<TableProps<Campus>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const T = Translator.create();

const L = Log.create('SketchesListPage');
const InitialState = {
    campus_filters: CampusSearchInitialValues,
    building_filters: BuildingSearchInitialValues,
    filterIsOpen: false,
    campusFormIsOpen: false,
    campusId: undefined,
    distanceModalIsOpen: false,
    integrationModalIsOpen: false,
    all_ids: [],
    selected_ids: []
};

const Sketches: React.FC<any> = (props) => {
    const [state, setState] = useState(InitialState);
    const [type, setType] = useState<string | null>()
    const [activeTab, setActiveTab] = useState('campus');
    const [page, setPage] = useState<any>()

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        setState(InitialState)
    })

    const [, forceRender] = React.useReducer(x => x + 1, 0);

    const handleLanguageChange = React.useCallback(
        debounce(() => {
            forceRender(1)
        }, 1000),
        []
    );

    useEffect(() => {
        const { location } = props;

        // URL'deki sorgu parametrelerini al
        const searchParams = new URLSearchParams(location.search);

        setType(searchParams.get('type'))
    }, [props.location])

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        window.scrollTo(0, 0);
        searchSketchCampuses();
        searchSketchBuildings();
        getSketchSelectOptions();
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, [])


    function searchSketchCampuses() {
        props.dispatch(
            Actions.ApiRequest(Constants.sketch.SKETCH_CAMPUS_LIST_SEARCH, state.campus_filters, 'sketch-list-spin')
        );
    }

    function searchSketchBuildings() {
        props.dispatch(
            Actions.ApiRequest(Constants.sketch.SKETCH_BUILDING_LIST_SEARCH, state.building_filters, 'sketch-list-spin')
        );
    }

    function getSketchSelectOptions() {
        props.dispatch(Actions.ApiRequest(Constants.sketch.SKETCH_GET_SELECT_OPTIONS, 'sketch-list-spin'));
    }

    useEffect(() => {
        setPage(<div className={cn(flexCenter, 'tw-h-screen')}><AiOutlineLoading3Quarters className='tw-animate-spin tw-text-2xl' /></div>)
        const { location } = props;

        // URL'deki sorgu parametrelerini al
        const searchParams = new URLSearchParams(location.search);

        const id = searchParams.get('id')

        if (id && type) {
            switch (type) {
                case 'campus': {
                    // form: store.state.campus_page && store.state.campus_page.form ? store.state.campus_page.form : getInitialState(),
                    props.dispatch(Actions.ApiRequest(Constants.sketch.SKETCH_CAMPUS_GET_BY_ID, id, 'sketch-campus-form-spin',
                        () => setPage(<SketchesDetail
                            key={id}
                            returnText={(T) => T.t("gen_return_campus_selection")}
                            type={type}
                        />)
                    ))
                    break;
                }

                case 'building': {
                    const floor = searchParams.get('floor');
                    const model = {
                        buildingId: id,
                        floor: floor
                    };
                    props.dispatch(Actions.ApiRequest(Constants.sketch.SKETCH_BUILDING_GET_BY_ID, model, 'sketch-building-form-spin',
                        () => setPage(<SketchesDetail
                            key={id}
                            returnText={(T) => T.t("gen_return_building_selection")}
                            type={type}
                        />)
                    ))
                    break;
                }

                default:
                    setPage(undefined)

            }
        } else {
            setPage(undefined)
        }
    }, [type])

    return (
        <MainLayout header={<APlanHeader />}>
            <SimplePage name="sketch-page">
                <Spinner name='sketch-list-spin' />
                <div className="main list-campus">
                    <div className="container-fluid">
                        <h4 className="mb-4 mb-sm-0 tw-flex tw-items-center tw-gap-2">
                            {T.t("sketches")}
                        </h4>
                        <nav className="inner-page-nav">
                            <div className={cn(
                                "mb-4 nav nav-tabs nav-fill",
                                page ? "tw-pointer-events-none tw-opacity-60" : null
                            )} id="nav-tab" role="tablist">
                                <a
                                    className={cn(
                                        "nav-item nav-link",
                                        activeTab === 'campus' ? 'active' : ''
                                    )}
                                    onClick={() => handleTabChange('campus')}
                                    id="campus-tab"
                                    data-toggle="tab"
                                    href="#campus"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected={activeTab === 'campus'}
                                >
                                    {T.t('campus_settlements')}
                                </a>
                                <a
                                    className={cn(
                                        "nav-item nav-link",
                                        activeTab === 'building' ? 'active' : ''
                                    )}
                                    onClick={() => handleTabChange('building')}
                                    id="building-tab"
                                    data-toggle="tab"
                                    href="#building"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected={activeTab === 'building'}
                                >
                                    {T.t('building_settlements')}
                                </a>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent" role='tablist'>
                            <div className={cn(
                                "tab-pane fade",
                                activeTab === 'campus' ? 'show active' : '',
                            )} id="campus" role="tab" aria-labelledby="campus">
                                {
                                    page || <CampusSketchList />
                                }
                            </div>
                            <div className={cn(
                                "tab-pane fade",
                                activeTab === 'building' ? 'show active' : '',
                            )}
                                id="building" role="tab" aria-labelledby="building">
                                {
                                    page || <BuildingSketchList />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </MainLayout>
    );

}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusPageProps): Types.ICampusPageProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.ICampusPageProps = Object.assign({}, ownProps, {
        user: store.state.user,
        campusResults: store.state.sketch_page && store.state.sketch_page.campus_results,
        campusFilters: store.state.sketch_page && store.state.sketch_page.campus_filters,
        campusSelectOptions: store.state.select_options && store.state.select_options.sketchPage && store.state.select_options.sketchPage.campus_buildings,
        buildingResults: store.state.sketch_page && store.state.sketch_page.building_results,
        buildingFilters: store.state.sketch_page && store.state.sketch_page.building_filters,
        buildingSelectOptions: store.state.select_options && store.state.select_options.sketchPage && store.state.select_options.sketchPage.building_campuses,
    });
    return newProps;
};
const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(Sketches);

export default container;
