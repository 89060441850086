import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import ProgramForm from './program-form';
import ProgramImportForm from './program-import-form';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import DownloadButton from '../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../store/constants/enums';
import SyncProgramModal from './program-sync-modal';
import { EducationTypeSelectOptions, ProgramSearchInitialValues } from '../../store/constants/program-const';
import ChangeAllDataModal from './change-all-data-modal';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('FacultyListPage');

class ProgramTable extends Component<Types.IProgramPageProps, Types.IProgramPageState> {
  state: Types.IProgramPageState = {
    filters: ProgramSearchInitialValues,
    filterIsOpen: false,
    programFormIsOpen: false,
    programImportFormIsOpen: false,
    integrationModalIsOpen: false,
    programId: undefined,
    all_ids: [],
    selected_ids: [],
    changeAllDataModalIsOpen: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
    }
    this.init();
  }

  componentDidUpdate() {
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
      this.props.dispatch(Actions.Notification('gen_selected_term_removed', 'gen_warning', 'warning'))
      this.init();
    }
  }

  init() {
    this.state = {
      filters: Object.assign({}, ProgramSearchInitialValues),
      filterIsOpen: false,
      programFormIsOpen: false,
      programImportFormIsOpen: false,
      integrationModalIsOpen: false,
      programId: undefined,
      all_ids: [],
      selected_ids: [],
      changeAllDataModalIsOpen: false
    };
    this.state.filters.page = 1;
    this.searchPrograms();
    this.getProgramSelectOptions();
  }

  searchPrograms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.program.PROGRAM_LIST_SEARCH, this.state.filters, 'program-list-spin')
    );
  }

  getProgramSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.program.PROGRAM_GET_SELECT_OPTIONS, 'program-list-spin'));
  }

  getProgramsByFaculties = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchPrograms();
  };

  programFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname, '/create')));
  };

  programImportFormIsOpen = () => {
    this.setState({
      ...this.state,
      programImportFormIsOpen: true
    });
  };

  programFormOnClose = (refresh: boolean) => {
    let path = window.location.pathname.startsWith('/static/') ? GT.Route(Routes.STATIC_PROGRAM) : GT.Route(Routes.PROGRAM)
    if (this.state.programFormIsOpen) {
      this.props.dispatch(Actions.Navigation(path));
    }
    if (refresh) {
      this.searchPrograms();
    }
  };

  programImportFormOnClose = (refresh: boolean) => {
    this.setState({
      ...this.state,
      programImportFormIsOpen: false
    });
    if (refresh) {
      this.searchPrograms();
    }
  };

  onProgramImport = () => {
    this.searchPrograms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchPrograms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ProgramSearchInitialValues);
    this.setState(this.state);
    this.searchPrograms();
  };

  onFilterProgram(model: Types.IFilterProgram, FormActions: FormikActions<Types.IFilterProgram>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchPrograms();
    FormActions.setSubmitting(false);
  }

  onProgramEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname + '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.program.PROGRAM_LIST_SEARCH,
            this.state.filters,
            'program-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteProgram = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_program_question'),
          name: 'program_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = ProgramSearchInitialValues;
                this.setState(this.state);
                this.searchPrograms();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(Constants.program.PROGRAM_DELETE, deleteList, 'program-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  onSelectProgram = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  switchChangeAlldataModalStatus = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });

    this.searchPrograms();
  };

  translateEducationTypeCode = (programCode: string) => {
    let educationTypeCodes = ["NO", "IO", "Uzaktan Eğitim"];

    if (educationTypeCodes.some(s => programCode.endsWith(s))) {
      if (programCode.endsWith("Uzaktan Eğitim")) {
        let code = "Uzaktan Eğitim"
        let parts = programCode.split(code);
        return parts.join("") + " " + T.t(code);
      } else {
        let parts = programCode.split(" ");
        let code = parts.pop()
        return parts.join(" ") + " " + T.t(code);
      }
    }
    else {
      return programCode;
    }
  }

  static getDerivedStateFromProps(props: Types.IProgramPageProps, state: Types.IProgramPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.programFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.programId = props.match.params.id;
      } else {
        state.programId = undefined;
      }
    } else {
      hasNewState = true;
      state.programFormIsOpen = false;
      state.programId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let programList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_programs')}</h4>
              <Spinner name="program-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-9 col-sm-12 col-12">
                    {this.props.user && this.props.user.role === 's' ? (
                      this.state.selected_ids && this.state.selected_ids.length ? (
                        <React.Fragment>
                          <button
                            id='button_cancel_selection'
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px' }}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                selected_ids: []
                              });
                            }}
                          >
                            <i className="material-icons mr-2">close</i>
                            <span>
                              {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                            </span>
                          </button>
                          <button
                            id='button_delete_outline'
                            className="category-tag-square float-left pr-2 pl-2"
                            style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                            onClick={this.onDeleteProgram}
                          >
                            <i className="material-icons mr-2">delete_outline</i>
                            <span> {T.t('gen_delete_selected')}</span>
                          </button>
                          <button
                            id='button_sync'
                            className="category-tag-square float-left pr-2 pl-2 tag-green"
                            style={{ margin: '5px', color: '#fff' }}
                            onClick={this.switchChangeAlldataModalStatus}
                          >
                            <i className="material-icons mr-2">sync</i>
                            <span> {T.t('gen_change_all_data')}</span>
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <button
                            id='button_add'
                            className="category-tag-square tag-green"
                            onClick={this.props.term_id === -1 ? this.programFormIsOpen : this.programImportFormIsOpen}
                          >
                            <i className="material-icons mr-2">add</i>
                            {T.t('gen_add_program')}
                          </button>
                          {this.props.term_id === -1 ? (
                            <React.Fragment>
                              <button
                                id='button_playlist_add'
                                className="category-tag-square tag-green ml-1"
                                onClick={this.switchIntegrationModalStatus}
                              >
                                <i className="material-icons mr-2">playlist_add</i>
                                {T.t('gen_list_sync_jobs')}
                              </button>
                              <ImportModal
                                title={T.t('gen_add_with_excel')}
                                componentKey={ExcelImportKeys.Programs}
                                dispatch={this.props.dispatch}
                                onImport={this.onProgramImport}
                              />
                              <DownloadButtonSyncResult title={'SyncProgramsResult'} sectionType={SectionTypes.SyncProgramsResult} />
                            </React.Fragment>
                          ) : null}
                          <DownloadButton title={'Programlar'} sectionType={SectionTypes.Programs} />
                        </React.Fragment>
                      )
                    ) : null}
                  </div>
                  <div className="col-md-3 col-sm-12 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        id='button_filter_list'
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ProgramSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterProgram(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterProgram>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="program_code"
                                    name="program_code"
                                    value={props.values.program_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="program_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_faculty_college')}</label>
                                    <Select
                                      id='select_faculty'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_faculty')}
                                      value={props.values.faculties ? props.values.faculties : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('faculties', list);
                                        props.setFieldValue(
                                          'faculty_ids',
                                          list.map((item) => item.value)
                                        );
                                        this.getProgramsByFaculties(list.map(item => item.value))
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.props.term_type === 0 ? (
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_program')}</label>
                                      <Select
                                        id='select_program'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={this.props.programs_related_faculty && this.props.programs_related_faculty.length > 0 ? this.props.programs_related_faculty :
                                          this.props.selectOptions && this.props.selectOptions.programs
                                            ? this.props.selectOptions.programs
                                            : []}
                                        placeholder={T.t('gen_select_program')}
                                        value={props.values.programs ? props.values.programs : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('programs', list);
                                          props.setFieldValue(
                                            'program_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_education_type')}</label>
                                    <Select
                                      id='select_education_type'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={EducationTypeSelectOptions(T)}
                                      placeholder={T.t('gen_select_education_type')}
                                      value={props.values.education_types_select}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('education_types_select', list);
                                        props.setFieldValue(
                                          'education_types',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.props.term_type !== 1 ? (
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_grade')}</label>
                                      <Select
                                        id='select_grade'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.term_id === -1 ?
                                            (this.props.selectOptions && this.props.selectOptions.all_classes ? this.props.selectOptions.all_classes : []) :
                                            (this.props.selectOptions && this.props.selectOptions.classes ? this.props.selectOptions.classes : [])
                                        }
                                        placeholder={T.t('gen_select_grade')}
                                        value={props.values.class ? props.values.class : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('class', list);
                                          props.setFieldValue(
                                            'class_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th data-cell="select">
                              <div className="tick-radio position-relative">
                                <input
                                  id='select_all'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={this.checkAllIdsSelected()}
                                  onChange={this.onSelectAll}
                                />
                              </div>
                            </th>
                          ) : null}
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="program_code"
                            title={T.t('gen_code')}
                            sortkey="program_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="faculty_name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_faculty_college')}
                            sortkey="faculty_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_education_type')}
                          </th>
                          {this.props.term_type !== 1 ? (
                            <React.Fragment>
                              <th scope="col" className="text-center">
                                {T.t('gen_grade')}
                              </th>
                              <th scope="col" className="text-center">
                                {T.t('gen_max_gap_between_courses')}
                              </th>
                            </React.Fragment>
                          ) : null}
                          <SortedColumn
                            datacell="daily_max_class"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_daily_max_course')}
                            sortkey="daily_max_class"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="min_class_interval_bw_campuses"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_min_gap_between_campuses')}
                            sortkey="min_class_interval_bw_campuses"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th scope="col" className="text-right">
                              <span className="text-right">{T.t('gen_actions')}</span>
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {programList && programList.length
                          ? programList.map((item: any) => (
                            <tr key={'program-' + item.program_id} data-title={item.name}>
                              {this.props.user && this.props.user.role === 's' ? (
                                <td data-cell="select">
                                  <div className="tick-radio position-relative">
                                    <input
                                      id='select_program'
                                      type="checkbox"
                                      className="form-radio"
                                      checked={
                                        this.state.selected_ids &&
                                        this.state.selected_ids.indexOf(item.program_id ? item.program_id : -1) > -1
                                      }
                                      data-id={item.program_id}
                                      onChange={this.onSelectProgram}
                                    />
                                  </div>
                                </td>
                              ) : null}
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='status'
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {this.props.term_type !== 1 ? this.translateEducationTypeCode(item.program_code) : item.program_code}
                              </td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td data-label={T.t('gen_faculty_college')} className="text-center">
                                {item.faculty_name}
                              </td>
                              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                  EducationTypeSelectOptions(T)[0].label :
                                  item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                    EducationTypeSelectOptions(T)[1].label :
                                    item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                      EducationTypeSelectOptions(T)[2].label : item.education_type
                                }
                              </td>
                              {this.props.term_type !== 1 ? (
                                <React.Fragment>
                                  <td data-label={T.t('gen_grade')} className="text-center">
                                    {this.props.term_type === -1 ? item.class : item.grade}
                                  </td>
                                  <td data-label="{T.t('gen_max_gap_between_courses')}" className="text-center">
                                    {item.max_gap_between_courses}
                                  </td>
                                </React.Fragment>
                              ) : null}
                              <td data-label={T.t('gen_daily_max_course')} className="text-center">
                                {item.daily_max_class}
                              </td>
                              <td data-label={T.t('gen_min_gap_between_campuses')} className="text-center">
                                {item.min_class_interval_bw_campuses}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                              {this.props.user && this.props.user.role === 's' ? (
                                <td data-label={T.t('gen_actions')} className="table-buttons">
                                  <div className="table-buttons-wrapper">
                                    <button
                                      id='button_edit'
                                      data-toggle="tooltip"
                                      data-id={item.program_id}
                                      onClick={this.onProgramEdit}
                                      title={T.t('gen_edit')}
                                      className="btn btn-light btn-sm table-button"
                                    >
                                      <span className="d-block" data-toggle="modal" data-target="#addNew">
                                        <i className="material-icons">edit</i>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <ProgramForm
          programId={this.state.programId}
          formIsOpen={this.state.programFormIsOpen}
          onClose={this.programFormOnClose}
          term_type={this.props.term_type}
        />
        <ProgramImportForm
          formIsOpen={this.state.programImportFormIsOpen != null ? this.state.programImportFormIsOpen : false}
          onClose={this.programImportFormOnClose}
        />
        {this.state.integrationModalIsOpen ? (
          <SyncProgramModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchPrograms()}
          />
        ) : null}
        <ChangeAllDataModal
          modalIsOpen={this.state.changeAllDataModalIsOpen}
          onClose={this.switchChangeAlldataModalStatus}
          term_id={this.state.filters.term_id}
          selected_ids={this.state.selected_ids}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramPageProps): Types.IProgramPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IProgramPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    user: store.state.user,
    results: store.state.program_page && store.state.program_page.results,
    filters: store.state.program_page && store.state.program_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.programPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    term_type: store.state.term_type
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.program_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.program_page && prev.state.program_page.results,
        next.state.program_page && next.state.program_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programPage,
        next.state.select_options && next.state.select_options.programPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ProgramTable);

export default container;
