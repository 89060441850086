import * as React from 'react';
import { FormikProps } from "formik"
import { ICreateFilterEvent } from "../../store/types"
import Translator from "../../services/translate-factory"
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';


const Preview: React.FC<{ formikProps: FormikProps<ICreateFilterEvent> }> = ({ formikProps: props }) => {
    const T = new Translator()
    const { values } = props
    if (!values.selectedPlace) return <div></div>

    const getSittingType = () => {
        if (!values.selectedPlace)
            return ""
        const seatingType = values.selectedPlace.sitting_type
        const findLocalized = ClassroomSeatingTypeOptions(T).find(_ => _.value === seatingType)
        return findLocalized && findLocalized.label || seatingType
    }

    const getSeatingArrangement = () => {
        if (!values.selectedPlace)
            return ""
        const seatingArrangement = values.selectedPlace.seating_arrangement
        const findLocalized = ClassroomSeatingArrangementOptions(T).find(_ => _.value === seatingArrangement)
        return findLocalized && findLocalized.label || seatingArrangement
    }

    return <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10 tw-w-full">
        {/* Etkinlik Detayları Kartı */}
        <div className=" tw-bg-gray-200/50 tw-border-solid tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4 tw-shadow-md">
            <h4 className="tw-text-2xl tw-font-bold tw-text-gray-800">{T.t("gen_event_info")}</h4>
            <div className="tw-grid tw-grid-cols-2 tw-text-gray-600">
                <p className="tw-text-lg "><strong>{T.t("gen_event_name")}:</strong> {values.title}</p>
                <p className="tw-text-lg "><strong>{T.t("gen_event_type")}:</strong> {values.event_type && values.event_type.label}</p>
                <p className="tw-text-lg tw-col-span-2"><strong>{T.t('gen_description')}:</strong> {values.description}</p>
                <p className="tw-text-lg tw-col-span-2"><strong>{T.t("gen_date_and_time")}:</strong> {values.start_date_and_time} - {values.end_date_and_time}</p>
            </div>
        </div>

        {/* Mekan Detayları Kartı */}
        <div className=" tw-bg-gray-200/50 tw-border-solid tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4 tw-shadow-md">
            <h2 className="tw-text-2xl tw-font-bold tw-text-gray-800">{T.t("gen_place_info")}</h2>
            <div className="tw-grid tw-grid-cols-2 tw-text-gray-600">
                <p className="tw-text-lg"><strong>{T.t("gen_campus")}:</strong> {values.selectedPlace.campus_name}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_building")}:</strong> {values.selectedPlace.building_name}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_floor")}:</strong> {values.selectedPlace.floor}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_classroom")}:</strong> {values.selectedPlace.name}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_sitting_type")}:</strong> {getSittingType()}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_seating_arrangement")}:</strong> {getSeatingArrangement()}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_lecture_capacity")}:</strong> {values.selectedPlace.lecture_capacity}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_exam_capacity")}:</strong> {values.selectedPlace.exam_capacity}</p>
                <p className="tw-text-lg"><strong>{T.t("gen_feature")}:</strong> {
                    values.selectedPlace.feature.map(i => {
                        const localized = ClassroomFeatureOptions(T).find(_ => _.value === i)
                        return localized && localized.label || i
                    }).join(", ")
                }</p>
            </div>
        </div>
    </div>
}

export default Preview