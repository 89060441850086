import { put, takeEvery, all, fork, take } from 'redux-saga/effects';
import * as coreSagas from './core-saga';
import * as loginSagas from './login-saga';
import * as userSagas from './user-saga';
import * as dashboardSagas from './dashboard-saga';
import * as settingSagas from './setting-saga';
import * as campusSagas from './campus-saga';
import * as buildingSagas from './building-saga';
import * as facultySagas from './faculty-saga';
import * as classroomSagas from './classroom-saga';
import * as programSagas from './program-saga';
import * as courseSagas from './course-saga';
import * as activitySagas from './activity-saga';
import * as instructorSagas from './instructor-saga';
import * as coursePeriodSagas from './course-period-saga';
import * as examPeriodSagas from './exam-period-saga';
import * as solutionSagas from './solution-saga';
import * as integrationSagas from './integration-saga';
import * as notificationSagas from './notification-saga';
import * as backupSagas from './backup-saga';
import * as SyncSagas from './sync-saga';
import * as DistributorSagas from './distributor-saga';
import * as SketchSagas from './sketch-saga';

import { Log } from 'ng2-logger';
import { program } from '@babel/types';
import distributorReducer from '../reducers/distributor-reducer';
const L = Log.create('Sagas');

export default function* rootSaga() {
  yield all([
    fork(coreSagas.getAuthUser),
    fork(coreSagas.getWellKnownUnits),
    fork(loginSagas.getAuthToken),
    fork(userSagas.watchUserSearch),
    fork(userSagas.watchUserSelectOptions),
    fork(userSagas.watchUserCreate),
    fork(userSagas.watchUserBrowse),
    fork(userSagas.watchUserGetById),
    fork(userSagas.watchUserUpdate),
    fork(userSagas.watchDeleteUsers),
    fork(userSagas.watchCreateUserBulk),
    fork(dashboardSagas.watchDashboardGetSessions),
    fork(userSagas.watchUpdateUserRole),
    fork(userSagas.watchUserGetProgramsByFaculties),
    fork(userSagas.watchUserGetFilterProgramsByFaculties),
    fork(userSagas.watchCreateUserExcelBulk),
    fork(loginSagas.getForgotPwResponse),
    fork(loginSagas.getResetPwResponse),
    fork(campusSagas.watchCampusSearch),
    fork(campusSagas.watchCampusGetById),
    fork(campusSagas.watchCampusCreate),
    fork(campusSagas.watchCampusUpdate),
    fork(campusSagas.watchCampusDelete),
    fork(campusSagas.watchCampusGetSelectOptions),
    fork(campusSagas.watchCampusGetDistances),
    fork(campusSagas.watchCampusUpdateDistances),
    fork(campusSagas.watchCreateCampusExcelBulk),
    fork(campusSagas.watchCampusImportSearch),
    fork(campusSagas.watchCampusImportGetSelectOptions),
    fork(campusSagas.watchCampusImportCreate),
    fork(buildingSagas.watchBuildingSearch),
    fork(buildingSagas.watchBuildingGetById),
    fork(buildingSagas.watchBuildingCreate),
    fork(buildingSagas.watchBuildingUpdate),
    fork(buildingSagas.watchBuildingDelete),
    fork(buildingSagas.watchBuildingGetSelectOptions),
    fork(buildingSagas.watchCreateBuildingExcelBulk),
    fork(buildingSagas.watchBuildingGetDistances),
    fork(buildingSagas.watchBuildingUpdateDistances),
    fork(buildingSagas.watchBuildingImportSearch),
    fork(buildingSagas.watchBuildingImportGetSelectOptions),
    fork(buildingSagas.watchBuildingImportCreate),
    fork(buildingSagas.watchDownloadAllData),
    fork(facultySagas.watchFacultySearch),
    fork(facultySagas.watchFacultyGetById),
    fork(facultySagas.watchFacultyCreate),
    fork(facultySagas.watchFacultyUpdate),
    fork(facultySagas.watchFacultyDelete),
    fork(facultySagas.watchFacultyGetSelectOptions),
    fork(facultySagas.watchCreateFacultyExcelBulk),
    fork(facultySagas.watchFacultyImportSearch),
    fork(facultySagas.watchFacultyImportGetSelectOptions),
    fork(facultySagas.watchFacultyImportCreate),
    fork(classroomSagas.watchClassroomSearch),
    fork(classroomSagas.watchClassroomGetById),
    fork(classroomSagas.watchClassroomCreate),
    fork(classroomSagas.watchClassroomUpdate),
    fork(classroomSagas.watchClassroomDelete),
    fork(classroomSagas.watchClassroomChangeAllData),
    fork(classroomSagas.watchClassroomGetSelectOptions),
    fork(classroomSagas.watchClassroomGetBuildingsByCampuses),
    fork(classroomSagas.watchCreateClassroomExcelBulk),
    fork(classroomSagas.watchClassroomImportSearch),
    fork(classroomSagas.watchClassroomImportGetSelectOptions),
    fork(classroomSagas.watchClassroomImportGetBuildingsByCampuses),
    fork(classroomSagas.watchClassroomImportCreate),
    fork(programSagas.watchProgramSearch),
    fork(programSagas.watchProgramGetById),
    fork(programSagas.watchProgramCreate),
    fork(programSagas.watchProgramMultiCreate),
    fork(programSagas.watchProgramUpdate),
    fork(programSagas.watchProgramDelete),
    fork(programSagas.watchProgramGetSelectOptions),
    fork(programSagas.watchCreateBuildingExcelBulk),
    fork(programSagas.watchProgramImportSearch),
    fork(programSagas.watchProgramImportGetSelectOptions),
    fork(programSagas.watchProgramImportCreate),
    fork(programSagas.watchChangeProgramAllData),
    fork(courseSagas.watchCourseSearch),
    fork(courseSagas.watchCourseGetById),
    fork(courseSagas.watchCourseCreate),
    fork(courseSagas.watchCourseUpdate),
    fork(courseSagas.watchCourseDelete),
    fork(courseSagas.watchCourseGetProgramsByFaculties),
    fork(courseSagas.watchCourseGetSelectOptions),
    fork(courseSagas.watchCreateCourseExcelBulk),
    fork(activitySagas.watchActivitySearch),
    fork(activitySagas.watchActivityChangeAllData),
    fork(activitySagas.watchActivityGetById),
    fork(activitySagas.watchActivityIsRelated),
    fork(activitySagas.watchActivityCreate),
    fork(activitySagas.watchActivityUpdate),
    fork(activitySagas.watchActivityDelete),
    fork(activitySagas.watchActivityCreateBreakCourses),
    fork(activitySagas.watchActivityGetProgramsByFaculties),
    fork(activitySagas.watchActivityDublication),
    fork(activitySagas.watchActivityDublicationBySection),
    fork(activitySagas.watchActivityGetSelectOptions),
    fork(activitySagas.watchCreateActivityExcelBulk),
    fork(activitySagas.watchActivityCourseSectionsUpdate),
    fork(instructorSagas.watchInstructorSearch),
    fork(instructorSagas.watchInstructorGetById),
    fork(instructorSagas.watchInstructorCreate),
    fork(instructorSagas.watchInstructorUpdate),
    fork(instructorSagas.watchInstructorDelete),
    fork(instructorSagas.watchInstructorGetSelectOptions),
    fork(instructorSagas.watchInstructorGetProgramsByFaculties),
    fork(instructorSagas.watchInstructorGetInvigilatorProgramsByFaculties),
    fork(instructorSagas.watchCreateInstructorExcelBulk),
    fork(instructorSagas.watchChangeInstructorAllData),
    fork(coursePeriodSagas.watchCoursePeriodSearch),
    fork(coursePeriodSagas.watchCoursePeriodGetById),
    fork(coursePeriodSagas.watchCoursePeriodCreate),
    fork(coursePeriodSagas.watchCoursePeriodUpdate),
    fork(coursePeriodSagas.watchCoursePeriodDelete),
    fork(coursePeriodSagas.watchCourseActivityRelationSearch),
    fork(coursePeriodSagas.watchCourseUpdateActivityRelation),
    fork(coursePeriodSagas.watchCoursePostActivityRelation),
    fork(coursePeriodSagas.watchCourseDeleteActivityRelation),
    fork(coursePeriodSagas.watchCourseDeleteFromActivityRelation),
    fork(coursePeriodSagas.watchCourseTermHoursUpdate),
    fork(coursePeriodSagas.watchCourseTermAttachedClassrooms),
    fork(coursePeriodSagas.watchCourseTermDeleteAttachedClassrooms),
    fork(examPeriodSagas.watchActivitiesAddToPeriod),
    fork(examPeriodSagas.watchExamPeriodCourseChangeAllData),
    fork(examPeriodSagas.watchExamPeriodSearch),
    fork(examPeriodSagas.watchExamPeriodGetById),
    fork(examPeriodSagas.watchExamPeriodCreate),
    fork(examPeriodSagas.watchExamPeriodUpdate),
    fork(examPeriodSagas.watchExamPeriodCopy),
    fork(examPeriodSagas.watchExamPeriodCopyGetRequestStatus),
    fork(examPeriodSagas.watchExamPeriodDelete),
    fork(examPeriodSagas.watchExamDatesGetById),
    fork(examPeriodSagas.watchExamDatesCreate),
    fork(examPeriodSagas.watchCourseDatesCreate),
    fork(examPeriodSagas.watchExamDatesUpdate),
    fork(examPeriodSagas.watchCourseDatesUpdate),
    fork(examPeriodSagas.watchExamDatesDelete),
    fork(examPeriodSagas.watchCourseDatesDelete),
    fork(examPeriodSagas.watchSaveScheduletoStore),
    fork(examPeriodSagas.watchInstructorHoursGetById),
    fork(examPeriodSagas.watchInstructorsAddToPeriod),
    fork(examPeriodSagas.watchInstructorSaveInvigilations),
    fork(examPeriodSagas.watchInstructorHoursUpdate),
    fork(examPeriodSagas.watchInstructorHoursUpdateMultiple),
    fork(examPeriodSagas.watchInstructorsDeleteFromPeriod),
    fork(examPeriodSagas.watchClassroomHoursGetById),
    fork(examPeriodSagas.watchTermWeekDates),
    fork(examPeriodSagas.watchPutTermWeekDates),
    fork(examPeriodSagas.watchTermWeekExclutionDatesDelete),
    fork(examPeriodSagas.watchTermWeekHolidayDatesDelete),
    fork(examPeriodSagas.watchClassroomsAddToPeriod),
    fork(examPeriodSagas.watchClassroomHoursUpdate),
    fork(examPeriodSagas.watchMultipleClassroomHoursUpdate),
    fork(examPeriodSagas.watchClassroomsDeleteFromPeriod),
    fork(examPeriodSagas.watchCourseHoursGetById),
    fork(examPeriodSagas.watchCourseTermCourseHoursGetById),
    fork(examPeriodSagas.watchCourseHoursUpdate),
    fork(examPeriodSagas.watchMultipleCourseHoursUpdate),
    fork(examPeriodSagas.watchCreateCombinedExams),
    fork(examPeriodSagas.watchCreateCombinedActivities),
    fork(examPeriodSagas.watchUpdateCombinedExams),
    fork(examPeriodSagas.watchCoursesAddToPeriod),
    fork(examPeriodSagas.watchAttachClassroomToCourses),
    fork(examPeriodSagas.watchCourseTermAttachClassroomToCourses),
    fork(examPeriodSagas.watchGetAttachedClassrooms),
    fork(examPeriodSagas.watchGetOverlaps),
    fork(examPeriodSagas.watchCourseTermGetOverlaps),
    fork(examPeriodSagas.watchDeleteAttachedClassrooms),
    fork(examPeriodSagas.watchCoursesDeleteFromPeriod),
    fork(examPeriodSagas.watchCourseTermAttachClassroomToPrograms),
    fork(examPeriodSagas.watchCourseTermClassroomPriorityPrograms),
    fork(examPeriodSagas.watchCourseTermDeleteClassroomPriorityAttachedClassrooms),
    fork(examPeriodSagas.watchBulkCombineExamsSearch),
    fork(examPeriodSagas.watchStudentsGet),
    fork(examPeriodSagas.watchStudentsDelete),
    fork(examPeriodSagas.watchStudentsDeleteAll),
    fork(examPeriodSagas.watchCreateStudentBulk),
    fork(examPeriodSagas.watchCheckStudents),
    fork(examPeriodSagas.watchUpdateCoursesViaStudents),
    fork(examPeriodSagas.watchStudentsChangeAllData),
    fork(examPeriodSagas.watchStudentsCourseOverlaps),
    fork(examPeriodSagas.watchStudentGetCourseProgramsByFaculties),
    fork(examPeriodSagas.watchStudentGetEnrolledProgramsByFaculties),
    fork(examPeriodSagas.watchStudentGetSelectOptions),
    fork(examPeriodSagas.watchGetExamPeriodList),
    fork(examPeriodSagas.watchExamPeriodGetSelectOptions),
    fork(examPeriodSagas.watchCopyCourseToPeriod),
    fork(examPeriodSagas.watchCourseTermCopyCourseToPeriod),
    fork(examPeriodSagas.watchCourseHoursEditCourse),
    fork(examPeriodSagas.watchCourseTermCourseHoursEditCourse),
    fork(examPeriodSagas.watchCreateClassroomHoursExcelBulk),
    fork(examPeriodSagas.watchCreateInstructorHoursExcelBulk),
    fork(examPeriodSagas.watchCreateCourseHoursExcelBulk),
    fork(solutionSagas.watchGetSolutionByTerm),
    fork(solutionSagas.watchGetSolution),
    fork(solutionSagas.watchGetCourseSolution),
    fork(solutionSagas.watchGetInvigilatorCountSolution),
    fork(solutionSagas.watchSaveSolution),
    fork(solutionSagas.watchCopySolution),
    fork(solutionSagas.watchDeleteSolution),
    fork(solutionSagas.watchPublicSolution),
    fork(solutionSagas.watchSolutionActivitySelectOptions),
    fork(solutionSagas.watchSolutionActivityClassroomSelectOptions),
    fork(solutionSagas.watchSolutionEditActivity),
    fork(solutionSagas.watchSolutionAddActivity),
    fork(solutionSagas.watchSolutionDeleteActivity),
    fork(solutionSagas.watchSolutionGetProgramsByFaculties),
    fork(solutionSagas.watchSolutionCourseGetProgramsByFaculties),
    fork(solutionSagas.watchSolutionCourseSelectOptions),
    fork(solutionSagas.watchSolutionEditCourse),
    fork(solutionSagas.watchSolutionAddCourse),
    fork(solutionSagas.watchSolutionDeleteCourse),
    fork(solutionSagas.watchDownloadStudentExamList),
    fork(solutionSagas.watchDownloadInstructorExamList),
    fork(solutionSagas.watchSolutionActivityPrograms),
    fork(solutionSagas.watchSolutionCoursePrograms),
    fork(solutionSagas.watchDownloadSolutionRawFiles),
    fork(solutionSagas.watchSolutionGetExamDates),
    fork(solutionSagas.watchSolutionBatchChangeExamDates),
    fork(settingSagas.watchGetSettings),
    fork(settingSagas.watchGetGeneralSettings),
    fork(settingSagas.watchUpdateSettings),
    fork(settingSagas.watchChangePassword),
    fork(integrationSagas.watchGetIntegration),
    fork(integrationSagas.watchAddMultiWithIntegration),
    fork(notificationSagas.watchNotificationSearch),
    fork(notificationSagas.watchNotificationGetById),
    fork(notificationSagas.watchNotificationGetSignedUrlById),
    fork(notificationSagas.watchNotificationGetSelectOptions),
    fork(notificationSagas.watchNotificationCreate),
    fork(notificationSagas.watchNotificationDelete),
    fork(backupSagas.watchBackupSearch),
    fork(backupSagas.watchBackupGetById),
    fork(backupSagas.watchBackupGetSelectOptions),
    fork(backupSagas.watchBackupCreate),
    fork(backupSagas.watchBackupRestore),
    fork(backupSagas.watchBackupDelete),
    fork(SyncSagas.watchSynchronizationCreate),
    fork(SyncSagas.watchSyncGetSelectOptions),
    fork(SyncSagas.watchSyncBuildingSearch),
    fork(SyncSagas.watchSyncProgramSearch),
    fork(SyncSagas.watchSyncCampusSearch),
    fork(SyncSagas.watchSyncClassroomSearch),
    fork(SyncSagas.watchSyncFacultySearch),
    fork(SyncSagas.watchSyncInstructorSearch),
    fork(SyncSagas.watchSyncCourseSearch),
    fork(SyncSagas.watchSyncStudentSearch),
    fork(SyncSagas.watchSyncBuildingAddToMain),
    fork(SyncSagas.watchSyncCampusAddToMain),
    fork(SyncSagas.watchSyncClassroomAddToMain),
    fork(SyncSagas.watchSyncFacultyAddToMain),
    fork(SyncSagas.watchSyncProgramAddToMain),
    fork(SyncSagas.watchSyncInstructorAddToMain),
    fork(SyncSagas.watchSyncCourseAddToMain),
    fork(SyncSagas.watchSyncStudentAddToMain),
    fork(SyncSagas.watchSyncGetModalSelectOptions),
    fork(SyncSagas.watchSyncGetCampusOptions),
    fork(SyncSagas.watchGetBuildingOptions),
    fork(SyncSagas.watchSyncGetProgramOptions),
    fork(SyncSagas.watchGetFacultyOptions),
    fork(SyncSagas.watchSendSyncType),
    fork(SyncSagas.watchSyncJobStatus),
    fork(SyncSagas.watchDisplayCampusData),
    fork(SyncSagas.watchSyncListSyncJobs),
    fork(SyncSagas.watchSyncGetFetchedData),
    fork(DistributorSagas.watchDistributorSearch),
    fork(DistributorSagas.watchDistributorStatusHistorySearch),
    fork(DistributorSagas.watchDistributorCancel),
    fork(DistributorSagas.watchDistributorCreate),
    fork(DistributorSagas.watchDistributorConfirm),
    fork(DistributorSagas.watchDistributorDownloadHistory),
    fork(DistributorSagas.watchDistributorStopCalculation),
    fork(DistributorSagas.watchDistributorResetMaxNumberOfAttempts),
    fork(DistributorSagas.watchDistributorReassignInvigilatorsAuto),
    fork(DistributorSagas.watchDistributorSearchInProgressStatus),
    fork(DistributorSagas.watchDistributorSolutionChangeGetSelectOptions),
    fork(DistributorSagas.watchDistributorGetInfoSolutionChange),
    fork(DistributorSagas.watchDistributorSetCourseTimeSlot),
    fork(DistributorSagas.watchDistributorSolutionChange),
    fork(DistributorSagas.watchDistributorInvigilatorChangeGetSelectOptions),
    fork(DistributorSagas.watchDistributorGetInfoInvigilatorChange),
    fork(DistributorSagas.watchDistributorSetExamInvigilator),
    fork(DistributorSagas.watchDistributorInvigilatorChange),
    fork(DistributorSagas.watchDistributorSingleExamChangeGetSelectOptions),
    fork(DistributorSagas.watchDistributorGetInfoSingleExamChange),
    fork(DistributorSagas.watchDistributorSingleExamChange),
    fork(DistributorSagas.watchDistributorSingleCourseChangeGetSelectOptions),
    fork(DistributorSagas.watchDistributorGetInfoSingleCourseChange),
    fork(DistributorSagas.watchDistributorSingleCourseChange),
    fork(DistributorSagas.watchDistributorClassroomChangeGetSelectOptions),
    fork(DistributorSagas.watchDistributorGetInfoClassroomChange),
    fork(DistributorSagas.watchDistributorSetCourseClassroom),
    fork(DistributorSagas.watchDistributorClassroomChange),
    fork(DistributorSagas.watchDistributorSubmitSolution),
    fork(dashboardSagas.watchDashboardGetMetabaseReport),
    fork(SketchSagas.watchSketchCampusSearch),
    fork(SketchSagas.watchSketchBuildingSearch),
    fork(SketchSagas.watchSketchGetSelectOptions),
    fork(SketchSagas.watchSketchCampusGetById),
    fork(SketchSagas.watchSketchBuildingGetById),
    fork(SketchSagas.watchBuildingMarkerUpdate),
    fork(SketchSagas.watchClassroomMarkerUpdate),
    fork(SketchSagas.watchSketchBulkUpdate),
  ]);
}
