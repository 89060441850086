import React, { useEffect, useState } from 'react';
import { FormikProps } from "formik";
import Button from "../../components/button";
import InputField from "../../components/Input/default-input";
import RangeInputField from "../../components/Input/range-input";
import SelectField from "../../components/Input/select-field";
import cn, { flexCenter, flexRow } from "../../components/ui/Tailwind";
import { IAppSelectOptions, ICreateFilterEvent, IPersistedState, ISelectOption } from "../../store/types";
import Translator from "../../services/translate-factory";
import { RBCEventType } from "./constants";
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import AvailablePlaceTable from "./add-place-table";
import { connect } from 'react-redux';
import Warning from '../../components/warning';
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';
import { ClassroomTypes } from '../../store/constants/setting-const';

interface AvailablePlaceProps {
    formikProps: FormikProps<ICreateFilterEvent>
    dispatch?: any,
    selectOptions?: IAppSelectOptions;
}

const AvailablePlaceIn: React.FC<AvailablePlaceProps> = ({ formikProps: props, dispatch, selectOptions }) => {
    const T = new Translator();
    const { values, setFieldValue } = props

    const [selectOptionsState, setSelectOptionsState] = useState<{
        campusOptions?: ISelectOption[],
        buildingOptions?: ISelectOption[],
        featureOptions?: ISelectOption[],
        sittingTypeOptions?: ISelectOption[],
        typeFunctionOptions?: ISelectOption[],
        seatingArrangementOptions?: ISelectOption[]
    }>({
        campusOptions: [],
        buildingOptions: [],
        featureOptions: [],
        sittingTypeOptions: [],
        typeFunctionOptions: [],
        seatingArrangementOptions: []
    });

    useEffect(() => {
        // loads selectOptions 
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, undefined, 'add-place-select-options', loadSelectOptions));
    }, [])

    const loadSelectOptions = () => {
        // building is will be fetched when campus is selected
        setSelectOptionsState({
            campusOptions: selectOptions && selectOptions.campusRelation && selectOptions.campusRelation.campuses,
            featureOptions: [
                ...ClassroomFeatureOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.features || []),
            ].sort((a, b) => a.label.localeCompare(b.label)),
            sittingTypeOptions: [
                ...ClassroomSeatingTypeOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.sitting_types || []),
            ].sort((a, b) => a.label.localeCompare(b.label)),
            typeFunctionOptions: [
                ...ClassroomTypes(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.type_functions || []),

            ].sort((a, b) => a.label.localeCompare(b.label)),
            seatingArrangementOptions: [
                ...ClassroomSeatingArrangementOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.seating_arrangement || []),
            ].sort((a, b) => a.label.localeCompare(b.label))
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, minKey: keyof ICreateFilterEvent, maxKey: keyof ICreateFilterEvent) => {
        const { name, value } = e.target;
        if (name.includes('min')) {
            setFieldValue(minKey, value);
        } else {
            setFieldValue(maxKey, value);
        }
    };

    const handleSliderChange = (values: number[], minKey: keyof ICreateFilterEvent, maxKey: keyof ICreateFilterEvent) => {
        setFieldValue(minKey, values[0]);
        setFieldValue(maxKey, values[1]);
    };

    const isValid = (option: any) => {
        return option && Array.isArray(option) && option.length !== 0;
    }

    const isDisable = (values: ICreateFilterEvent, key: keyof ICreateFilterEvent): boolean => {
        const value = values[key];

        if (Array.isArray(value)) {
            return value.length <= 0;  // eger array ise, bos olup olmadigini kontrol eder
        }
        return !(value !== null && value !== undefined && value !== '');
    };

    const resetFilter = () => {
        props.resetForm(
            {
                title: values.title,
                description: values.description,
                event_type: values.event_type,
                start_date_and_time: values.start_date_and_time,
                end_date_and_time: values.end_date_and_time,
                isSearched: false,
            }
        )
    }

    const search = () => {
        props.setFieldValue('isSearched', true);
        // load table data to show available places
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, props.values, 'available-place-table'));
    }

    const isCampusSelected = values.campuses && values.campuses.length > 0
    const isCourseOrOther = values.event_type && values.event_type.value !== RBCEventType.EXAM
    const isExam = values.event_type && values.event_type.value === RBCEventType.EXAM
    const isCourse = values.event_type && values.event_type.value === RBCEventType.COURSE

    return <div className="row tw-justify-center">
        <div className='col-md-12 tw-mb-4'>
            <Warning show={!isCampusSelected}>
                {T.t('gen_warning_event_select_campus_for_search')}
            </Warning>
        </div>
        <div className="col-md-3">
            <SelectField
                spinner="add-place-select-options"
                id="campuses"
                label={T.t("gen_campus") + " *"}
                options={selectOptionsState.campusOptions}
                value={values.campuses}
                onChange={(option) => {
                    props.setFieldValue('campuses', option);
                    //loads selectOptions.campusRelation.relatedBuildings and selectOptions.campusRelation.relatedFaculities
                    if (isValid(option))
                        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { campus_ids: option }, 'select-options-related-with-campus-ids'));
                }}
                placeholder={T.t('gen_select_campus') + " *"}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <SelectField
                spinner="select-options-related-with-campus-ids"
                id="building"
                label={T.t("gen_building")}
                options={selectOptionsState.buildingOptions}
                isDisabled={isDisable(values, 'campuses')}
                value={values.buildings}
                onChange={(option) => {
                    props.setFieldValue('buildings', option);
                }}
                placeholder={T.t('gen_select_building')}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <SelectField
                spinner="add-place-select-options"
                id="feature"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_feature")}
                options={selectOptionsState.featureOptions}
                value={values.features}
                onChange={props.handleChange('features')}
                placeholder={T.t('gen_select_feature')}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <InputField
                id="floor"
                type="number"
                min={0}
                label={T.t("gen_floor")}
                onChange={props.handleChange('floor')}
                required
            />
        </div>
        <div className="col-md-4">
            <SelectField
                spinner="add-place-select-options"
                id="=type_function"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_type_function")}
                options={selectOptionsState.typeFunctionOptions}
                value={values.type_functions}
                onChange={props.handleChange('type_functions')}
                placeholder={T.t('gen_select_type')}
                T={T}
            />
        </div>
        <div className="col-md-4">
            <SelectField
                spinner="add-place-select-options"
                id="=sitting_type"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_sitting_type")}
                options={selectOptionsState.sittingTypeOptions}
                value={values.sitting_types}
                onChange={props.handleChange('sitting_types')}
                placeholder={T.t('gen_select_sitting_type')}
                T={T}
            />
        </div>
        <div className="col-md-4">
            <SelectField
                spinner="add-place-select-options"
                id="=seating_arrangement"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_seating_arrangement")}
                options={selectOptionsState.seatingArrangementOptions}
                value={values.seating_arrangement}
                onChange={props.handleChange('seating_arrangement')}
                placeholder={T.t('gen_select_seating_arrangement')}
                T={T}
            />
        </div>
        <div className={cn(flexRow, "col-md-12", flexCenter)}>
            {
                isCourseOrOther &&
                <RangeInputField
                    id="lecture_capacity"
                    minValue={values.min_lecture_capacity}
                    maxValue={values.max_lecture_capacity}
                    onChange={(e) => handleChange(e, 'min_lecture_capacity', 'max_lecture_capacity')}
                    label={isCourse ? T.t('gen_lecture_capacity') : T.t("gen_capacity")}
                    setSlider={(values) => handleSliderChange(values, 'min_lecture_capacity', 'max_lecture_capacity')}
                    T={T}
                />
            }
            {
                isExam &&
                <RangeInputField
                    id="exam_capacity"
                    minValue={values.min_exam_capacity}
                    maxValue={values.max_exam_capacity}
                    onChange={(e) => handleChange(e, 'min_exam_capacity', 'max_exam_capacity')}
                    setSlider={(values) => handleSliderChange(values, 'min_exam_capacity', 'max_exam_capacity')}
                    label={T.t('gen_exam_capacity')}
                    T={T}
                />
            }
        </div>
        <div className="col-md-12 tw-flex">
            <div className="tw-ml-auto tw-flex tw-flex-row tw-gap-2">
                <Button color="red" onClick={resetFilter} icon="delete"></Button>
                <Button disabled={!values.campuses || values.campuses.length > 0} color="blue" icon="search" onClick={search}>{T.t('gen_search')}</Button>
            </div>
        </div>
        <div className="col-md-12">
            <AvailablePlaceTable formikProps={props} />
        </div>
    </div>
}

const mapStateToProps = (store: IPersistedState, ownProps: AvailablePlaceProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options,
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const AvailablePlace = connect(mapStateToProps, dispatchProps)(AvailablePlaceIn);

export default AvailablePlace