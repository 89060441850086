import React, { Component } from 'react';
import Select from 'react-select';
import { Formik, FormikProps, FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import * as Types from '../../store/types';
import * as Constants from '../../store/constants/all';
import * as Actions from '../../store/actions/general';
import { FacultyFormValidation } from './validations/faculty-form-val';
import { FacultyFormInitialValues } from '../../store/constants/faculty-const';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('FacultyForm');

function getInitialState(): Types.IFacultyFormState {
  const initialValues: Types.IFacultyFormState = {
    model: Object.assign({}, FacultyFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class FacultyForm extends Component<Types.IFacultyFormProps, Types.IFacultyFormState> {
  state: Types.IFacultyFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IFacultyItem, FormActions: FormikActions<Types.IFacultyItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.facultyId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.faculty.FACULTY_UPDATE, model, 'faculty-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.faculty.FACULTY_CREATE, model, 'faculty-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.IFacultyFormProps, state: Types.IFacultyFormState) {
    let hasNewState: boolean = false;
    if (props.facultyId && props.facultyId != state.model.faculty_id) {
      state.model.faculty_id = props.facultyId;
      props.dispatch(Actions.ApiRequest(Constants.faculty.FACULTY_GET_BY_ID, props.facultyId, 'faculty-form-spin'));
      hasNewState = true;
    }
    if (props.form && props.facultyId && props.facultyId == state.model.faculty_id) {
      state.model = props.form;
      if (props.form.buildings) {
        state.model.building_ids = props.form.buildings.map((f) => f.value);
      }
      if (props.form.programs) {
        state.model.program_ids = props.form.programs.map((f) => f.value);
      }
      if (props.form.campus) {
        state.model.campus_id = props.form.campus.value;
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.facultyId && state.model.faculty_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={FacultyFormValidation(T)}
        >
          {(props: FormikProps<Types.IFacultyItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {this.props.facultyId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_faculty')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_faculty')}</>}
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="faculty_code"
                              name="faculty_code"
                              value={values.faculty_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="faculty_code">{T.t('gen_code')}</label>
                            {errors && errors.faculty_code && props.submitCount > 0 && (
                              <div className="error">{errors && errors.faculty_code}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="error">{errors && errors.name}</div>
                            )}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_connected_campuses')}</label>
                                <Select
                                  className="react-select"
                                  name="campus_id"
                                  id="campus_id"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.campuses
                                      ? this.props.selectOptions.campuses
                                      : []
                                  }
                                  placeholder={T.t('gen_select_campus')}
                                  value={props.values.campus}
                                  onChange={(option: any) => {
                                    props.setFieldValue('campus_id', option.value);
                                    props.setFieldValue('campus', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                />
                              </div>
                              {errors && errors.campus_id && props.submitCount > 0 && (
                                <div className="error">{errors && errors.campus_id}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IFacultyFormProps): Types.IFacultyFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IFacultyFormProps = Object.assign({}, ownProps, {
    form: store.state.faculty_page && store.state.faculty_page.form ? store.state.faculty_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.facultyPage
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.faculty_page) {
    return (
      !!equal(
        prev.state.faculty_page && prev.state.faculty_page.form,
        next.state.faculty_page && next.state.faculty_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.facultyPage,
        next.state.select_options && next.state.select_options.facultyPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(FacultyForm);

export default container;
