import * as Types from '../../store/types';

export const EventPeriodSearchInitialValues: Types.IFilterEventPeriod = {
    name: '',
    status: [],
    terms: [],
    year: undefined,
    page: 1,
    order_by: '',
    query: '',
    total: -1,
    size: 10,
    event_types: []
};

export const EventPeriodFormInitialValues: Types.ITermItem = {
    term_id: undefined,
    status: 1,
    name: '',
    academic_terms: [],
    term_type: 1,
    calendar_status: 0,
    description: '',
    year: undefined,
    time_range: undefined
};