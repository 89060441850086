import React, { ChangeEvent, useEffect, useReducer } from 'react';
import Button from '../../../components/button';
import Input from '../../../components/Input';
import Translator from '../../../services/translate-factory';
import { DefinitionPieceData, Item } from '../defitinions-settings';
import * as Constants from '../../../store/constants/all';

type SetDefinitionData = React.Dispatch<React.SetStateAction<DefinitionPieceData>>

interface DefinitionPieceProps {
    values: { [key: string]: any };
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    definitionPiece: DefinitionPieceData;
    setDefinitionPiece: SetDefinitionData;
    onAddItem: (item: string, definitionPiece: DefinitionPieceData, setDefinitionPiece: SetDefinitionData, localizedLabels?: {
        label: any;
        value: number;
    }[]) => void;
    deleteItem: (index: number, item: Item, definitionPiece: DefinitionPieceData, setDefinitionPiece: SetDefinitionData) => void;
    itemLabel: string;
    enterItemLabel: string;
    translateLabel?: (id: number, T: Translator) => string;
    localizedLabel?: {
        label: any;
        value: number;
    }[],
    setModelSettings: (newStates: any) => void
}

const DefinitionPiece = ({
    values,
    handleChange,
    definitionPiece,
    setDefinitionPiece,
    onAddItem,
    deleteItem,
    itemLabel,
    enterItemLabel,
    translateLabel,
    localizedLabel,
}: DefinitionPieceProps) => {
    const T = Translator.create();
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

    const undeletableString = localizedLabel && localizedLabel.map(_ => _.label)
    const langChanged = () => {
        forceUpdate();
    };

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
        };
    }, []);

    return (
        <div className="col-md-6">
            <h6>{T.t(itemLabel)}</h6>
            <div className="row align-items-center">
                <div className="col-md-10">
                    <div className="form-input form-group date-picker">
                        <input
                            id={definitionPiece.itemType}
                            name={definitionPiece.itemType}
                            value={values[definitionPiece.itemType]}
                            onChange={handleChange}
                            type="text"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor={definitionPiece.itemType}>{T.t(enterItemLabel)}</label>
                        <i className="material-icons">playlist_add</i>
                    </div>
                    <div className="col-md-12 list-error">
                        {definitionPiece.isError ? (
                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                        ) : null}
                    </div>
                </div>
                <div className="col-md-2">
                    <button
                        id="button_add_circle_outline"
                        className="mss-table-add-button"
                        disabled={!values[definitionPiece.itemType]}
                        onClick={() => onAddItem(values[definitionPiece.itemType], definitionPiece, setDefinitionPiece, localizedLabel)}
                    >
                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                    </button>
                </div>
                <div className="col-md-11">
                    <div className="table-scrollable-td">
                        <ul className="tw-space-y-2">
                            {definitionPiece.items.filter(item => !item.isDeleted).map((item, index) => {
                                let localizedName = ""
                                if (translateLabel) {
                                    const translated = translateLabel(Number(item.id), T)
                                    localizedName = translated === "-" ? item.name : translated
                                } else {
                                    localizedName = item.name
                                }
                                if (values[definitionPiece.itemType] && !localizedName.toLowerCase().includes(values[definitionPiece.itemType].toLowerCase())) {
                                    return null
                                }

                                return (
                                    <li key={localizedName} className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                                        <div className='form-input' style={{ width: '85%' }}>
                                            <Input
                                                id={`${definitionPiece.itemType}_${item.id}`}
                                                name={`${definitionPiece.itemType}-${item.id}`}
                                                value={localizedName}
                                                disabled={true}
                                                type="text"
                                                required
                                            />
                                        </div>
                                        {!(undeletableString && undeletableString.includes(localizedName)) ? (
                                            <div className="form-input" style={{ width: '10%' }}>
                                                <Button
                                                    id="button_delete_outline"
                                                    className="ms-table-delete-button tw-p-1"
                                                    icon='delete'
                                                    size='disable'
                                                    onClick={() => deleteItem(index, item, definitionPiece, setDefinitionPiece,)}
                                                />
                                            </div>
                                        ) : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DefinitionPiece;



    // burada setModelSettings'in icerisine girilen field'in (classroom_types, etc.) adinin dogru yazilmasi onemli
    // parentta bulunan degistirilmek istenen state ile ayni isimde olmasi gerekiyor.