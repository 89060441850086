import React, { ButtonHTMLAttributes } from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import cn, { flexIC, flexRow, gap2 } from "../ui/Tailwind";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    color?: keyof typeof buttonColorList,
    customColor?: string,
    icon?: keyof typeof buttonIconList
    children?: React.ReactNode
    iconPosition?: "left" | "right"
    size?: keyof typeof sizeList
}

export const buttonColorList = {
    "blue": "tw-bg-blue-500 tw-text-white disabled:hover:tw-bg-blue-500 hover:tw-bg-blue-700",
    "green": "tw-bg-green-500 tw-text-white hover:tw-bg-green-600/80 disabled:hover:tw-bg-green-500",
    "red": "tw-bg-red-500 tw-text-white  hover:tw-bg-red-700 disabled:hover:tw-bg-red-500",
    "pastelRed": "tw-bg-red-200 tw-text-red-900 hover:tw-bg-red-300 disabled:hover:tw-bg-red-200",
    "pastelGreen": "tw-bg-green-200 tw-text-green-900 hover:tw-bg-green-300 disabled:hover:tw-bg-green-200",
    "pastelBlue": "tw-bg-blue-200 tw-text-blue-900 hover:tw-bg-blue-300 disabled:hover:tw-bg-blue-200",
    "pastelGray": "tw-bg-gray-200 tw-text-gray-900 hover:tw-bg-gray-300 disabled:hover:tw-bg-gray-200",
    "white": "tw-bg-white tw-text-black  hover:tw-bg-gray-100 disabled:hover:tw-bg-white",
    "gray": "tw-bg-gray-500 tw-text-white  hover:tw-bg-gray-700 disabled:hover:tw-bg-gray-500",
    "light-gray": "tw-bg-gray-200 tw-text-black hover:tw-bg-gray-300 disabled:hover:tw-bg-gray-200",
    "light-gray-100": "tw-bg-gray-100 tw-text-gray-500 hover:tw-bg-gray-200 disabled:hover:tw-bg-gray-100",
}

const textSizes = {
    "disable": "",
    "xs": "tw-text-xs",
    "sm": "tw-text-sm",
    "base": "tw-text-base",
    "md": "tw-text-base",
    "xl": "tw-text-xl",
}

const sizeList = {
    "disable": "",
    "xs": "tw-py-1 tw-px-2 tw-text-xs",
    "sm": "tw-py-1 tw-px-2 tw-text-sm",
    "base": "tw-py-2 tw-px-3 tw-text-base",
    "md": "tw-py-2 tw-px-3 tw-text-base",
    "xl": "mt-2 mb-2 mt-md-0 mb-md-0 btn tw-text-base",
}

export const buttonIconList = {
    "download": "cloud_download",
    "delete": "delete_outline",
    "add": "add",
    "playlist_add": "playlist_add",
    "search": "search",
    "save": "save",
    "filter_list": "filter_list",
    "cancel": "cancel",
    "close": "close",
    "lock": <FaLock />,
    "lock_open": <FaLockOpen />,
    "arrow_upward": "arrow_upward",
    "delete_sweep": "delete_sweep",
}

export default function Button({ onClick, size = "base", className, color, customColor, iconPosition = "left", children, icon, ...otherElements }: Readonly<ButtonProps>) {

    return <button type='button' {...otherElements} onClick={onClick} className={cn("tw-rounded", color && buttonColorList[color], sizeList[size], customColor,
        "tw-duration-150 tw-transition-all tw-shadow-sm tw-ease-in-out active:tw-shadow-inner disabled:tw-cursor-not-allowed disabled:tw-shadow-none",
        "disabled:tw-opacity-50 disabled:tw-cursor-not-allowed disabled:active:tw-scale-100 active:tw-scale-95",
        className)}>
        <IconMaker {...otherElements} icon={icon} iconPosition={iconPosition}>
            {children}
        </IconMaker>
    </button>
}

const IconMaker = ({ children, ...otherElements }: ButtonProps): React.ReactElement => {

    if (!otherElements.icon)
        return <>{children}</>

    let renderIcon
    if (typeof buttonIconList[otherElements.icon] === 'string') {
        renderIcon = <i className="material-icons">{buttonIconList[otherElements.icon]}</i>
    } else {
        renderIcon = buttonIconList[otherElements.icon]
    }

    if (otherElements.iconPosition === "left")
        return <div className={cn(flexRow, flexIC, gap2)}>
            {renderIcon}
            {children}
        </div>
    return <div className={cn(flexRow, flexIC, gap2)}>
        {children}
        {renderIcon}
    </div>
}