import React, { useEffect, useState } from 'react';
import { FormikProps } from "formik"
import { FaRegCalendar, FaRegClock } from "react-icons/fa"
import InputField from "../../components/Input/default-input"
import SelectField from "../../components/Input/select-field"
import cn, { flexCol, flexIC, flexRow, gap2 } from "../../components/ui/Tailwind"
import { IAppSelectOptions, ICreateFilterEvent, IPersistedState, ISelectOption } from "../../store/types"
import Translator from "../../services/translate-factory"
import { RBCEventTypes } from "./constants"
import Flatpickr from 'react-flatpickr';
import * as GT from '../../tools/general-tools';
import moment from "moment"
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Warning from '../../components/warning';
interface EventDetailProps {
    formikProps: FormikProps<ICreateFilterEvent>
    selectOptions?: IAppSelectOptions,
    dispatch?: any
}

const EventDetailIn: React.FC<EventDetailProps> = ({ formikProps: props, selectOptions, dispatch }) => {
    const T = new Translator()
    const locale = GT.getLocaleFromLangCode()
    const { values, handleChange, setFieldValue, errors, submitCount } = props
    const isDone = values.start_date_and_time && props.values.end_date_and_time && props.values.title && props.values.event_type
    const [eventTypeOptions, setEventTypeOptions] = useState<ISelectOption[]>([])

    useEffect(() => {
        // loads selectOptions 
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, undefined, 'event-type-options', loadSelectOptions));
    }, [])

    const loadSelectOptions = () => {
        setEventTypeOptions([
            ...RBCEventTypes(T),
            ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.eventTypes || [])
        ].sort((a, b) => a.label.localeCompare(b.label)))
    }

    return <div className={cn(flexCol, gap2)}>
        <Warning show={!isDone} classNames='col-md-12 tw-mb-4'>
            {T.t("gen_warning_event_date_not_selected")}
        </Warning>
        <div className='row tw-max-w-screen-md tw-mb-12'>
            <div className="col-md-6">
                <InputField
                    id="title"
                    value={values.title}
                    label={T.t("gen_event_name")}
                    onChange={handleChange('title')}
                    required
                />
            </div>
            <div className="col-md-6">
                <SelectField
                    id="event_type"
                    spinner="event-type-options"
                    isCreatable
                    isClearable
                    label={T.t("gen_event_type")}
                    options={eventTypeOptions}
                    value={values.event_type}
                    onChange={handleChange('event_type')}
                    placeholder={T.t('gen_select_or_type_event_type')}
                    T={T} />
            </div>
            <div className="col-md-12">
                <InputField
                    id="description"
                    label={T.t("gen_description")}
                    value={values.description}
                    onChange={handleChange('description')}
                    type="textarea"
                    required
                />
            </div>
            <div className={cn("col-md-12 tw-flex tw-flex-row tw-gap-4 tw-justify-center")}>
                <div className="tw-w-full form-input form-group with-icon">
                    <div className="tw-relative add-custom-tag mt-3">
                        <Flatpickr
                            id='start_date_and_time'
                            value={values.start_date_and_time}
                            name="start_date_and_time"
                            placeholder={T.t('gen_select_date_and_time')}
                            options={{
                                dateFormat: "d.m.Y H:i",
                                locale: locale,
                                enableTime: true,
                                maxDate: values.end_date_and_time,
                            }}
                            onChange={(value) => {
                                setFieldValue('start_date_and_time', moment(value[0]).format('DD.MM.YYYY HH:mm'))
                            }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label className="tw-absolute tw-left-0 -tw-top-2" htmlFor="start_date_and_time">{T.t('gen_start_date_and_time')}</label>
                        <div className={cn(flexRow, flexIC, gap2, "tw-absolute tw-bottom-2 tw-right-1 tw-transform -tw-translate-y-1/2 tw-pointer-events-none tw-text-gray-400")}>
                            <FaRegCalendar />
                            <FaRegClock />
                        </div>
                    </div>
                    {errors && errors.start_date_and_time && submitCount > 0 && (
                        <div className="error mt-3">{T.t('gen_cannot_leave_empty')}</div>
                    )}
                </div>
                <div className="tw-w-full form-input form-group with-icon">
                    <div className="tw-relative add-custom-tag mt-3">
                        <Flatpickr
                            id='end_date_and_time'
                            value={values.end_date_and_time}
                            name="end_date_and_time"
                            placeholder={T.t('gen_select_date_and_time')}
                            options={{
                                dateFormat: "d.m.Y H:i",
                                locale: locale,
                                enableTime: true,
                                minDate: values.start_date_and_time,
                            }}
                            onChange={(value) => {
                                setFieldValue('end_date_and_time', moment(value[0]).format('DD.MM.YYYY HH:mm'))
                            }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label className="tw-absolute tw-left-0 -tw-top-2" htmlFor="end_date_and_time">{T.t('gen_end_date_and_time')}</label>
                        <div className={cn(flexRow, flexIC, gap2, "tw-absolute tw-bottom-2 tw-right-1 tw-transform -tw-translate-y-1/2 tw-pointer-events-none tw-text-gray-400")}>
                            <FaRegCalendar />
                            <FaRegClock />
                        </div>
                    </div>
                    {errors && errors.end_date_and_time && submitCount > 0 && (
                        <div className="error mt-3">{T.t('gen_cannot_leave_empty')}</div>
                    )}
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (store: IPersistedState, ownProps: EventDetailProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options,
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventDetail = connect(mapStateToProps, dispatchProps)(EventDetailIn);


export default EventDetail