import { Modal, StepProps, Steps } from "antd";
import React, { useState, useRef } from "react";
import Button from "../../components/button";
import cn, { flexIC, flexRow, flexCenter, flexCol, gap2 } from "../../components/ui/Tailwind";
import Translator from '../../services/translate-factory';
import { Formik, FormikProps } from 'formik';
import { IAppSelectOptions, ICreateFilterEvent, IPersistedState } from "../../store/types";
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Preview from "./add-preview";
import AvailablePlace from "./add-place";
import EventDetail from "./add-detail";
import { connect } from "react-redux";

interface AddEventPeriodProps {
    isOpen: boolean
    toggle: () => void,
    dispatch?: any,
    selectOptions?: IAppSelectOptions;
}

interface StepItem {
    key: string
    content: React.ReactNode
    item: StepProps
}

export const AddEventPeriodIn: React.FC<AddEventPeriodProps> = ({ isOpen, toggle, dispatch, selectOptions }) => {
    const [modalKey, setModalKey] = useState(0)
    const T = Translator.create()
    const [current, setCurrent] = useState(0);
    const formikRef = useRef<Formik<ICreateFilterEvent>>(null)

    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }

    const stepItems = (formikProps: FormikProps<ICreateFilterEvent>): StepItem[] => [
        {
            key: 'event_info',
            content: <EventDetail formikProps={formikProps} />,
            item: {
                title: T.t("gen_event_info"),
            },
        },
        {
            key: 'place_info',
            content: <AvailablePlace formikProps={formikProps} />,
            item: {
                title: T.t('gen_select_place'),
            },
        },
        {
            key: 'preview',
            content: <Preview formikProps={formikProps} />,
            item: {
                title: T.t("gen_preview"),
            },

        }
    ]
    const [submitting, setSubmitting] = useState(false)
    return (
        <Modal
            key={modalKey}
            className="pt-0 tw-duration-150 tw-transform tw-translate-y-0"
            style={{ maxWidth: '100%', padding: '0 15px' }}
            open={isOpen}
            onCancel={() => {
                toggle()
            }}
            afterClose={() => {
                setModalKey(prev => prev + 1)
            }}
            width="100%"
            title={T.t('gen_add_event')}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setSubmitting(true)
                    if (!values.selectedPlace || !values.event_type)
                        return actions.setErrors({ event_type: T.t('gen_cannot_leave_empty'), selectedPlace: T.t('gen_cannot_leave_empty') })

                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, {
                        room_id: values.selectedPlace.id,
                        title: values.title,
                        description: values.description,
                        event_type: values.event_type.value,
                        start_date: values.start_date_and_time,
                        end_date: values.end_date_and_time,
                    }, 'submit-save-event', () => {
                        setSubmitting(false)
                        // close modal after 1 second
                        const timer = setTimeout(() => {
                            toggle()
                        }, 1000)
                        return () => clearTimeout(timer)
                    }));
                }}
                ref={formikRef}
            >
                {(formikProps: FormikProps<ICreateFilterEvent>) => {
                    const { values, handleSubmit } = formikProps
                    const isDateSelected = values.start_date_and_time && values.end_date_and_time && values.event_type && values.title
                    const disableNext = (current: number) => {
                        switch (current) {
                            case 0:
                                return current === 0 && !isDateSelected
                            case 1:
                                return current === 1 && !values.selectedPlace
                            default:
                                return false
                        }
                    }
                    const isPreview = current === stepItems(formikProps).length - 1
                    return <form>
                        <section className={cn(flexCol, gap2, flexIC)}>
                            <Steps
                                direction="horizontal"
                                // className="tw-max-w-[256px] tw-w-full"
                                current={current}
                                items={
                                    stepItems(formikProps).map(item => item.item)
                                } />
                            {/* content */}
                            <div className={cn(flexCenter, "tw-min-h-[425px] tw-bg-gray-100 tw-rounded-lg my-4 tw-p-4 tw-w-full tw-flex-grow")}>
                                {stepItems(formikProps)[current].content}
                            </div>
                        </section>
                        {/* end buttons */}
                        <div className={cn(flexRow, flexIC, "tw-justify-end tw-space-x-2")}>
                            <Button size="md" color="light-gray" onClick={current === 0 ? toggle : prev}>
                                {current === 0 ? T.t("gen_cancel") : T.t("gen_previous")}
                            </Button>

                            <Button
                                size="md"
                                icon={isPreview ? "save" : undefined}
                                disabled={disableNext(current) || submitting}
                                color={isPreview ? "green" : "blue"} onClick={isPreview ? () => handleSubmit() : next}
                            >
                                {isPreview ? T.t("gen_save") : T.t("gen_next")}
                            </Button>
                        </div>
                    </form>
                }}
            </Formik>
        </Modal >
    )
}


const mapStateToProps = (store: IPersistedState, ownProps: AddEventPeriodProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options,
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const AddEventPeriod = connect(mapStateToProps, dispatchProps)(AddEventPeriodIn);


export default AddEventPeriod