import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
// dönem türleri sınav türlerinden alınmıştır
import { TermTypes } from '../../store/constants/exam-period-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import Translator from '../../services/translate-factory';
import { EventPeriodSearchInitialValues } from '../../store/constants/event-period-const';
import EventPeriodForm from './event-period-form'
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import CopyEventPeriodForm from './event-copy-period-form'
import { MouseEventHandler } from 'react';
import EventCalendar from './event-calendar';

const response = {
    data: [
        {
            term_id: 6422,
            "term_type": 2,
            "status": 1,
            "academic_terms": [2, 1],
            "year": 2023,
            "name": "Sau Topluluk Günleri",
            time_range: {
                start: "01.04.2024",
                end: "23.12.2025"
            },
            course_term: {
                term_id: 6148,
            },
            exam_term: {
                term_id: 6149,
            },
            "description": "dd",
            "calendar_status": 1,
        }
    ],
    "filters": {
        "term_type": 1,
        "instructor_id": 0,
        "status": [],
        "term_ids": [],
        "calendar_status": -2,
        "program_manager_id": 0,
        "program_manager_program_ids": [],
        "exam_type_ids": [],
        "term_id": 0,
        "page": 1,
        "size": 10,
        "total": -1,
        "query": "",
        "order_by": "",
        "size_max": false,
        "select_all": false,
        "time": "0001-01-01T00:00:00+00:00"
    }
}


const T = Translator.create();
const L = Log.create('EventPeriodListPage');

class EventPeriodTable extends Component<Types.IEventPeriodPageProps, Types.IEventPeriodPageState> {
    state: Types.IEventPeriodPageState = {
        filters: EventPeriodSearchInitialValues,
        filterIsOpen: false,
        eventPeriodFormIsOpen: false,
        calendarFormIsOpen: false,
        eventPeriodId: undefined,
        copyFormIsOpen: false,
        copyFormTermId: undefined,
        terms: [],
    };

    constructor(props: any) {
        super(props)
        this.state.locale = GT.getLocaleFromLangCode()
        this.isSystem = this.isSystem.bind(this)
    }

    langChanged = () => {
        setTimeout(() => {
            try {
                this.forceUpdate();
            } catch (e) {
                L.error(e as string);
            }
        }, 1000);
    };

    componentDidMount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        window.scrollTo(0, 0);
        this.getTermSelectOptions();
        this.searchEventPeriods()
        this.updateTermListForTermSelector();
    }

    componentWillUnmount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    }

    getTermSelectOptions() {
        // TODO - DOGRU API REQUEST ATILACAK SELECT OPTIONS 
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_SELECT_OPTIONS, 'exam-period-spinner'));
    }

    updateTermListForTermSelector() {
        // TODO - DOGRU API REQUEST ATILACAK EXAM_PERIOD_GET_LIST
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_LIST, null, 'term-selector-spinner'));
    }

    searchEventPeriods() {

        // TODO - Buradan dogru api istegi yapilacak
        this.props.dispatch(
            Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_LIST_SEARCH, this.state.filters, 'examPeriod-list-spin')
        );
    }

    getSelectedPeriodInfo(term_id: number) {
        // TODO - EVENT PERIOD DATES API REQUEST
        this.props.dispatch(
            Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES, term_id, 'exam-period-modal-tab-spin')
        );

        let model: Types.IFilterSolutionModel = {
            term_id: term_id,
            active_tab: '0',
            page: 1,
            total: -1,
            size: 10,
            solution_id: 0
        };
        this.props.dispatch(
            Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
        );
    }

    sort = (sortkey: string, order_by: string) => {
        this.setState(prev => ({
            ...prev,
            filters: {
                ...prev.filters,
                order_by: sortkey + '_' + order_by
            }
        }));
        this.searchEventPeriods();
    };

    eventPeriodFormIsOpen = () => {
        this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS, '/create')));
    };

    copyFormIsOpen = (item: any) => {
        this.setState(prev => ({
            ...prev,
            copyFormIsOpen: true,
            copyFormTermId: item.term_id
        }))
    };

    eventPeriodFormOnClose = (refresh: boolean) => {
        if (this.state.eventPeriodFormIsOpen) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS)));
        }
        if (refresh) {
            this.searchEventPeriods();
        }
    };

    copyFormOnClose = (refresh: boolean) => {
        if (this.state.copyFormIsOpen) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS)));
        }
        if (refresh) {
            this.searchEventPeriods();
        }
        this.setState(prev => ({
            ...prev,
            copyFormIsOpen: false
        }))

    };

    calendarFormIsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e && e.currentTarget) {
            const id: string = e.currentTarget.dataset.id || '';
            this.setState(prev => prev);
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS, '/calendar/' + id)));
        }
    };

    calendarFormOnClose = () => {
        this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS)));
    };

    onPageChange = (page: number) => {
        this.setState(prev => ({
            ...prev,
            filters: {
                ...prev.filters,
                page
            }
        }));
        this.searchEventPeriods();
    };

    onFormReset = () => {
        this.setState(prev => ({
            ...prev,
            filters: Object.assign({}, EventPeriodSearchInitialValues)
        }));
        this.searchEventPeriods();
    };

    onFilterEventPeriod(model: Types.IFilterEventPeriod, FormActions: FormikActions<Types.IFilterEventPeriod>) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                ...model,
                page: 1,
            },
            filterIsOpen: true
        }));
        this.searchEventPeriods();
        FormActions.setSubmitting(false);
    }

    onEventPeriodEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e && e.currentTarget) {
            const id: string = e.currentTarget.dataset.id || '';
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS, '/' + id)));
        }
    };

    onEventPeriodDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e && e.currentTarget.dataset && e.currentTarget.dataset.id) {
            let id = e.currentTarget.dataset.id;
            let name = e.currentTarget.dataset.name;
            const resultCallBack = () => {
                this.updateTermListForTermSelector();
            };
            const onConfirmDelete = () => {
                // TODO - api EVENT PERIOD DELETE PROCESS
                this.props.dispatch(
                    Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_DELETE, id, undefined, resultCallBack)
                );
            };
            this.props.dispatch(
                Actions.ShowModal({
                    title: T.t('gen_exam_will_be_deleted'),
                    body: T.t('gen_are_you_sure_you_want_delete_period_named').replace('{0}', name),
                    name: 'examPeriod_delete',
                    icon: 'error_outline',
                    iconColor: 'red',
                    confirm: T.t('gen_yes'),
                    cancel: T.t('gen_no'),
                    onConfirm: onConfirmDelete
                })
            );
        }
    };

    static getDerivedStateFromProps(props: Types.IEventPeriodPageProps, state: Types.IEventPeriodPageState) {
        let hasNewState: boolean = false;

        if (props.terms) {
            state.terms = props.terms
        }

        if (props.match && props.match.params.id) {
            let regexObject = props.location.pathname.match(/[^/]+$/);
            hasNewState = true;
            if (props.location && props.location.pathname.includes('calendar')) {
                state.calendarFormIsOpen = true;
                // regexObject = props.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
                state.eventPeriodId = parseInt(regexObject[0], 10);
            } else if (props.location && regexObject[0] === 'create') {
                state.eventPeriodFormIsOpen = true;
            } else if (props.location && regexObject[0] !== 'create') {
                state.eventPeriodId = props.match.params.id;
                state.eventPeriodFormIsOpen = true;
            }
        } else {
            hasNewState = true;
            state.eventPeriodFormIsOpen = false;
            state.calendarFormIsOpen = false;
            state.eventPeriodId = undefined;
        }
        if (hasNewState) {
            return state;
        } else {
            return null;
        }
    }

    onTermSelect = (term_id: any, term_type: any) => {
        this.props.synchronization && (this.props.synchronization.synctype_campus_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_building_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_classroom_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_faculty_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_program_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_instructor_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_course_clicked = false);
        this.props.synchronization && (this.props.synchronization.synctype_student_clicked = false);

        this.props.dispatch(Actions.SetTermInfo(term_id, term_type));
        this.props.dispatch(
            Actions.ApiRequest(Constants.disributor.GET_DISTRIBUTION_IN_PROGRESS_STATUS, term_id, 'ep-student-list')
        );
        // TODO - api
        this.props.dispatch(
            Actions.Navigation(GT.Route(term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS))
        );
        // TODO - api
        this.props.dispatch(
            Actions.Navigation(
                GT.Route(
                    term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS
                )
            )
        );
        this.getSelectedPeriodInfo(term_id);
    };

    isSystem() {
        return this.props.user && this.props.user.role === 's'
    }

    AddButton() {
        return this.isSystem() ? (
            <button id='button_add_term' className="ml-3 category-tag-square tag-green" onClick={this.eventPeriodFormIsOpen}>
                <i className="material-icons mr-2">add</i>{T.t('gen_add_event_term')}
            </button>
        ) : <></>
    }

    listVisibility() {
        return this.state.calendarFormIsOpen ? 'none' : 'block'
    }

    calendarVisibility() {
        return !this.state.calendarFormIsOpen ? 'none' : 'block'
    }

    filterShow() {
        return this.state.filterIsOpen ? `show` : ``
    }

    flatpickrOnChange(selectedDates: any, props: FormikProps<Types.IFilterEventPeriod>) {

        if (selectedDates.length === 2) {
            const startDate = moment(selectedDates[0]).format('YYYY-MM-DD');
            const endDate = moment(selectedDates[1]).format('YYYY-MM-DD');
            props.setFieldValue('time_range', { start: startDate, end: endDate });
        }

    }

    conditionalShow(condition: boolean | undefined, Component: any, elseComponent?: any) {
        if (condition) {
            return Component
        } else {
            return elseComponent
        }
    }

    itemStatusColor(item: Types.ITermItem) {
        return (item.status == 1 ? ` tag-green` : ` tag-red`)
    }

    getFlatpickrValue(values: Types.IFilterEventPeriod) {
        return values.time_range ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []
    }

    getDefaultFlatpickrDate(values: Types.IFilterEventPeriod) {
        return values.time_range ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []
    }

    render() {

        // TODO API - liste verisi buraya gelecek dummy data ile degistirlecek
        let eventPeriodList = this.props.results;
        return (
            <MainLayout header={<APlanHeader />}>
                <div className="main list-campus" style={{ display: this.listVisibility() }}>
                    <div className="container-fluid">
                        <div className="row align-items-center mb-sm-4 mb-2">
                            <div className="col-md-6 col-sm-4 col-12">
                                <h4 className="mb-4 mb-sm-0">{T.t('gen_event_terms')}</h4>
                            </div>
                            <div className="col-md-6 col-sm-8 col-12 text-right">
                                <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                    <button
                                        id='button_filter'
                                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                                        style={{ margin: '5px' }}
                                        onClick={() => {

                                            this.setState(prev => ({
                                                ...prev,
                                                filterIsOpen: !prev.filterIsOpen
                                            }));
                                        }}
                                    >
                                        <i className="material-icons mr-2">filter_list</i>
                                        <span>{T.t('gen_filter')}</span>
                                    </button>
                                    <AddButton isSystem={this.isSystem} eventPeriodFormIsOpen={this.eventPeriodFormIsOpen} />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`white-container mt-4 collapse ` + this.filterShow()}
                            id="advance-search"
                        >
                            <div className="advance-search d-block mt-3">
                                <Formik
                                    initialValues={EventPeriodSearchInitialValues}
                                    enableReinitialize={true}
                                    onSubmit={(values, actions) => {
                                        this.onFilterEventPeriod(values, actions);
                                    }}
                                    onReset={this.onFormReset}
                                >
                                    {(props: FormikProps<Types.IFilterEventPeriod>) => {
                                        const { values } = props
                                        return (
                                            <form onSubmit={props.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h6>{T.t('gen_filter_parameters')}</h6>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-input form-group date-picker">
                                                            <input
                                                                id="name"
                                                                name="name"
                                                                value={props.values.name}
                                                                onChange={props.handleChange}
                                                                type="text"
                                                                required={false}
                                                            />
                                                            <span className="highlight" />
                                                            <span className="bar" />
                                                            <label htmlFor="name">{T.t('gen_term_name')}</label>
                                                            <i className="material-icons">title</i>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-input form-group date-picker">
                                                            <input
                                                                id="year"
                                                                name="year"
                                                                value={props.values.year}
                                                                onChange={props.handleChange}
                                                                type="number"
                                                            />
                                                            <span className="highlight" />
                                                            <span className="bar" />
                                                            <label htmlFor="year">{T.t('gen_year')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 form-input">
                                                        <div className="react-select-container">
                                                            <h6>{T.t('gen_terms')}</h6>
                                                            <Select
                                                                id='select_term'
                                                                className="react-select"
                                                                isMulti={true}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                closeMenuOnSelect={false}
                                                                options={TermTypes(T)}
                                                                placeholder={T.t('gen_select_term')}
                                                                value={props.values.terms}
                                                                onChange={(
                                                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                                ) => {
                                                                    const list: Array<Types.ISelectOption> = (options as Array<Types.ISelectOption>) || [] //empty

                                                                    props.setFieldValue('terms', list);
                                                                    props.setFieldValue(
                                                                        'term_ids',
                                                                        list.map((item) => item.value)
                                                                    );
                                                                }}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-4">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_exam_and_course_terms')}</label>
                                                            <Select
                                                                className="react-select"
                                                                placeholder={T.t('gen_select_exam_and_course_terms')}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                options={this.state.terms.map(
                                                                    term => ({
                                                                        label: `${term.year} ${GT.GetAcademicTermText(term.academic_term)} ${GT.GetTermTypeText(term.term_type)} / ${term.name} (${term.term_id})`,
                                                                        value: term.term_id
                                                                    })
                                                                )}
                                                                isMulti={true}
                                                                value={values.term_periods}
                                                                onChange={(selectedOption: any) => {
                                                                    props.setFieldValue('term_period_ids',
                                                                        selectedOption && {
                                                                            term_id: selectedOption.value,
                                                                            name: selectedOption.label
                                                                        }
                                                                    );
                                                                    props.setFieldValue('term_periods', selectedOption);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 form-input form-group with-icon">

                                                        <Flatpickr
                                                            placeholder={T.t('gen_select_dates')}
                                                            value={this.getFlatpickrValue(values)}
                                                            options={{
                                                                dateFormat: 'd.m.Y',
                                                                mode: 'range',
                                                                locale: this.state.locale,
                                                                defaultDate: this.getDefaultFlatpickrDate(values)
                                                            }}
                                                            onChange={(selectedOptions) => this.flatpickrOnChange(selectedOptions, props)}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="term_date_range">{T.t('gen_start_and_end_dates')}</label>
                                                    </div>

                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <div className="text-left">
                                                            <h6>{T.t('gen_status')}</h6>
                                                            <div className="tick-radio position-relative mb-3 d-inline-block">
                                                                <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                                            </div>
                                                            <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                                                <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <button
                                                            id='button_arrow_upward'
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState(prev => ({
                                                                    ...prev,
                                                                    filterIsOpen: false
                                                                }));
                                                            }}
                                                            className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn mr-2"
                                                        >
                                                            <i className="material-icons">arrow_upward</i>
                                                        </button>
                                                        <button
                                                            id='button_delete_sweep'
                                                            type="reset"
                                                            onClick={props.handleReset}
                                                            className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                                        >
                                                            <i className="material-icons">delete_sweep</i>
                                                        </button>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <button
                                                            id='button_search'
                                                            type="button"
                                                            className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                                            onClick={() => props.handleSubmit()}
                                                            disabled={props.isSubmitting}
                                                        >
                                                            <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                        <Spinner name="examPeriod-list-spin" />
                        <div className="white-container mt-4">
                            <div className="row">
                                <div className="col-12">
                                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                        <thead>
                                            <tr>
                                                <SortedColumn
                                                    datacell="status"
                                                    title={T.t('gen_status')}
                                                    sortkey="status"
                                                    sortedcolumn={this.state.filters.order_by}
                                                    sort={this.sort}
                                                />
                                                <SortedColumn
                                                    datacell="id"
                                                    title="ID"
                                                    sortkey="id"
                                                    sortedcolumn={this.state.filters.order_by}
                                                    sort={this.sort}
                                                />
                                                <SortedColumn
                                                    datacell="year"
                                                    className="d-none d-lg-table-cell d-xl-table-cell"
                                                    title={T.t('gen_year')}
                                                    sortkey="year"
                                                    sortedcolumn={this.state.filters.order_by}
                                                    sort={this.sort}
                                                />
                                                <SortedColumn
                                                    datacell="academic_term"
                                                    className="d-none d-lg-table-cell d-xl-table-cell"
                                                    title={T.t('gen_terms')}
                                                    sortkey="academic_term"
                                                    sortedcolumn={this.state.filters.order_by}
                                                    sort={this.sort}
                                                />
                                                <SortedColumn
                                                    datacell="name"
                                                    className="d-none d-lg-table-cell d-xl-table-cell"
                                                    title={T.t('gen_name')}
                                                    sortkey="name"
                                                    sortedcolumn={this.state.filters.order_by}
                                                    sort={this.sort}
                                                />
                                                <th scope="col" className="text-center">
                                                    {T.t('gen_time_range')}
                                                </th>
                                                <th scope="col" className="text-center">
                                                    {T.t('gen_description')}
                                                </th>
                                                <th scope="col" className="text-center">
                                                    {T.t('gen_select_term')}
                                                </th>
                                                <th scope="col" className="text-center">
                                                    {T.t('gen_copy_term')}
                                                </th>
                                                {this.isSystem() ? (
                                                    <th scope="col" className="text-right">
                                                        <span className="text-right">{T.t('gen_actions')}</span>
                                                    </th>
                                                ) : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {response.data
                                                ? response.data.map((item: Types.ITermItem) => {
                                                    let termsObjects = TermTypes(T).filter((object) => item.academic_terms && item.academic_terms.includes(object.value));
                                                    return (
                                                        <tr key={'examPeriod-' + item.term_id} data-title={item.name}>
                                                            <td scope="row" data-label={T.t('gen_status')}>
                                                                <div className="tags ml-1 mr-4">
                                                                    <button
                                                                        id='button_status'
                                                                        className={
                                                                            `small-tag text-uppercase ` + this.itemStatusColor(item)
                                                                        }
                                                                    >
                                                                        {GT.GetActiveStatus(item.status)}
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td scope="row" data-label="ID">
                                                                {item.term_id}
                                                            </td>
                                                            <td data-label={T.t('gen_year')}>{item.year}</td>
                                                            <td data-label={T.t('gen_terms')}>
                                                                {termsObjects && termsObjects.map((termObject) => termObject.label).join(', ')}
                                                            </td>

                                                            <td data-label={T.t('gen_name')}>{item.name}</td>
                                                            <td data-label={T.t('gen_time_range')} className="text-center">
                                                                {item.time_range ? `${item.time_range.start} - ${item.time_range.end}` : '-'}
                                                            </td>
                                                            <td data-label={T.t('gen_description')} className="text-center">
                                                                {item.description && item.description.length ?
                                                                    <div className="table-scrollable-td">{item.description}</div> : '-'}
                                                            </td>
                                                            <td data-cell="exam-periods" className="text-center">
                                                                <button
                                                                    id='button_term_type'
                                                                    className="period-select-button"
                                                                    onClick={() => this.onTermSelect(item.term_id, item.term_type)}
                                                                >
                                                                    {item.name}
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    id='button_copy_term'
                                                                    className="ml-3 category-tag-square tag-green"
                                                                    onClick={() => this.copyFormIsOpen(item)}>
                                                                    <i className="material-icons mr-2">add</i>
                                                                    {T.t('gen_copy_term')}
                                                                </button>
                                                            </td>
                                                            {
                                                                this.conditionalShow(
                                                                    this.isSystem(),
                                                                    (<td data-label={T.t('gen_actions')} className="table-buttons">
                                                                        <div className="table-buttons-wrapper">
                                                                            <button
                                                                                id='button_edit'
                                                                                data-toggle="tooltip"
                                                                                data-id={item.term_id}
                                                                                onClick={this.onEventPeriodEdit}
                                                                                title={T.t('gen_edit')}
                                                                                className="btn btn-light btn-sm table-button"
                                                                            >
                                                                                <span className="d-block" data-toggle="modal" data-target="#addNew">
                                                                                    <i className="material-icons">edit</i>
                                                                                </span>
                                                                            </button>
                                                                            <button
                                                                                id='button_delete'
                                                                                data-toggle="tooltip"
                                                                                data-id={item.term_id}
                                                                                data-name={item.name}
                                                                                onClick={this.onEventPeriodDelete}
                                                                                title={T.t('gen_delete')}
                                                                                className="btn btn-light btn-sm table-button"
                                                                            >
                                                                                <span className="d-block" data-toggle="modal" data-target="#exampleModal">
                                                                                    <i className="material-icons">delete</i>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </td>)
                                                                )}
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                    <div className="row-options justify-content-end">
                                        <div
                                            className="page-sorting d-flex align-items-center justify-content-center"
                                            style={{ marginTop: '5px' }}
                                        >
                                            {this.props.results && this.props.results.length > 0 && (
                                                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.copyFormIsOpen &&
                    <CopyEventPeriodForm
                        examPeriodId={this.state.copyFormTermId}
                        formIsOpen={this.state.copyFormIsOpen}
                        onClose={this.copyFormOnClose}
                    />
                }
                <EventPeriodForm
                    eventPeriodId={this.state.eventPeriodId}
                    formIsOpen={this.state.eventPeriodFormIsOpen}
                    onClose={this.eventPeriodFormOnClose}
                />
                {
                    <div style={{ display: this.calendarVisibility() }}>
                        <EventCalendar />
                    </div>
                }
            </MainLayout>
        );
    }
}

const AddButton = ({ isSystem, eventPeriodFormIsOpen }: { isSystem: () => boolean | undefined, eventPeriodFormIsOpen: MouseEventHandler<HTMLButtonElement> }) => {
    return isSystem() ? (
        <button id='button_add_term' className="ml-3 category-tag-square tag-green" onClick={eventPeriodFormIsOpen}>
            <i className="material-icons mr-2">add</i>{T.t('gen_add_event_term')}
        </button>
    ) : <></>;
};

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: Types.IEventPeriodPageProps
): Types.IEventPeriodPageProps => {
    if (!store) {
        return ownProps;
    }

    const newProps: Types.IEventPeriodPageProps = Object.assign({}, ownProps, {
        results: store.state.exam_period_page && store.state.exam_period_page.results,
        filters: store.state.exam_period_page && store.state.exam_period_page.filters,
        user: store.state.user,
        synchronization: store.state.synchronization && store.state.synchronization,
        selectOptions: store.state.select_options && store.state.select_options.termsPage,
        terms: store.state.term_list
    });
    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
    areStatesEqual
})(EventPeriodTable);

export default container;
