import React from 'react';
import { Log } from 'ng2-logger';
import * as Icons from 'react-icons/md';
import { IColumnProps } from '../../store/types';

const L = Log.create('sorted-column');

interface SortedColumnState {
  order_by: string;
  sortkey: string;
}

class SortedColumn extends React.Component<IColumnProps, SortedColumnState> {
  state = {
    order_by: '',
    sortkey: this.props.sortkey
  };

  onClick = () => {
    const { order_by } = this.state;
    const newOrder = order_by === 'asc' ? 'desc' : 'asc';
    this.setState({ order_by: newOrder }, () => {
      if (this.props.sort) {
        this.props.sort(this.props.sortkey, newOrder);
      }
    });
  };

  static getDerivedStateFromProps(nextProps: IColumnProps, prevState: SortedColumnState) {
    const { sortedcolumn, sortkey } = nextProps;
    if (sortedcolumn && sortedcolumn.startsWith(sortkey + '_')) {
      const order_by = sortedcolumn.endsWith('asc') ? 'asc' : 'desc';
      if (order_by !== prevState.order_by) {
        return { order_by };
      }
    } else if (prevState.order_by !== '') {
      return { order_by: '' };
    }
    return null;
  }

  render() {
    const { order_by } = this.state;
    let icon = <Icons.MdSort />;
    if (order_by === 'asc') {
      icon = <Icons.MdKeyboardArrowUp />;
    } else if (order_by === 'desc') {
      icon = <Icons.MdKeyboardArrowDown />;
    }

    return (
      <th
        id={this.props.id}
        className={`${this.props.className} text-center`}
        data-cell={this.props.datacell}
        onClick={this.onClick}
        style={{ cursor: 'pointer' }}
      >
        {icon}&nbsp;{this.props.title}
      </th>
    );
  }
}

export default SortedColumn;