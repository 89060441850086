import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Translator from '../../services/translate-factory';
import Select from 'react-select';
import Button from '../../components/button';
import Spinner from '../../components/templates/spinner';
import { RBCEventStatus, RBCEventStatuses, RBCEventTypes } from './constants';
import { ValueType } from 'react-select/lib/types';
import AddEventPeriod from './add';
import { debounce } from 'lodash';

const initialFilter: Types.IFilterEvent = {}

interface EventCalendarFiltersProps {
    selectOptions?: Types.IAppSelectOptions;
    dispatch?: any;
}

const EventCalendarFiltersIn: React.FC<EventCalendarFiltersProps> = ({ dispatch, selectOptions }) => {
    const [modal, setModal] = useState(false);
    const T = Translator.create();
    const [, forceRender] = useReducer(x => x + 1, 0);
    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);


    const onFormSave = (model: Types.IFilterEvent, FormActions: FormikActions<Types.IFilterEvent>) => {

        const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
                let errors: any = {};
                if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                        errors[m.field] = m.message[0];
                    });
                }
                FormActions.setErrors(errors);
            }
        };

        // loads calendar events
        dispatch(Actions.ApiRequest(Constants.building.BUILDING_UPDATE, model, 'calendar-event-spin', resultCallback));
        FormActions.setSubmitting(false);
    };

    useEffect(() => {
        // bunlar tek bir api istegiyle gerceklesebilir.
        // loads selectOptions.campusRelation.campuses
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, {}, 'event-campus-spin'));
        // loads selectOptions.campusRelation.relatedInstructors
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, {}, 'instructors-select-options'));
        // loads selectOptions.eventRelation.eventTypes
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, {}, 'event-type-select-options'));
    }, [])

    const getEventTypeOptions = [
        ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.eventTypes || []),
        ...RBCEventTypes(T)
    ].sort((a, b) => a.label.localeCompare(b.label))
    const getCampusOptions = selectOptions && selectOptions.campusRelation && selectOptions.campusRelation.campuses
    const getBuildingOptions = selectOptions && selectOptions.campusRelation && selectOptions.campusRelation.relatedBuildings
    const getClassroomOptions = selectOptions && selectOptions.buildingRelation && selectOptions.buildingRelation.relatedClassrooms
    const getFaculityOptions = selectOptions && selectOptions.campusRelation && selectOptions.campusRelation.relatedFaculities
    const getProgramOptions = selectOptions && selectOptions.facultyRelation && selectOptions.facultyRelation.relatedPrograms
    const getInstructorOptions = selectOptions && (selectOptions.campusRelation && selectOptions.campusRelation.relatedInstructors || selectOptions.instructorRelation && selectOptions.instructorRelation.instructors)


    const isDisable = (values: Types.IFilterEvent, key: keyof Types.IFilterEvent): boolean => {
        const value = values[key];
        return !(value && Array.isArray(value) && value.length > 0);
    };


    const isValid = (option: any) => {
        return option && Array.isArray(option) && option.length !== 0;
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={initialFilter}
            onSubmit={(values, actions) => {
                onFormSave(values, actions);
            }}
        >
            {(props: FormikProps<Types.IFilterEvent>) => {

                const { errors, handleSubmit, resetForm, values, submitCount, setFieldValue } = props;
                return (
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-md-3">
                                <Spinner name='event-campus-spin' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_campus')}</label>
                                        <Select
                                            className="react-select"
                                            name="campuses"
                                            id="campuses"
                                            filterOption={(option, query) => option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getCampusOptions}
                                            placeholder={T.t('gen_select_campus')}
                                            value={props.values.campuses}
                                            onChange={(option) => {
                                                props.setFieldValue('campuses', option);
                                                //loads selectOptions.campusRelation.relatedBuildings and selectOptions.campusRelation.relatedFaculities
                                                if (isValid(option))
                                                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { campus_ids: option }, 'building-select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.campuses && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.campuses}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='building-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_building')}</label>
                                        <Select
                                            className="react-select"
                                            name="building"
                                            id="building"
                                            isDisabled={isDisable(values, 'campuses')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getBuildingOptions}
                                            placeholder={T.t('gen_select_building')}
                                            value={props.values.buildings}
                                            onChange={(option) => {
                                                props.setFieldValue('buildings', option);
                                                //loads selectOptions.buildingRelation.relatedClassrooms
                                                if (isValid(option))
                                                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { building_ids: option }, 'classroom-select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.buildings && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.buildings}</div>
                                    )}
                                </div>

                            </div>

                            <div className="col-md-3">
                                <Spinner name='classroom-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_classroom')}</label>
                                        <Select
                                            className="react-select"
                                            name="classroom/room"
                                            id="classroom/room"
                                            isDisabled={isDisable(values, 'buildings')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getClassroomOptions}
                                            placeholder={T.t('gen_select_building')}
                                            value={props.values.classrooms}
                                            onChange={(option) => {
                                                props.setFieldValue('classrooms', option);
                                                if (isValid(option))
                                                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { classroom_ids: option }));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.classrooms && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.classrooms}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <Spinner name='building-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_faculty')}</label>
                                        <Select
                                            className="react-select"
                                            name="faculty"
                                            id="faculty"
                                            isDisabled={isDisable(values, 'campuses')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getFaculityOptions}
                                            placeholder={T.t('gen_select_faculty')}
                                            value={props.values.faculities}
                                            onChange={(option) => {
                                                props.setFieldValue('faculities', option);
                                                // loads selectOptions.facultyRelation.relatedPrograms
                                                if (isValid(option))
                                                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { faculity_ids: option }, 'program-select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.faculities && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.faculities}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='program-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_program')}</label>
                                        <Select
                                            className="react-select"
                                            name="programs"
                                            id="programs"
                                            isDisabled={isDisable(values, 'faculities')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getProgramOptions}
                                            placeholder={T.t('gen_select_program')}
                                            value={values.programs}
                                            onChange={(option) => {
                                                setFieldValue('programs', option);
                                                // loads or update selectOptions.campusRelation.relatedInstructors
                                                if (isValid(option))
                                                    dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, { program_ids: option }, 'instructors-select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.programs && submitCount > 0 && (
                                        <div className="error">{errors && errors.programs}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='instructors-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_instructors')}</label>
                                        <Select
                                            className="react-select"
                                            name="instructors"
                                            id="instructors"
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getInstructorOptions}
                                            placeholder={T.t('gen_select_instructor')}
                                            value={values.instructors}
                                            onChange={(option) => {
                                                setFieldValue('instructors', option);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.instructors && submitCount > 0 && (
                                        <div className="error">{errors && errors.instructors}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='event-type-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_event_type')}</label>
                                        <Select
                                            className="react-select"
                                            name="event_type"
                                            id="event_type"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            options={getEventTypeOptions}
                                            placeholder={T.t('gen_select_event_type')}
                                            value={values.event_type}
                                            onChange={(option) => {
                                                setFieldValue('event_type', option);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.event_type && submitCount > 0 && (
                                        <div className="error">{errors && errors.event_type}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='event-type-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_event_status')}</label>
                                        <Select
                                            className="react-select"
                                            name="event_status"
                                            id="event_status"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            filterOption={(option, query) => {
                                                const label = option.label ? option.label.toLocaleLowerCase(T.getSelectedLanguage()) : '';
                                                const normalizedQuery = query.toLocaleLowerCase(T.getSelectedLanguage());
                                                return label.includes(normalizedQuery);
                                            }}
                                            options={Object.keys(RBCEventStatus).map((key) => {
                                                return ({
                                                    value: key,
                                                    label: (RBCEventStatuses(T) as { [key: string]: any })[key]
                                                })
                                            })}
                                            placeholder={T.t('gen_select_event_status')}
                                            value={values.event_status}
                                            onChange={(option: ValueType<Types.ISelectOption>) => {
                                                setFieldValue('event_status', option);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.event_status && submitCount > 0 && (
                                        <div className="error">{errors && errors.event_status}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-right">
                            <div className='tw-flex tw-flex-row tw-items-center tw-space-x-2'>
                                <Button color='green' icon='add' size='md' onClick={() => setModal(true)}>
                                    {T.t("gen_add_event")}
                                </Button>
                                <div className='tw-flex-grow' />
                                <Button color='red' onClick={() => resetForm()} size='md'>
                                    {T.t("reset")}
                                </Button>
                                <Button color='blue' icon='search' size='md' onClick={() => handleSubmit()}>
                                    {T.t("gen_search")}
                                </Button>
                            </div>
                        </div>
                        <hr />
                    </form>
                )
            }}
        </Formik>
        <AddEventPeriod isOpen={modal} toggle={() => setModal(!modal)} />
    </>
}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: EventCalendarFiltersProps
): EventCalendarFiltersProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: EventCalendarFiltersProps = {
        selectOptions: store.state.select_options,
        ...ownProps,
    };
    return newProps;
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendarFilters = connect(mapStateToProps, dispatchProps)(EventCalendarFiltersIn);

export default EventCalendarFilters
