import React, { useState } from 'react';
import { AiOutlineEnter } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import cn, { flexIC, flexRow, gap2 } from '../../../ui/Tailwind';

interface EditableTitleProps {
    title: string;
    subTitle?: string;
    onSave: (newValue: string) => void
}

const EditableTitle: React.FC<EditableTitleProps> = ({ onSave, title, subTitle }) => {
    const [editableTitle, setEditableTitle] = useState<string>(title);
    const [isEditing, setIsEditing] = useState(false);
    const [tempTitle, setTempTitle] = useState(editableTitle);

    const handleEdit = () => {
        setTempTitle(editableTitle);
        setIsEditing(true);
    };

    const handleSave = () => {
        setEditableTitle(tempTitle);
        setIsEditing(false);
        onSave(tempTitle)
    };

    const handleCancel = () => {
        setTempTitle(editableTitle);
        setIsEditing(false);
    };

    const handleChange = (e: any) => setTempTitle(e.target.value);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSave();
        } else if (e.key === 'Escape') {
            handleCancel();
        }
    };

    return (
        <div className={cn(flexRow, flexIC, gap2, "tw-h-8")}>
            <span>{subTitle}</span>
            {isEditing ? (
                <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-flex-row'>
                    <input
                        value={tempTitle}
                        onChange={handleChange}
                        onBlur={handleSave}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        className="tw-bg-transparent tw-pr-8 tw-border-b tw-border-gray-300  tw-p-1 focus:tw-outline-none"
                    />
                    <AiOutlineEnter
                        className="tw-absolute tw-right-2 tw-opacity-80 tw-cursor-pointer"
                        onClick={handleSave}
                    />
                </div>
            ) : (
                <button
                    className="tw-flex tw-flex-row tw-items-center tw-gap-2"
                    onClick={handleEdit}
                >
                    <span>{editableTitle}</span>
                    <MdEdit />
                </button>
            )}
        </div>
    );
};

export default EditableTitle;
